import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../../context/requestContext'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../../context/authContext'
import SearchTypeWorkofArt from '../../../components/form/searchTypeWorkofArt'
import ListWorkOfArtClass from '../../../components/list/workOfArt/ListworkOfArtClass'
const ManagerWorkOfArtClass = () => {
	const { getItemObArtClass, getWhereItemObArtClass, obArtClass } = useContext(requestContext)
	const { handleForm, state: { ob_art_class_desc } } = useContext(formContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		getItemObArtClass()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = obArtClass
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return ob_art_class_desc ? getWhereItemObArtClass(current_page > 1 && `${ob_art_class_desc}?page=${current_page - 1}`) : getItemObArtClass(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return ob_art_class_desc ? getWhereItemObArtClass(current_page > 1 && `${ob_art_class_desc}?page=${current_page - 1}`) : getItemObArtClass(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return ob_art_class_desc ? getWhereItemObArtClass(ob_art_class_desc) : getItemObArtClass()
		}
		if (ob_art_class_desc) {
			return getWhereItemObArtClass(`${ob_art_class_desc}?page=${label}`)
		}
		getItemObArtClass(`0?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			ob_art_class_cod: '',
			ob_art_class_desc: '',
			ob_art_class_ativo: ''
		})
		navigate('/work_of_art_class/new')
	}
	const RenderPagination = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>

		)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Classificadores de Obra de arte'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
							Classificadores de Obra de arte cadastrados
						</h3>
						<div className="card-tools">
							<SearchTypeWorkofArt handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListWorkOfArtClass data={obArtClass} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(obArtClass?.links) &&
								obArtClass.links.map(item => (<RenderPagination item={item} key={item.label} />))}
						</ul>
						{user && user.set_nivel === 1 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerWorkOfArtClass