import React from 'react'
import ContentHeader from '../../components/contentHeader'
import './style.css'
import { useNavigate } from "react-router-dom";
import { configurationsLinks } from '../../constants';
const ManagerConfiguration = () => {
	const navigate = useNavigate();      
    return (
        <>
            <ContentHeader title={'Configurações'} />
            <div className='content'>
                <div className="row p-5">
                    {configurationsLinks && configurationsLinks.map(item =>
                    (
                        <div onClick={()=>navigate(item.path)} className="col-6 col-md-3 p-3 d-flex clickable">
                            <div className="mr-2">
                                <i className={`Icon fa fa-${item?.icon}`} aria-hidden="true"></i>
                            </div>
                            <div>
                                <strong>{item?.title}</strong>
                                <p className="Subtitle">{item?.subtitle}</p>
                            </div>
                        </div>
                    )
                    )}
                </div>
            </div>
        </>

    )
}
export default ManagerConfiguration