import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { verifyLength } from '../../../services/arrayHandless'
import { requestContext } from '../../../context/requestContext'
import { addDate } from '../../../services/dateHandless'
const SelectServiceSubCat = ({ label, newlayout=false }) => {
	const { serviceSubcat, servicecat } = useContext(requestContext)
	const { state: { serv_sub_cat_cod, serv_cat_cod }, onChangeSelectInput, handleForm } = useContext(formContext)
	const [textOptionServiceSubCat, setTextOptionServiceSubCat] = useState(null)
	const [disabled, setDisable] = useState(false)

	useEffect(() => {
		if (serviceSubcat) {
			setTextOptionServiceSubCat(verifyLength(serviceSubcat))
		} else {
			setTextOptionServiceSubCat('Selecione um Grupo Atividade')
		}
	}, [serviceSubcat])
	useEffect(() => {
		const controlDisableInput = () => {
			if (!serv_cat_cod) {
				return setDisable(true)
			}
			return setDisable(false)
		}
		controlDisableInput()
	}, [serv_cat_cod])
	return (
		<div className="form-group">
			<label>{label || 'Atividade:'}</label>
			<div className="input-group">
				<select
					onChange={onChangeSelectInput}
					disabled={disabled}
					className={`form-control ${!newlayout && 'form-control-border'} ${(!serv_sub_cat_cod && !newlayout) && 'is-invalid'}`}
				>
					<option>{textOptionServiceSubCat}</option>
					{Array.isArray(serviceSubcat?.data) &&
						serviceSubcat.data.map((item) => (<option key={item.serv_sub_cat_cod} value={JSON.stringify(item)}>{item.serv_sub_cat_desc}
						</option>))}
				</select>
			</div>
		</div>
	)
}
export default SelectServiceSubCat