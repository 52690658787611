/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { requestContext } from "../../context/requestContext";
import ContentHeader from "../../components/contentHeader";
import Spinner from "../../components/spinner";
import SearchSector from "../../components/form/searchSector";
import { formContext } from "../../context/formContext";
import { Link, useNavigate } from "react-router-dom";
import SectorCompany from "../../components/list/sector";
const ManagerSector = () => {
    const { getItemAllSector, getItemCompany, getItemWhereCompany, data, setData } = useContext(requestContext);
    const { handleForm, state: { emp_nome } } = useContext(formContext)
    const navigate = useNavigate()
    useEffect(() => {
        setData(null)
        getItemAllSector();
    }, []);
    const handlePage = (item) => {
        if (!item) {
            return
        }
        const { label } = item
        const { current_page, last_page } = data
        window.scrollTo(0, 0);
        if (label === 'pagination.previous') {
            return emp_nome ? getItemWhereCompany(current_page > 1 && `${emp_nome}?page=${current_page - 1}`) : getItemCompany(current_page > 1 && `0?page=${current_page - 1}`)
        }
        if (label === 'pagination.next') {
            return emp_nome ? getItemWhereCompany(current_page > 1 && `${emp_nome}?page=${current_page - 1}`) : getItemCompany(current_page < last_page && `0?page=${current_page + 1}`)
        }
        if (!label) {
            return emp_nome ? getItemWhereCompany(emp_nome) : getItemCompany();
        }
        if (emp_nome) {
            return getItemWhereCompany(`${emp_nome}?page=${label}`)
        }
        getItemCompany(`0?page=${label}`);
    };
    const handleNew = () => {
        handleForm({
            set_desc: '',
            set_disp: '',
            set_nivel: ''
        })
        navigate('/sector/new')
    }
    return (
        <>
            <ContentHeader title={"Cadastro de Setores"} />
            <div className="content">
                <div className="card sticky-top">
                    <div className="card-header">
                        <h3 className="card-title col-8 d-none d-md-block">
                            Setores cadastrados
                        </h3>
                        <div className="card-tools">
                            <SearchSector handleNew={handleNew} />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <Spinner direction={"center"} label="carregando" />
                    <SectorCompany data={data} />
                    <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                            {Array.isArray(data?.links) &&
                                data.links.map((item) => (
                                    <li className="page-item" key={item.label}>
                                        <Link
                                            className={`page-link ${item.active && "text-light bg-info"
                                                }`}
                                            to="#"
                                            onClick={() => handlePage(item)}
                                        >
                                            {item.label === "pagination.previous" ? (
                                                <i className="fa fa-angle-left"></i>
                                            ) : item.label === "pagination.next" ? (
                                                <i className="fa fa-angle-right"></i>
                                            ) : (
                                                item.label
                                            )}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                        {/* {user && user.set_nivel === 1 && (
                            <ul className="pagination pagination-sm m-0 float-left">
                                <li className="page-item">
                                    <div className="page-link" onClick={handleNew}>
                                        Novo <i className="fa fa-plus mr-1 ml-1"></i>
                                    </div>
                                </li>
                            </ul>
                        )} */}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ManagerSector;
