import './style.css'

export const ImageBox = ({ bases64, handleAttach, openCamera, handleRemove, onlyView=false }) => {    
    return (
        <div className="ImageBox">
            <div className="row">
                {bases64?.map((item) => (
                    <div key={item} className="col-lg-3 col-md-4 col-sm-6 mb-1">
                        <div style={{position:'relative'}} className="ImageItem">
                            <img className="ImageItem" src={item.base64} />
                            {!onlyView&&<i className={'nav-icon fas fa-trash TrashIcon' } onClick={()=>handleRemove(item)}></i>}
                        </div>
                        
                    </div>
                ))}
                {!onlyView && <div className="col-lg-3 col-md-4 col-sm-6 mb-1">
                    <div className="ImageItem clickable" onClick={handleAttach}>
                        +
                    </div>
                </div>}
                {/* {!onlyView && <div className="col-lg-3 col-md-4 col-sm-6 mb-1">
                    <div className="ImageItem clickable" onClick={openCamera}>
                    <i className={'nav-icon fas fa-camera' } ></i>
                    </div>
                </div>} */}
            </div>
        </div>
    )
}