import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
const ListuserBatch = ({ data }) => {
	const [items, setItems] = useState(null)
	const { deletetemUserBatch } = useContext(requestContext)
	useEffect(() => {
		const loadItems = () => {
			try {
				if (Array.isArray(data?.data)) {
					const { data: data_items } = data
					setItems(data_items)
				}
			} catch (error) {
				console.log(error)
			}
		}
		loadItems()
	}, [data])
	if (!Array.isArray(items)) {
		return <div className="row">
			<div className="col-12">Não há itens para serem exibidos</div>
		</div>
	}
	const handleDelete = (item) => {
		const { conc_nome, name, lot_conc_desc, user_id, lot_usu_cod } = item
		const confirm = window.confirm(`Deseja mesmo remover a concessionária ${lot_conc_desc}-${conc_nome} da fiscalização do usuário ${name}`)
		if (!confirm) {
			return
		}
		deletetemUserBatch({
			id: lot_usu_cod,
			user_id: user_id,
		})
	}
	return (
		items.map(item => (
			<div key={item.lot_usu_cod} className='row'>
				<div className='col-md-10 col-sm-12'>
					<div className='row'>
						<div className='col-md-4 col-sm-4 mb-3'>
							<span className='text-secondary'>Nome do usuário: </span>
							{item.name}
						</div>
						<div className='col-md-4 col-sm-4 mb-3'>
							<span className='text-secondary'>Concessionária: </span>
							{item.conc_nome}
						</div>
						<div className='col-md-4 col-sm-4 mb-3'>
							<span className='text-secondary'>Lote: </span>
							{item.lot_conc_desc}
						</div>
					</div>
				</div>
				<div className='col-md-2 col-sm-12' onClick={() => handleDelete(item)}>
					<div className='btn btn-info btn-sm'>
						<i className='fa fa-trash'></i>
					</div>
				</div>
			</div>
		))
	)
}
export default ListuserBatch