import React, { useContext, useEffect } from 'react'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { namesScreens } from '../../../constants'
import SearchPavementIndicatorGroupIndex from '../../../components/form/searchPavementIndicatorGroupIndex'
import ListGroupsIndex from '../../../components/list/groupsIndex'
const ManagerGroupsIndex = () => {
	const { state, handleForm } = useContext(formContext)
	const { type_constructions, getItemPavementIndicatorsGroupsIndex, getWhereItemPavementIndicatorsGroupsIndex } = useContext(requestContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	const {
		gp_ind_pav_cod,
		desc,
	} = state
	useEffect(() => {
		getItemPavementIndicatorsGroupsIndex({
			id: gp_ind_pav_cod})
	}, [])
	const handleNew = () => {
		handleForm({
			gp_ind_pav_cod: '',
			gp_ind_pav_desc: ''
		})
		navigate(namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX_NEW)
	}
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = type_constructions
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return desc ? getWhereItemPavementIndicatorsGroupsIndex(current_page > 1 && `${desc}?page=${current_page - 1}`) : getItemPavementIndicatorsGroupsIndex(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return desc ? getWhereItemPavementIndicatorsGroupsIndex(current_page > 1 && `${desc}?page=${current_page - 1}`) : getItemPavementIndicatorsGroupsIndex(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return desc ? getWhereItemPavementIndicatorsGroupsIndex(desc) : getItemPavementIndicatorsGroupsIndex({id: 0})
		}
		if (desc) {
			return getWhereItemPavementIndicatorsGroupsIndex(`${desc}?page=${label}`)
		}
		getItemPavementIndicatorsGroupsIndex(`0?page=${label}`)
	}
	const RenderLinkFooter = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Grupos de índices'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title d-md-block d-none">Grupos de índices cadastrados</h3>
						<div className="card-tools">
							<SearchPavementIndicatorGroupIndex handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className='card'>
					<div className="card-body">
						<Spinner direction={'center'} label="carregando" />
						<ListGroupsIndex data={type_constructions?.data} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(type_constructions?.links) &&
                                type_constructions.links.map((item) => (<RenderLinkFooter item={item} key={item.label} />
                                ))}
						</ul>
						{user?.set_nivel === 0 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
                                        Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerGroupsIndex