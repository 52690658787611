/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ContentHeader from '../../components/contentHeader'
import SearchDealershipContract from '../../components/form/searchDealershipContract'
import ListDealership from '../../components/list/dealership'
import Spinner from '../../components/spinner'
import { requestContext } from '../../context/requestContext'
import { formContext } from '../../context/formContext'

const DealershipContract = () => {
    const { getItemDealershipContract, getWhereItemDealershipContract, contract } = useContext(requestContext)
    const { state: { conc_nome } } = useContext(formContext)
    useEffect(() => {
        getItemDealershipContract()
    }, [])
    const handlePage = (item) => {
        if (!item) {
            return
        }
        const { label } = item
        const { current_page, last_page } = contract
        window.scrollTo(0, 0);
        if (label === 'pagination.previous') {
            return conc_nome ? getWhereItemDealershipContract(current_page > 1 && `${conc_nome}?page=${current_page - 1}`) : getItemDealershipContract(current_page > 1 && `0?page=${current_page - 1}`)
        }
        if (label === 'pagination.next') {
            return conc_nome ? getWhereItemDealershipContract(current_page < last_page && `${conc_nome}?page=${current_page + 1}`) : getItemDealershipContract(current_page < last_page && `0?page=${current_page + 1}`)
        }
        if (!label) {
            return conc_nome ? getWhereItemDealershipContract(conc_nome) : getItemDealershipContract()
        }
        if (conc_nome) {
            return getWhereItemDealershipContract(`${conc_nome}?page=${label}`)
        }
        getItemDealershipContract(`0?page=${label}`)
    }
    return (
        <>
            <ContentHeader title={'Cadastro de Contrato de Concessionária'} />
            <div className='content'>
                <div className="card sticky-top">
                    <div className="card-header">
                        <h3 className="card-title col-8 d-none d-md-block">Concessionárias e contratos</h3>
                        <div className="card-tools col-md-4 col-sm-12">
                            <SearchDealershipContract />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <Spinner direction={'center'} label='carregando' />
                    <ListDealership data={contract} />
                    <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                            {Array.isArray(contract?.links) && contract.links.map((item, i) => (
                                <li className="page-item" key={item.label}>
                                    <Link className={`page-link ${item.active && 'text-light bg-info'}`}
                                        to="#" onClick={() => handlePage(item)}>
                                        {item.label === 'pagination.previous' ? <i className="fa fa-angle-left"></i> :
                                            item.label === 'pagination.next' ? <i className="fa fa-angle-right"></i> :
                                                item.label
                                        }
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DealershipContract