import { execptionWithIcons } from '../../api'

/**
 * Checks if the given field is valid.
 * @param {Object} field - The field to be validated.
 * @returns {boolean} True if the field is valid, false otherwise.
 */
export const isValid = (field) => {
	let newData = Object.keys(field).find((value) => field[value] === '')
	if (newData) {
		return false
	}
	return true
}

/**
 * Handles an error message.
 * @param {string} [message] - The error message.
 * @returns {Object} An object containing the status, message, and any additional exceptions.
 */
export const handleError = (message) => {
	const execptionWithicons = execptionWithIcons(500) // Assuming execptionWithIcons is defined elsewhere
	return {
		status: 420,
		message: message || 'Unable to complete your request',
		...execptionWithicons,
	}
}

/**
 * Handles a success message.
 * @param {string} [message] - The success message.
 * @returns {Object} An object containing the status, message, and any additional exceptions.
 */
export const handleSuccess = (message) => {
	const execptionWithicons = execptionWithIcons(200) // Assuming execptionWithIcons is defined elsewhere
	return {
		status: 200,
		message: message || 'Fill in all the fields!',
		...execptionWithicons,
	}
}
/**
 * Handles a warn message.
 * @param {string} [message] - The warn message.
 * @returns {Object} An object containing the status, message, and any additional exceptions.
 */
export const handleWaring = (message) => {
	const execptionWithicons = execptionWithIcons(400) // Assuming execptionWithIcons is defined elsewhere
	return {
		status: 400,
		message: message || 'Fill in all the fields!',
		...execptionWithicons,
	}
}

export const isValidJSON = str => {
	try {
		JSON.parse(str)
		return true
	} catch (e) {
		return false
	}
}