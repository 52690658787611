/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Service from '../../../components/form/service'
import ServiceCategory from '../../../components/form/serviceCategory'
import ServiceSubCategory from '../../../components/form/serviceSubCategory'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, isValid } from '../../../services/isValid'
const NewService = () => {
	const navigate = useNavigate()
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemService,
		getItemCategoryService, servicecat,
		getItemServiceSubCat, serviceSubcat } = useContext(requestContext)
	const {
		serv_cod,
		serv_valor,
		serv_desc,
		serv_disp,
		serv_cat_desc,
		serv_cat_disp,
		pv_servico,
		serv_cat_cod
	} = state
	useEffect(() => {
		if (serv_cod) {
			getItemCategoryService(serv_cod)
		}
	}, [serv_cod])
	useEffect(() => {
		if (serv_cod && servicecat) {
			if (servicecat?.total > 0) {
				handleForm({
					...servicecat.data[0]
				})
			}
		}
	}, [serv_cod, servicecat])
	useEffect(() => {
		if (serv_cat_cod) {
			getItemServiceSubCat(serv_cat_cod)
		}
	}, [serv_cat_cod])
	useEffect(() => {
		if (serv_cat_cod && serviceSubcat) {
			if (serviceSubcat.total > 0) {
				handleForm({
					pv_servico: serviceSubcat.data
				})
			}
		}
	}, [serv_cat_cod, serviceSubcat])
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			serv_valor: +serv_valor,
			serv_desc,
			serv_disp: +serv_disp,
			serv_cat_desc,
			serv_cat_disp: +serv_cat_disp,
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		if (!Array.isArray(pv_servico)) {
			return setInfoRequest(handleWaring('Você precisa pelo menos colocar uma Atividade'))
		}
		postItemService({
			...item,
			serv_cat_cod,
			servicos_sub_categoria: pv_servico,
			id: serv_cod || undefined
		})
	}
	const handleCancel = () => {
		handleForm({
			serv_cod: '',
			serv_cat_cod: '',
			serv_valor: '',
			serv_desc: '',
			serv_disp: '',
			pv_servico: '',
			serv_cat_desc: '',
			serv_cat_disp: '',
			serv_sub_cat_desc: '',
			serv_sub_cat_disp: '',
		})
		navigate('/service')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Serviços'} />
			<div className='content'>
				<Spinner label={'Salvando dados'} />
				<form onSubmit={onSubmit}>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Serviço</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<Service />
						</div>
					</div>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Categoria Serviço</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<ServiceCategory />
						</div>
					</div>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Atividade Serviço</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<ServiceSubCategory />
						</div>
					</div>
					<div className="card">
						<div className='card-body'>
							<button type='submit' className='btn btn-info mr-3'>
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewService