import React, { useContext, useEffect, useState } from 'react'
import { authContext } from '../../../context/authContext'
import ContentHeader from '../../../components/contentHeader'
import User from '../../../components/form/user'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getEnvironment } from '../../../api'
import { nameVersion } from '../../../constants'
import SelectLimitHighwayUser from '../../../components/form/selectLimitHighwayUser'
import ListuserBatch from '../../../components/list/userBatch'
const NewUsers = () => {
	const { state, handleForm } = useContext(formContext)
	const { showSpinner, user } = useContext(authContext)
	const { setInfoRequest, postItemUsers,
		setComplements,
		getAllItemHighwayLimit,
		limithigway,
		userLimithigway,
		getAllItemUserBatch
	} = useContext(requestContext)
	const [updateUser, setUpdateUser] = useState(true)
	const [itemsSelected, setItemsSelected] = useState(null)
	const location = useLocation()
	const navigate = useNavigate()
	const {
		new_password,
		password_compare,
		usu_emp,
		usu_ativo,
		set_cod,
		name,
		email,
		usu_gp,
		set_nivel,
		id,
		selected_concs
	} = state
	useEffect(() => {
		try {
			if (location) {
				const { state: state_params } = location
				const {
					updateUser: updateUser_
				} = state_params
				setUpdateUser(updateUser_)
			}
		} catch (error) {
			setInfoRequest(handleWaring(String(error)))
		}
	}, [location])
	useEffect(() => {
		setComplements(null)
	}, [])
	useEffect(() => {
		const loadLimitHighway = () => {
			if (usu_gp && set_nivel === 1 && id) {
				getAllItemUserBatch(id)
			}
			if (usu_gp && set_nivel > 1) {
				return getAllItemHighwayLimit(usu_gp)
			}
		}
		loadLimitHighway()
	}, [usu_gp, set_nivel, id])
	const handleCancel = () => {
		handleForm({
			new_password: '',
			password_compare: '',
			old_password: '',
			usu_email: '',
			usu_emp: '',
			usu_ativo: '',
			set_cod: '',
			id: '',
			name: '',
			email: '',
			usu_gp: ''
		})
		if (user?.set_nivel <= 1) {
			return navigate('/users')
		}
		navigate('/')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		if (set_nivel === 1 && !selected_concs) {
			return setInfoRequest(handleWaring('Você precisa especificar qual concessionária esse usuário vai fiscalizar'))
		}
		const item = {
			usu_ativo,
			usu_emp,
			email,
			usu_gp,
			name,
		}
		const valid = isValid(item)
		if (!valid) {
			return setInfoRequest(handleWaring())
		}
		if (new_password && password_compare) {
			if (new_password !== password_compare) {
				return setInfoRequest(handleWaring('Suas senhas não conferem'))
			}
		}
		return postItemUsers({
			...item,
			usu_emp,
			password: new_password,
			password_confirmation: password_compare,
			usu_ativo,
			usu_set: set_cod,
			id,
			usu_gp,
			name,
			email,
			vers_ambiente: getEnvironment(),
			vers_tipo: [
				nameVersion.APP,
				nameVersion.WEB
			],
			lote_usuario: selected_concs ? selected_concs.map(item => ({ conc_cod: parseInt(item.conc_cod), usu_email: email })) : []
		})
	}

	return (
		<>
			<ContentHeader title={'Novo usuário'} />
			<div className='content'>
				<form onSubmit={onSubmit}>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">{updateUser ? `Usuário ${name}` : 'Insira os dados do novo usuário'}</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<User />
						</div>
					</div>
					{limithigway?.total > 0 && set_nivel > 1 && (
						<div className="card card-info">
							<div className="card-header">
								<h3 className="card-title">{name ? `Limites de rodovias para ${name}` : 'Limites de rodovias para o novo usuário'}</h3>
								<div className="card-tools">
									<button type="button" className="btn btn-tool" data-card-widget="collapse">
										<i className="fas fa-minus"></i>
									</button>
									<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
										<i className="fa fa-question-circle"></i>
									</button>
								</div>
							</div>
							<div className='card-body'>
								<SelectLimitHighwayUser itemsSelected={itemsSelected} setItemsSelected={setItemsSelected} />
							</div>
						</div>
					)}
					{limithigway?.total > 0 && set_nivel <= 1 && id > 0 && (
						<div className="card card-info">
							<div className="card-header">
								<h3 className="card-title">{name ? `Concessionárias que o usuário ${name} vai fiscalizar` : 'Concessionárias que o novo usuário vai fiscalizar'}</h3>
								<div className="card-tools">
									<button type="button" className="btn btn-tool" data-card-widget="collapse">
										<i className="fas fa-minus"></i>
									</button>
									<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
										<i className="fa fa-question-circle"></i>
									</button>
								</div>
							</div>
							<div className='card-body'>
								<ListuserBatch data={userLimithigway} />
							</div>
						</div>
					)}
					<div className="card">
						<div className='card-body'>
							{showSpinner ?
								<button type="submit" className="btn btn-info mr-3">
									<div className="spinner-border spinner-border-sm" role="status" />
								</button>
								:
								<button className='btn btn-info mr-3'>
									Salvar <i className="fa fa-save"></i>
								</button>
							}
							<div className='btn btn-secondary' onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form >
			</div >
		</>
	)
}
export default NewUsers