import React, { useContext } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { authContext } from '../../../context/authContext'
const Profile = () => {
	const { user, } = useContext(authContext)
	return (
		<>
			<ContentHeader title={'Perfil'} />
			<div className='content'>
				<div className="card card-info">
					<div className="card-header">
						<h3 className="card-title">Sobre mim</h3>
					</div>

					<div className="card-body">
						<strong><i className="fas fa-envelope"></i> E-mail</strong>
						<p className="text-muted">
							{user?.email || user?.usu_email}
						</p>
						<hr />
						<strong><i className="fas fa-user"></i> Nome</strong>
						<p className="text-muted">{user?.name} | {user?.set_desc}</p>
						<hr />
						<strong><i className="fas fa-user"></i> Grupo</strong>
						<p className="text-muted">
							{user?.gp_desc}
						</p>
						<hr />
					</div>
				</div>
			</div>
		</>
	)
}
export default Profile