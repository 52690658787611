import React, { useContext } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { useNavigate } from 'react-router-dom'
import { namesScreens } from '../../../constants'
const ListTypesIndex = ({ data }) => {
	const { deleteItemTypeIndexPavementIndicators } = useContext(requestContext)
	const { handleForm } = useContext(formContext)
	const navigate = useNavigate()
	if (!Array.isArray(data)) {
		return <div className="row">
			<p>Não há itens para serem exibidos</p>
		</div>
	}
	const handleEdit = (item) => {
		handleForm({
			...item,
		})
		navigate(namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX_NEW)
	}
	const handleDelete = (item) => {
		const { tip_ind_pav_cod, tip_ind_pav_desc } = item
		const confirm = window.confirm(`Você deseja mesmo apagar o grupo ${tip_ind_pav_desc} ?`)
		if (!confirm) {
			return
		}
		deleteItemTypeIndexPavementIndicators(tip_ind_pav_cod)
	}
	return (
		data.map(item => (
			<div className='row mb-3' key={item.tip_ind_pav_cod}>
				<div className='col-md-10 col-sm-12'>
					<span className='text-secondary mr-2'>
						Descrição do tipo de índice:
					</span>
					{item.tip_ind_pav_desc}
				</div>
				<div className='col-md-2 col-sm-12' onClick={() => handleEdit(item)}>
					<button className='btn btn-info btn-sm mr-2'>
						<i className='fa fa-edit'></i>
					</button>
					<div className='btn btn-secondary btn-sm' onClick={() => handleDelete(item)}>
						<i className='fa fa-trash'></i>
					</div>
				</div>
			</div>
		))
	)
}
export default ListTypesIndex