import { useContext, useState } from "react"
import { formContext } from "../../../context/formContext"
import { ImageBox } from "../PhotoSection/ImageBox"
import { requestContext } from "../../../context/requestContext"
import './style.css'
import { formateDate, formateHours } from "../../../services/dateHandless"

export const ResumeSection = () => {
    const {bases64, data, state} = useContext(formContext)
    const {cardinal_points} = useContext(requestContext)
    const { conc_nome, 
            rod_desc, 
            rod_sent_cod, 
            rod_sent_desc,
            comp_descricao, 
            rod_km_fim, 
            rod_km_fim_mt, 
            rod_km_ini, 
            rod_km_ini_mt,
            serv_desc,
            serv_cat_desc,
            serv_sub_cat_desc,
            serv_cat_prazo
        } = state

    return (
        <>
            <h5 className="mb-2">Revisão</h5>
            <ImageBox bases64={bases64} onlyView={true} />
            <p><strong>Concessionária:</strong> {conc_nome} </p>
            <p><strong>Rodoviária:</strong> {rod_desc} </p>
            <p><strong>Sentido:</strong> {rod_sent_desc} </p>
            <p><strong>Complemento:</strong> {comp_descricao} </p>
            <p><strong>Localização inicial:</strong> {rod_km_ini}km {rod_km_ini_mt}m </p>
            <p><strong>Localização final:</strong> {rod_km_fim}km {rod_km_fim_mt}m </p>
            <p><strong>Tipo:</strong> {serv_desc} </p>
            <p><strong>Grupo:</strong> {serv_cat_desc} </p>
            <p><strong>Atividade:</strong> {serv_sub_cat_desc} </p>
            <p><strong>Prazo:</strong> {formateDate(serv_cat_prazo)} </p>
            <br />
            <button className="d-flex d-md-none btn btn-info">Salvar</button>
        </>
    )
}