import React, { useContext, useEffect, useState } from "react";
import { requestContext } from "../../../context/requestContext";
import { useNavigate } from "react-router-dom";
import { formContext } from "../../../context/formContext";
const ListTypeSubServiceCategory = ({ data }) => {
    const [items, setItems] = useState(null)
    const { deleteItemTypeSubServiceCategory } = useContext(requestContext)
    const { handleForm } = useContext(formContext)
    const navigate = useNavigate();
    useEffect(() => {
        if (data?.total > 0) {
            setItems(data.data)
        }
    }, [data])
    const handleEdit = (item) => {
        handleForm(item)
        navigate('/type_service/new')
    }
    const handleDelete = (item) => {
        const { tip_serv_sub_cat_cod, tip_serv_sub_cat_desc } = item
        const confirm = window.confirm(`Você deseja mesmo excluir tipo de serviço ${tip_serv_sub_cat_desc} ?`)
        if (confirm) {
            deleteItemTypeSubServiceCategory(tip_serv_sub_cat_cod)
        }
    }
    if (!Array.isArray(items)) {
        return <div className="col-12">Sem registros</div>
    }
    return (
        <>
            {items.map(item => (
                <div className="row p-3">
                    <div className="col-md-10 col-sm-8">
                        <div className="row">
                            <div className="col-6">
                                <span className="text-secondary mr-2">Descrição:</span>
                                {item.tip_serv_sub_cat_desc}
                            </div>
                            <div className="col-6">
                                <span className="text-secondary mr-2">Situação:</span>
                                {item.tip_serv_sub_cat_disp === 1 ? 'Ativo' : 'Inativo'}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-2">
                        <button
                            className="btn btn-info btn-sm mr-2"
                            onClick={() => handleEdit(item)}
                        >
                            <i className="ml-1 fa fa-edit"></i>
                        </button>
                        <button
                            className="btn btn-secondary btn-sm"
                            onClick={() => handleDelete(item)}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            ))}
        </>
    )
}
export default ListTypeSubServiceCategory;