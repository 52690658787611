import { Dealership } from "./Dealership"
import { Highway } from "./Highway"
import { Direction } from "./Direction"
import { Complement } from "./Complement"

export const LocalizationSection = () => {

    return (
        <>
            <h5 className="mb-2">Passo 2/4</h5>
            <h4 className="mb-2 mt-2">Rodovias</h4>
            <div className="mb-1">
                <Dealership />
            </div>
            <div className="mb-1">
                <Highway />
            </div>
            <div className="mb-1">
                <Direction />
            </div>
            <div className="mb-1">
                <Complement />
            </div>
        </>
    )
}