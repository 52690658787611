import React, { useContext } from 'react'
import ContentHeader from '../../../../components/contentHeader'
import FormHelp from '../../../../components/form/help'
import ListTypeAttachments from '../../../../components/list/typeAttachments'
import { formContext } from '../../../../context/formContext'
import { useNavigate } from 'react-router-dom'
import { requestContext } from '../../../../context/requestContext'
import { handleWaring, isValid } from '../../../../services/isValid'
const NewHelp = () => {
	const { bases64, state, setBases64, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemHelp } = useContext(requestContext)
	const {
		help_desc, email, name
	} = state
	const navigate = useNavigate()
	const handleCancel = () => {
		handleForm({
			hel_cod: '',
			help_desc: '',
			hel_usu_request: '',
			hel_usu_response: '',
			help_desc_response: '',
		})
		navigate('/help')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		window.scrollTo(0, 0)
		const valid = isValid({
			help_desc, email, name
		})
		if (!valid) {
			return setInfoRequest(handleWaring())
		}
		postItemHelp({
			help_desc,
			name,
			email,
			help_attachment: Array.isArray(bases64) ? [...bases64] : null
		}, () => {
			setBases64(null)
			handleForm({
				ane_pl_ob_obs: '',
				help_desc: ''
			})
		})

	}
	return (
		<>
			<ContentHeader title={'Dúvdias ou solicitação de suporte'} />
			<div className='content'>
				<form onSubmit={onSubmit}>
					<FormHelp />
					{Array.isArray(bases64) && (
						<div className="card card-info">
							<div className="card-header">
								<h3 className="card-title">
                                    Anexos adicionados
								</h3>
								<div className="card-tools">
									<button
										type="button"
										className="btn btn-tool"
										data-card-widget="collapse"
									>
										<i className="fas fa-minus"></i>
									</button>
									<button
										type="button"
										className="btn btn-tool"
										data-toggle="modal"
										data-target="#concessionaria"
									>
										<i className="fa fa-question-circle"></i>
									</button>
								</div>
							</div>
							<div className="card-body">
								<ListTypeAttachments
									addedItems={bases64}
								/>
							</div>
						</div>
					)}
					<div className="card">
						<div className='card-body'>
							<button className='btn btn-info mr-3'>
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewHelp