import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../../components/contentHeader'
import Spinner from '../../../../components/spinner'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
import { handleWaring, isValid } from '../../../../services/isValid'
import { namesScreens } from '../../../../constants'
import FormServiceConstruction from '../../../../components/form/servicesConstruction'
const NewServiceConstructions = () => {
	const { handleForm, state } = useContext(formContext)
	const {
		setInfoRequest, postItemServiceConstructions
	} = useContext(requestContext)
	const {
		tip_ob_cod,
		serv_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		ob_serv_med_cod,
		serv_desc,
		serv_sub_cat_desc
	} = state
	const navigate = useNavigate()
	const onSubmit = (e) => {
		window.scrollTo(0, 0)
		e.preventDefault()
		let payload = {}
		if (!serv_sub_cat_cod) {
			const payload = {
				serv_desc,
				serv_sub_cat_desc,
				tip_ob_cod,
				ob_serv_med_cod
			}
			const validate = isValid(payload)
			if (!validate) {
				return setInfoRequest(handleWaring())
			}
		} else {
			payload = {
				id: serv_sub_cat_cod,
				serv_cod,
				serv_cat_cod,
				tip_ob_cod,
				ob_serv_med_cod,
				serv_desc,
				serv_sub_cat_desc,
			}
			const validate = isValid(payload)
			if (!validate) {
				return setInfoRequest(handleWaring())
			}
		}
		postItemServiceConstructions(payload)
	}
	const handleCancel = () => {
		handleForm({
			tip_ob_cod: '',
			tip_ob_desc: '',
			tip_ob_sub_cod: '',
			tip_ob_sub_desc: '',
			tip_ob_sub_tip_ob: '',
			tip_sub_ob: '',
		})
		navigate(namesScreens.SERVICES_CONSTRUCTIONS)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Discplinas e Serviços'} />
			<div className="content">
				<Spinner label={'Salvando dados'} />
				<form onSubmit={onSubmit}>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Informações sobre a Disciplina e serviços</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormServiceConstruction />
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<button type="submit" className="btn btn-info mr-3">
								Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={() => handleCancel()}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewServiceConstructions
