import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import SearchDealership from '../../components/form/searchDealership'
import ListDealership from '../../components/list/dealership'
import { formContext } from '../../context/formContext'
import Spinner from '../../components/spinner'
const ManagerDealership = () => {
	const { getItemDealership, getWhereItemDealership, dealership, setContractAttachment, setContract } = useContext(requestContext)
	const { handleForm, state } = useContext(formContext)
	const {
		conc_nome
	} = state
	const navigate = useNavigate()
	useEffect(() => {
		getItemDealership()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = dealership
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return conc_nome ? getWhereItemDealership(current_page > 1 && `${conc_nome}?page=${current_page - 1}`) : getItemDealership(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return conc_nome ? getWhereItemDealership(current_page < last_page && `${conc_nome}?page=${current_page + 1}`) : getItemDealership(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return getItemDealership()
		}
		if (conc_nome) {
			return getWhereItemDealership(`${conc_nome}?page=${label}`)
		}
		if (conc_nome) {
			return getWhereItemDealership(`${conc_nome}?page=${label}`)
		}
		getItemDealership(`0?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			ane_desc: '',
			cont_cod: '',
			conc_cod: '',
		})
		setContract(null)
		setContractAttachment(null)
		navigate('/dealership/new')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Concessionária'} />
			<div className='content'>
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">Concessionárias cadastradas</h3>
						<div className="card-tools col-md-4 col-sm-12">
							<SearchDealership handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<div className='card-body'>
						<Spinner direction={'center'} label='carregando' />
						<ListDealership data={dealership} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(dealership?.links) && dealership.links.map((item) => (
								<li className="page-item" key={item.label}>
									<Link className={`page-link ${item.active && 'text-light bg-info'}`}
										to="#" onClick={() => handlePage(item)}>
										{item.label === 'pagination.previous' ? <i className="fa fa-angle-left"></i> :
											item.label === 'pagination.next' ? <i className="fa fa-angle-right"></i> :
												item.label
										}
									</Link>
								</li>
							))}
						</ul>
						<ul className="pagination pagination-sm m-0 float-left">
							<li className="page-item">
								<div className="page-link" onClick={handleNew}>
									Novo <i className='fa fa-plus mr-1 ml-1'></i>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerDealership