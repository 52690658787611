import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import { handleWaring } from '../../../services/isValid'
const FormDealershipNew = () => {
	const [loadSearchCEP, setLoadSearchCEP] = useState(false)
	const { state, onChange, handleForm, CEP, CNPJ } = useContext(formContext)
	const { infoaddress, getInfoAdress, setInfoRequest } = useContext(requestContext)
	const element_conc_numero = useRef()
	const {
		conc_nome,
		conc_cep,
		conc_razaosocial,
		conc_endereco,
		conc_numero,
		conc_bairro,
		mun_nome,
		est_uf,
		mun_uf,
		conc_complemento,
		conc_cod,
		conc_cnpj,
		lot_conc_desc,
		conc_color,
	} = state
	useEffect(() => {
		if (conc_cep.length === 9 && !conc_cod) {
			setLoadSearchCEP(true)
			try {
				getInfoAdress(conc_cep)
			} finally {
				setLoadSearchCEP(false)
			}
		}
	}, [conc_cep])
	useEffect(() => {
		if (conc_cod && !est_uf) {
			handleForm({
				est_uf: mun_uf
			})
		}
	}, [conc_cod, est_uf])
	useEffect(() => {
		if (infoaddress && !conc_cod) {
			handleForm({
				conc_endereco: infoaddress.logradouro,
				conc_complemento: infoaddress.complemento,
				conc_bairro: infoaddress.bairro,
				mun_nome: infoaddress.localidade,
				conc_cidade: infoaddress.ibge,
				dd: infoaddress.ddd,
				est_uf: infoaddress.uf
			})
			if (element_conc_numero) {
				element_conc_numero.current?.focus()
			}
		}
	}, [infoaddress])
	const searchCep = async () => {
		if (!conc_cep || conc_cep?.length !== 9) {
			return setInfoRequest(handleWaring('Digite o CEP corretamente para poder pesquisar'))
		}
		setLoadSearchCEP(true)
		await getInfoAdress(conc_cep)
		return setLoadSearchCEP(false)
	}
	return (
		<div className='row'>
			<div className="form-group col-md-8 col-sm-12">
				<label>Nome da Concessionária:</label>
				<div className="input-group">
					<input type="text" onChange={onChange}
						className={`form-control form-control-border ${!conc_nome && 'is-invalid'}`}
						name='conc_nome' value={conc_nome}

						placeholder='Nome da Concessionária' />
				</div>
			</div>
			<div className="form-group col-md-4 col-sm-12">
				<label>N° do Lote</label>
				<div className='input-group'>
					<input type='text' onChange={onChange}
						className={`form-control form-control-border ${!lot_conc_desc && 'is-invalid'}`}
						placeholder="Numero do contrato"
						name='lot_conc_desc' value={lot_conc_desc}
					/>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Razão social da Concessionária:</label>
				<div className="input-group">
					<input type="text" onChange={onChange}
						className={`form-control form-control-border ${!conc_razaosocial && 'is-invalid'}`}
						name='conc_razaosocial' value={conc_razaosocial}
						placeholder='Razão social da Concessionária' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>CPNJ da Concessionária:</label>
				<div className="input-group">
					<input type="text" onChange={onChange}
						className={`form-control form-control-border ${!conc_cnpj && 'is-invalid'}`}
						name='conc_cnpj' value={CNPJ(conc_cnpj)}
						disabled={conc_cod}
						placeholder='CNPJ da Concessionária' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>CEP:</label>
				<div className="input-group">
					<input type="text" onChange={CEP}
						name='conc_cep' value={conc_cep}
						maxLength={9}
						className={`form-control form-control-border ${!conc_cep && 'is-invalid'}`}
						placeholder='00000-000' />
					{loadSearchCEP ? (
						<div className='input-group-prepend'>
							<span className='input-group-text bg-info'
								style={styleButtonSearch}>
								<div className="spinner-border spinner-border-sm text-light" role="status">
									<span className="sr-only"></span>
								</div>
							</span>
						</div>
					)
						:
						<div className="input-group-prepend" onClick={searchCep}>
							<span className="input-group-text bg-info"
								style={styleButtonSearch}
							>
								<i className="fa fa-search"></i>
							</span>
						</div>
					}
				</div>
			</div>
			<div className='col-8'>

			</div>
			<div className="form-group col-md-10 col-sm-12">
				<label>Endereço:</label>
				<div className="input-group">
					<input type="text"
						name='conc_endereco' value={conc_endereco}
						className={`form-control form-control-border ${!conc_endereco && 'is-invalid'}`}
						placeholder='Endereço da Concessionária'
						disabled />
				</div>
			</div>
			<div className="form-group col-md-2 col-sm-4">
				<label>Numero:</label>
				<div className="input-group">
					<input type="text"
						name='conc_numero' value={conc_numero}
						onChange={onChange}
						className={`form-control form-control-border ${!conc_numero && 'is-invalid'}`}
						ref={element_conc_numero}
						placeholder='N° do endereço' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Bairro:</label>
				<div className="input-group">
					<input type="text"
						name='conc_bairro' value={conc_bairro}
						className={`form-control form-control-border ${!conc_bairro && 'is-invalid'}`}
						disabled
						placeholder='Bairro da Concessionária' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Cidade:</label>
				<div className="input-group">
					<input type="text"
						name='mun_nome' value={mun_nome}
						className={`form-control form-control-border ${!mun_nome && 'is-invalid'}`}
						disabled
						placeholder='Cidade da Concessionária' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Estado:</label>
				<div className="input-group">
					<input type="text" className={`form-control form-control-border ${!est_uf && 'is-invalid'}`}
						name='est_uf' value={est_uf}
						disabled>
					</input >
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Complemento:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='conc_complemento' value={conc_complemento}
						className="form-control form-control-border"
						placeholder='Complemento da Concessionária' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Cor para gráficos:</label>
				<div className="input-group">
					<input type="color"
						onChange={onChange}
						name='conc_color' value={conc_color}
						className="form-control form-control-border" />
				</div>
			</div>
		</div >
	)
}
export default FormDealershipNew