import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { formContext } from "../../../context/formContext";
import { requestContext } from "../../../context/requestContext";
import { namesScreens } from "../../../constants";
const ListServiceConstruction = ({ data }) => {
  const { handleForm } = useContext(formContext);
  const { deleteItemTypeConstructions } = useContext(requestContext);
  const navigate = useNavigate();
  const onClick = (item) => {
    handleForm({
      ...item,
    });
    navigate(namesScreens.SERVICES_CONSTRUCTIONS_NEW);
  };
  const handleDelete = (item) => {
    const confirm = window.confirm(
      `Você deseja mesmo excluir o serviço ${item.serv_sub_cat_desc}?`
    );
    if (!confirm) {
      return;
    }
    deleteItemTypeConstructions(item.serv_sub_cat_cod);
  };
  return data.data.map((item) => (
    <div className="row p-3 mb-3" key={item.serv_sub_cat_cod}>
      <div className="col-md-10 col-sm-8 mb-3">
        <div className="row">
          <div className="mb-3 col-md-4 col-sm-6">
            <span className="text-secondary mr-2">Disciplina:</span>
            {item.serv_desc}
          </div>
          <div className="mb-3 col-md-4 col-sm-6">
            <span className="text-secondary mr-2">Sub Disciplina:</span>
            {item.serv_cat_desc}
          </div>
          <div className="mb-3 col-md-4 col-sm-6">
            <span className="text-secondary mr-2">Serviço:</span>
            {item.serv_sub_cat_desc}
          </div>
          <div className="mb-3 col-md-4 col-sm-6">
            <span className="text-secondary mr-2">Tipo de obra:</span>
            {item.tip_ob_desc}
          </div>
          <div className="col-md-6 col-sm-6">
            <span className="text-secondary mr-2">
              Unidade de medida:
            </span>
            {item.ob_serv_med_desc}
          </div>
        </div>
      </div>
      <div className="col-md-2 col-sm-4">
        <button
          className="btn btn-sm btn-info mr-2"
          onClick={() => onClick(item)}
        >
          <i className="fa fa-edit"></i>
        </button>
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => handleDelete(item)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <div className="col-12">
        <hr />
      </div>
    </div>
  ));
};
export default ListServiceConstruction;
