import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import Spinner from '../../components/spinner'
import SearchUsers from '../../components/form/searchUsers'
import ListUsers from '../../components/list/users'
import { formContext } from '../../context/formContext'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../context/authContext'
import { handleWaring } from '../../services/isValid'
import { nameVersion } from '../../constants'
import { getEnvironment } from '../../api'
import FilterUser from '../../components/form/filters/user'
const ManagerUsers = () => {
	const { postSendItemResetPassword, setInfoRequest, getItemUsers, getWhereItemUsers, complements, getAllItemHighwayLimit } = useContext(requestContext)
	const { handleForm, state: { name, set_nivel, set_cod, conc_cod, gp_cod } } = useContext(formContext)
	const { user } = useContext(authContext)
	const [resetPassword, setResetPassWord] = useState(null)
	const [isListResetPassword, setIsListResetPassword] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		getItemUsers({
			id: 0,
			set_nivel,
			set_cod,
			conc_cod,
			gp_cod,
		})
		getAllItemHighwayLimit()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = complements
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return name ? getWhereItemUsers(current_page > 1 && `${name}?page=${current_page - 1}`) : getItemUsers({
				id: 0,
				set_nivel,
				set_cod,
				conc_cod,
				gp_cod: current_page > 1 && `${gp_cod}?page=${current_page - 1}`
			})
		}
		if (label === 'pagination.next') {
			return name ? getWhereItemUsers(current_page < last_page && `${name}?page=${current_page + 1}`) : getItemUsers({
				id: 0,
				set_nivel,
				set_cod,
				conc_cod,
				gp_cod: current_page < last_page && `${gp_cod}?page=${current_page + 1}`
			})
		}
		if (!label) {
			return name ? getWhereItemUsers(name) : getItemUsers({
				id: 0,
				set_nivel,
				set_cod,
				conc_cod,
				gp_cod,
			})
		}
		if (name) {
			return getWhereItemUsers(`${name}?page=${label}`)
		}
		getItemUsers({
			id: 0,
			set_nivel,
			set_cod,
			conc_cod,
			gp_cod: `${gp_cod}?page=${label}`
		})
	}
	const handleNew = () => {
		handleForm({
			new_password: '',
			password_compare: '',
			old_password: '',
			usu_email: '',
			usu_emp: '',
			usu_ativo: '',
			set_cod: '',
			id: '',
			name: '',
			email: '',
			emp_cod: '',
			emp_nome: '',
			usu_gp: '',
			gp_cod: '',
			gp_desc: '',
		})
		navigate('/users/new', { state: { updateUser: false } })
	}
	const handleMarkOnOff = () => {
		setIsListResetPassword(false)
		setResetPassWord(null)
	}
	const handleSubmit = () => {
		if (!Array.isArray(resetPassword)) {
			return setInfoRequest(handleWaring('Você precisa selecionar pelo menos um usuário'))
		}
		return postSendItemResetPassword({
			emails: [...resetPassword],
			vers_ambiente: getEnvironment(),
			vers_tipo: [
				nameVersion.APP,
				nameVersion.WEB
			]
		})
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Usuários'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<div className="row">
							<h3 className="card-title col-4 d-none d-md-block">
								Usuários cadastrados
							</h3>
							<div className="card-tools col-8">
								<div className="row">
									<div className="col-6">
										{!Array.isArray(resetPassword) ? (
											<Link to="#"
												className={isListResetPassword ? 'btn btn-sm btn-info mr-2' : 'btn btn-sm btn-dark mr-2'}
												onClick={() => setIsListResetPassword(!isListResetPassword)}>
												Resetar senha
											</Link>
										) : (
											<Link to="#"
												className="btn btn-sm btn-dark mr-2"
												onClick={handleSubmit}>
												Enviar nova senha <i className="fa fa-paper-plane"></i>
											</Link>
										)}
										{isListResetPassword && Array.isArray(resetPassword) && (
											<Link to="#"
												className="btn btn-sm btn-tools"
												onClick={handleMarkOnOff}>
												<i className="fa fa-check-square"></i> Selecionado {resetPassword?.length}
											</Link>
										)}
									</div>
									<SearchUsers handleNew={handleNew} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<FilterUser />
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListUsers data={complements?.data} isListResetPassword={isListResetPassword} resetPassword={resetPassword} setResetPassWord={setResetPassWord} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(complements?.links) &&
								complements.links.map((item) => (<li className="page-item" key={item.label}>
									<Link
										className={`page-link ${item.active && 'text-light bg-info'
										}`}
										to="#"
										onClick={() => handlePage(item)}
									>
										{item.label === 'pagination.previous' ? (
											<i className="fa fa-angle-left"></i>
										) : item.label === 'pagination.next' ? (
											<i className="fa fa-angle-right"></i>
										) : (
											item.label
										)}
									</Link>
								</li>))}
						</ul>
						{user && user.set_nivel === 1 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerUsers
