import React, { useContext, useEffect, useRef, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { generateRandomColorPaletteWithBrightness, groupBy } from '../../../services/arrayHandless'
import { namesScreens } from '../../../constants'
import { formContext } from '../../../context/formContext'
import { criarDatasetCompletado, geneateDataChartPizzaPorCenExec, generateItemsCalculedAcPr } from '../../../services/constructionPlan'
import { formateDate, parseFromMysqlDateToJSDate } from '../../../services/dateHandless'
import { useReactToPrint } from 'react-to-print'
import { useNavigate } from 'react-router-dom'
import { handleSuccess, handleWaring } from '../../../services/isValid'
import ListServiceConstructionPlan from '../../../components/table/serviceConstructionPlan'
import FormConfigReportConstructionPlan from '../../../components/form/configReportContructionPlan'
import MonitoringTable from '../../../components/table/constructionPlan/monitoring'
import { ChartPie, LineChart } from '../../../components/charts'
import ListAttachmentsContructionPlan from '../../../components/list/attachmentsContructionPlan'
import ListMidias from '../../../components/list/midias'
import { styleButtonSearch } from '../../../css/globlalStyle'
const ReportConstructionPlan = () => {
	const {
		onChange,
		state: {
			sisf_sinc_int_amp_id,
			pl_ob_cod,
			conc_nome,
			rod_km,
			rod_desc,
			rod_sent_desc,
			pv_loc_km_ini,
			pv_loc_km_init_mts,
			pv_loc_km_fim,
			pv_loc_km_fim_mts,
			pl_ob_desc_object,
			pl_ob_cod_item,
			pvs_prazo_ini,
			pvs_prazo_fim,
			pl_ob_ped_venda,
			ped_venda_date_from,
			ped_venda_date_to,
			show_progress_all_service,
			show_info_construction_plan,
			show_work_schedule,
			show_chart_resume_progress,
			show_attachments,
			show_photos_in_report
		} } = useContext(formContext)
	const {
		setInfoRequest,
		getItemOversightPhotos,
		getItemAttachmentsConstructionPlan,
		getItemMonitoringProgressServiceWork,
		service,
		monitoring_progress,
		monitoring_progress_work,
		attachments_contruction_plan,
		monitoring_progressservice_work
	} = useContext(requestContext)
	const [printView, setPrintView] = useState(false)
	const [services, setServices] = useState([])
	const [servicesMonitoring, setServicesMonitoring] = useState([])
	const [chartPizzaPorCenExec, setChartPizzaPorCenExec] = useState(null)
	const [chartLineAdvances, setChartLineAdvances] = useState(null)
	const [photos, setPhotos] = useState([])
	const [attachments, setAttchments] = useState([])
	const [viewMonitoring, setViewMonitoring] = useState(false)
	const componentRef = useRef(null)
	const navigate = useNavigate()
	const generateReport = () => {
		setPrintView(true)
		setInfoRequest(handleWaring('Aguarde o relatório ser gerado'))
		setTimeout(() => {
			handlePrint()
		}, 6000)
		setInfoRequest(handleSuccess('Relatório gerado com sucesso'))
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Relatório de Plano de Obra',
		copyStyles: true,
		onAfterPrint: () => onAfterPrint(),
	})
	const onAfterPrint = () => {
		setPrintView(false)
		navigate(namesScreens.CONSTRUCTION_PLAN)
	}
	useEffect(() => {
		const controllViewMonitoring = () => {
			if (!ped_venda_date_from || !ped_venda_date_to) {
				return setViewMonitoring(false)
			}
		}
		controllViewMonitoring()
	}, [ped_venda_date_from, ped_venda_date_to])
	useEffect(() => {
		const controllItems = () => {
			if (service?.data) {
				const { data } = service
				let dataWithCodigo = []
				dataWithCodigo = data.map(item => ({
					...item,
					gp_codigo: parseInt(String(item.serv_sub_cat_subitem_der).substring(4, 6))
				}))
				const pl_ob_reais_prev = data.reduce((previuesValues, currentValues) => previuesValues + (currentValues.pvs_qtd * currentValues.pvs_valor), 0)
				const sumCalcAcProg = generateItemsCalculedAcPr({
					dataWithCodigo,
					pl_ob_reais_prev
				})
				const group = groupBy(sumCalcAcProg, 'gp_codigo')
				setServices(group)
			}
		}
		controllItems()
	}, [service])
	useEffect(() => {
		const controllItems = () => {
			if (Array.isArray(service?.data)) {
				const { data } = service
				const chartData = geneateDataChartPizzaPorCenExec({
					data, state: { sisf_sinc_int_amp_id, pl_ob_cod, conc_nome }
				})
				setChartPizzaPorCenExec(chartData)
			}
		}
		controllItems()
	}, [service])
	const fetchServicesMonitoring = () => {
		if (pl_ob_ped_venda) {
			getItemMonitoringProgressServiceWork({
				id: pl_ob_ped_venda,
				apos_data_from: ped_venda_date_from,
				apos_data_to: ped_venda_date_to
			})
			setViewMonitoring(true)
		}
	}
	useEffect(() => {
		const controllItems = () => {
			if (Array.isArray(monitoring_progressservice_work?.data)) {
				const { data } = monitoring_progressservice_work
				const dataWithCodigo = data.map(item => ({
					...item,
					gp_codigo: parseInt(String(item.serv_sub_cat_subitem_der).substring(4, 6))
				}))
				const group = groupBy(dataWithCodigo, 'gp_codigo')
				setServicesMonitoring(group)
			}
		}
		controllItems()
	}, [monitoring_progressservice_work])
	useEffect(() => {
		const controllItems = () => {
			try {
				if (Array.isArray(monitoring_progress?.data) && Array.isArray(monitoring_progress_work?.data)) {
					const { data: tempAvancoExecutado } = monitoring_progress_work
					const { data: avancoPrevisto } = monitoring_progress
					const groupByData = groupBy(tempAvancoExecutado, 'apo_data')
					const avancoExecutado = []
					Object.keys(groupByData).forEach(item => {
						if (Array.isArray(groupByData[item])) {
							const lass = groupByData[item].sort((a, b) => new Date(a.apo_prog_ob_por_cen_progress) - new Date(b.apo_prog_ob_por_cen_progress))[groupByData[item].length - 1]
							avancoExecutado.push({
								...lass
							})
						}
					})
					const todasAsDatas = [
						...new Set([...avancoPrevisto.map(item => item.acob_date_prev_fim)]),
						...avancoExecutado.map(item => item.apo_data)
					].slice(1).sort((a, b) => new Date(a) - new Date(b))

					const avancoExecutadoCompletado = criarDatasetCompletado(avancoExecutado, todasAsDatas, 'apo_prog_ob_por_cen_progress', 'apo_data')
					const avancoPrevistoCompletado = criarDatasetCompletado(avancoPrevisto, todasAsDatas, 'acob_perc_prev', 'acob_date_prev_fim')
					const colors = generateRandomColorPaletteWithBrightness(2)
					const data = {
						labels: todasAsDatas.map(data => formateDate(data)),
						datasets: [
							{
								label: 'Avanço Executado',
								data: [...avancoExecutadoCompletado],
								borderColor: colors[0].color,
								backgroundColor: colors[0].brightness,
								fill: false,
								tension: 0.1
							},
							{
								label: 'Avanço Previsto',
								data: avancoPrevistoCompletado,
								borderColor: colors[1].color,
								backgroundColor: colors[1].brightness,
								fill: false,
								tension: 0.1
							}
						]
					}
					setChartLineAdvances(data)
				}
			} catch (error) {
				console.log(error)
			}
		}
		controllItems()
	}, [monitoring_progress, monitoring_progress_work])
	const fetchPhotosMovies = async () => {
		if (Array.isArray(service?.data)) {
			const { data } = service
			const pvsCods = data.map(item => item.prog_serv_serv_cod)
			const respone = await getItemOversightPhotos({
				id: pvsCods,
				ped_venda_date_from: pvs_prazo_ini,
				ped_venda_date_to: pvs_prazo_fim
			})
			if (respone?.data?.length) {
				const { data } = respone
				const newItems = data.map(item => {
					const created_at = parseFromMysqlDateToJSDate(item.created_at)
					return {
						...item,
						timestamp: new Date(created_at).getTime()
					}
				})
				return setPhotos(newItems)
			}
			setPhotos([])
		}
	}
	useEffect(() => {
		fetchPhotosMovies()
	}, [service])
	const fetchAttachments = () => {
		if (pl_ob_cod) {
			getItemAttachmentsConstructionPlan(pl_ob_cod)
		}
	}
	useEffect(() => {
		fetchAttachments()
	}, [pl_ob_cod])
	useEffect(() => {
		const controllItems = () => {
			if (Array.isArray(attachments_contruction_plan?.data)) {
				const { data } = attachments_contruction_plan
				const newItems = data.map(item => {
					const created_at = parseFromMysqlDateToJSDate(item.created_at)
					return {
						...item,
						timestamp: new Date(created_at).getTime()
					}
				})
				setAttchments(newItems)
			}
		}
		controllItems()
	}, [attachments_contruction_plan])
	return (
		<div className='container-fluid p-3'>
			<div className='card'>
				<div className='card-header'>
					<div className='card-title'>
						Opções de relatório
					</div>
				</div>
				<div className='card-body'>
					<FormConfigReportConstructionPlan viewMonitoring={viewMonitoring} fetchServicesMonitoring={fetchServicesMonitoring} handlePrint={generateReport} />
				</div>
			</div>
			<div className='container' ref={componentRef}>
				{show_info_construction_plan ? (
					<div className='card'>
						<div className='card-header'>
							<div className='card-title'>
								informações sobre a obra
							</div>
						</div>
						<div className='card-body row'>
							<div className='form-group col-6'>
								<label>Concessionária N° lote</label>
								<div className="input-group">
									<div className='form-control form-control-border'>
										{conc_nome}
									</div>
								</div>
							</div>
							<div className="form-group col-6">
								<label>Rodovia:</label>
								<div className="input-group">
									<div className="form-control form-control-border">
										{rod_km} {rod_desc}
									</div>
								</div>
							</div>
							<div className="form-group col-4">
								<label>Sentido:</label>
								<div className="input-group">
									<div className="form-control form-control-border">
										{rod_sent_desc}
									</div>
								</div>
							</div>
							<div className="form-group col-4">
								<label>KM Inicial:</label>
								<div className="input-group">
									<div className="form-control form-control-border">
										{pv_loc_km_ini}, {pv_loc_km_init_mts} mts
									</div>
								</div>
							</div>
							<div className="form-group col-4">
								<label>KM Final:</label>
								<div className="input-group">
									<div className="form-control form-control-border">
										{pv_loc_km_fim}, {pv_loc_km_fim_mts} mts
									</div>
								</div>
							</div>
							<div className="form-group col-md-6 col-sm-12">
								<label>Nome da Obra ou do objeto:</label>
								<div className="input-group">
									<div className='form-control form-control-border'
									>
										{pl_ob_desc_object}
									</div>
								</div>
							</div>
							<div className="form-group col-md-6 col-sm-12">
								<label>Item de serviço:</label>
								<div className="input-group">
									<div className='form-control form-control-border'
									>
										{pl_ob_cod_item}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
				{show_progress_all_service ? (
					<div className='card'>
						<div className='card-header'>
							<h5 className='card-title'>Serviços</h5>
						</div>
						<div className='card-body'>
							{(services && !viewMonitoring) ? Object.keys(services).sort((a, b) => a - b).map(item => (
								<ListServiceConstructionPlan key={item} items={services[item]} />
							)) : null}
							{(servicesMonitoring && viewMonitoring) ? Object.keys(servicesMonitoring).sort((a, b) => a - b).map(item => (
								<ListServiceConstructionPlan key={item} items={servicesMonitoring[item]} />
							)) : null}
						</div>
						<div className='card-footer'>
							<p className='card-text'>
								{viewMonitoring ? 'Você está vendo um histórico de avanços' : ''}
							</p>
						</div>
					</div>
				) : null}
				{show_work_schedule ? (
					<div className='card'>
						<div className='card-header'>
							<h5 className='card-title'>Cronograma da Obra</h5>
						</div>
						<div className='card-body'>
							{Array.isArray(monitoring_progress?.data) && (
								<MonitoringTable items={monitoring_progress?.data} />
							)}
						</div>
					</div>
				) : null}
				{show_chart_resume_progress ? (
					<div className='row align-items-center'>
						<div className='col-md-6 col-sm-12'>
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">Percentuais de Execução</h5>
								</div>
								<div className="card-body">
									{chartPizzaPorCenExec && (
										<ChartPie data={chartPizzaPorCenExec} />
									)}
								</div>
							</div>
						</div>
						<div className='col-md-6 col-sm-12'>
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">Progresso Previsto e Executado</h5>
								</div>
								<div className="card-body">
									{chartLineAdvances && (
										<LineChart data={chartLineAdvances} />
									)}
								</div>
							</div>
						</div>
					</div>
				) : null}
				{show_attachments ? (
					<div className='card'>
						<div className='card-header'>
							<h5 className='card-title'>
								Anexos da Obra
							</h5>
						</div>
						<div className='card-body'>
							{attachments?.length ? (
								<ListAttachmentsContructionPlan items={attachments} />
							) : null}
						</div>
					</div>
				) : null}
				{show_photos_in_report ? (
					<div className='card'>
						<div className='card-header'>
							<h5 className='card-title'>
								Fotos da Obra
							</h5>
						</div>
						<div className='card-body'>
							{!printView ? (
								<div className='row'>
									<div className='col-md-6 col-sm-12'>
										<div className="input-group">
											<input
												type="date"
												className="form-control form-control-border"
												name="pvs_prazo_ini"
												value={pvs_prazo_ini}
												onChange={onChange}
											/>
										</div>
									</div>
									<div className='col-md-6 col-sm-12'>
										<div className="input-group">
											<input
												type="date"
												className="form-control form-control-border"
												name="pvs_prazo_fim"
												value={pvs_prazo_fim}
												onChange={onChange}
											/>
											<div className="input-group-prepend" onClick={fetchPhotosMovies}>
												<span
													className="input-group-text bg-danger"
													style={styleButtonSearch}
												>
													<i className="fa fa-search" aria-hidden="true"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							) : null}
							{photos.length ? (
								<ListMidias items={photos} />
							) : null}
						</div>
					</div>
				) : null}
			</div>
		</div>
	)
}
export default ReportConstructionPlan