import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import SearchSpreedsheetConstructionPlan from '../../components/form/searchSpreedsheetConstructionPlan'
import { formContext } from '../../context/formContext'
import Spinner from '../../components/spinner'
import ListSpreedsheetConstructionPlan from '../../components/list/spreedsheetConstructionPlan'
const MangegerSpreedsheetDefault = () => {
	const {
		getItemDefaultSpreedSheetConstructionPlan,
		getWhereItemDefaultSpreedSheetConstructionPlan,
		data,
		setData
	} = useContext(requestContext)
	const { handleForm, state } = useContext(formContext)
	const {
		tip_ob_desc,
		tip_ob_cod
	} = state
	useEffect(() => {
		const loadItem = () => {
			if (!tip_ob_cod) {
				return getItemDefaultSpreedSheetConstructionPlan('0')
			}
			getItemDefaultSpreedSheetConstructionPlan(tip_ob_cod)
		}
		loadItem()
	}, [tip_ob_cod])
	const navigate = useNavigate()

	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = data
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return tip_ob_desc ? getWhereItemDefaultSpreedSheetConstructionPlan(current_page > 1 && `${tip_ob_desc}?page=${current_page - 1}`) : getItemDefaultSpreedSheetConstructionPlan(current_page > 1 && `${tip_ob_cod}?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return tip_ob_desc ? getWhereItemDefaultSpreedSheetConstructionPlan(current_page < last_page && `${tip_ob_desc}?page=${current_page + 1}`) : getItemDefaultSpreedSheetConstructionPlan(current_page < last_page && `${tip_ob_cod}?page=${current_page + 1}`)
		}
		if (!label) {
			return getItemDefaultSpreedSheetConstructionPlan(tip_ob_cod)
		}
		if (tip_ob_desc) {
			return getWhereItemDefaultSpreedSheetConstructionPlan(`${tip_ob_desc}?page=${label}`)
		}
		if (tip_ob_desc) {
			return getWhereItemDefaultSpreedSheetConstructionPlan(`${tip_ob_desc}?page=${label}`)
		}
		getItemDefaultSpreedSheetConstructionPlan(`${tip_ob_cod}?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			tip_ob_desc: '',
			tip_ob_cod: '',
		})
		setData(null)
		navigate('/spreedsheet_default/new')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Planilhas Modelos p/ Plano de Obras'} />
			<div className='content'>
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">Planilhas Modelos p/ Plano de Obras cadastradas</h3>
						<div className="card-tools col-md-4 col-sm-12">
							<SearchSpreedsheetConstructionPlan handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label='carregando' />
					<div className='card-body'>
						<ListSpreedsheetConstructionPlan data={data?.data} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(data?.links) && data.links.map((item) => (
								<li className="page-item" key={item.label}>
									<Link className={`page-link ${item.active && 'text-light bg-info'}`}
										to="#" onClick={() => handlePage(item)}>
										{item.label === 'pagination.previous' ? <i className="fa fa-angle-left"></i> :
											item.label === 'pagination.next' ? <i className="fa fa-angle-right"></i> :
												item.label
										}
									</Link>
								</li>
							))}
						</ul>
						<ul className="pagination pagination-sm m-0 float-left">
							<li className="page-item">
								<div className="page-link" onClick={handleNew}>
									Novo <i className='fa fa-plus mr-1 ml-1'></i>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default MangegerSpreedsheetDefault