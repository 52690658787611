import React, { useContext } from 'react'
import ContentHeader from '../../../../components/contentHeader'
import { requestContext } from '../../../../context/requestContext'
import { formContext } from '../../../../context/formContext'
import { handleWaring, isValid } from '../../../../services/isValid'
import { useNavigate } from 'react-router-dom'
import ElementWorkOfArt from '../../../../components/form/elementWorkOfArt'
const NewWorkOfArtElement = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemObArtElement } = useContext(requestContext)
	const naviate = useNavigate()
	const {
		ob_art_elem_cod,
		ob_art_elem_ativo,
		ob_art_elem_desc,
	} = state
	const handleCancel = () => {
		handleForm({
			ob_art_elem_cod: '',
			ob_art_elem_oae: '',
			ob_art_elem_desc: '',
			ob_art_elem_ativo: ''
		})
		return naviate('/work_of_art_element')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			ob_art_elem_desc,
			ob_art_elem_ativo,
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		return postItemObArtElement({
			...item,
			id: ob_art_elem_cod
		})
	}
	return (
		<>
			<ContentHeader title={'Novo Elemento para Obra de arte'} />
			<div className='content'>
				<div className="card card-info">
					<div className="card-header">
						<h3 className="card-title">Dados Elemento para Obra de arte</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<form onSubmit={onSubmit}>
							<ElementWorkOfArt />
							<div className="card">
								<div className="card-body">
									<button type="submit" className="btn btn-info mr-3">
                                        Salvar <i className="fa fa-save"></i>
									</button>
									<div className="btn btn-secondary" onClick={() => handleCancel()}>
                                        Cancelar <i className="fa fa-times-circle"></i>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}
export default NewWorkOfArtElement