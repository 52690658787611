import './style.css'

export const MenuItem = ({ title, desc, icon, menuName, activeMenu, miniMenu = false }) => {
    return (
        <>
            {miniMenu ?
                <>
                    <div className="justify-content-center align-items-center col-2">
                        <div className={`d-flex justify-content-center align-items-center ${activeMenu === menuName ? 'MenuItemCircleActiveMini' : 'MenuItemCircleInactiveMini'}`}>
                            <i className={`nav-icon fas fa-${icon} ${activeMenu === menuName ? 'MenuItemIconActive' : 'MenuItemIconInactive'}`}  ></i>
                        </div>
                    </div>
                    <h5 className="MenuItemTitleMini">{title}</h5>
                </>
                : <div className="row mb-2 MenuItemHeight">
                    <div className="d-flex flex-column justify-content-center align-items-end col-6">
                        <h5 className="MenuItemTitle">{title}</h5>
                        <p className="MenuItemDesc">{desc}</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center col-4">
                        <div className={`d-flex justify-content-center align-items-center ${activeMenu === menuName ? 'MenuItemCircleActive' : 'MenuItemCircleInactive'}`}>
                            <i className={`nav-icon fas fa-${icon} ${activeMenu === menuName ? 'MenuItemIconActive' : 'MenuItemIconInactive'}`}  ></i>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}