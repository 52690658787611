import React, { useEffect, useState } from "react";
import { FLAVOR } from "../../../config/flavor";
const ListSAC = ({ data }) => {
    const [items, setItems] = useState(null)
    useEffect(() => {
        if (Array.isArray(data?.data)) {
            const { data: allData } = data
            setItems(allData)
        }
    }, [data])
    if (!Array.isArray(items)) {
        return <div className="text-secondary"></div>
    }
    const callHelper = (CONTACT) => {
        if (!CONTACT) {
            return
        }
        window.open(`https://wa.me/+55${CONTACT}?text=Você pode me ajudar \n com sistema ${FLAVOR}?`, "Chamando Suporte").focus()
    }
    const onChangePhone = (value) => {
        var v = value;
        var r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 5) {
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        }
        return r;
    };
    const RenderButtonType = ({ item }) => {
        if (item.tip_desc === 'Whatsapp') {
            return (
                <div className="btn btn-sm btn-success" onClick={() => callHelper(item.contact)}>
                    {item.tip_desc}{' '}
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                </div>
            )
        }
        if (item.tip_desc === "E-mail") {
            return (
                <div className="btn btn-sm btn-info">
                    {item.tip_desc}{' '}
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </div>
            )
        }
        return (
            <div className="btn btn-sm btn-dark">
                {item.tip_desc}
            </div>
        )
    }
    return (
        <div className="col-12">
            {items.map((item, i) => (
                <div className="row" key={i}>
                    <div className="col-md-4 col-6 mb-3">
                        <span className="text-secondary mr-2">Nome:</span>
                        {item.name}
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                        <span className="text-secondary mr-2">Contato:</span>
                        {item.tip_desc === "Whatsapp" ? onChangePhone(item.contact) : item.contact}
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                        <span className="text-secondary mr-2">Tipo:</span>
                        <RenderButtonType item={item} />
                    </div>
                </div>
            ))}
        </div>
    )
}
export default ListSAC