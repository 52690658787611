import React, { useContext, useEffect, useRef, useState } from "react";
import { formContext } from "../../../context/formContext";
import { handleError, handleWaring, isValid } from "../../../services/isValid";
import { requestContext } from "../../../context/requestContext";
import MonitoringTable from "../../table/constructionPlan/monitoring";
import { randomNumber } from "../../../services/randomId";
import { divideMonthByPercentage } from "../../../services/arrayHandless";

const FormMonitoringProgressConstructionPlan = ({ monitoringProgressItems, setMonitoringProgressItems, handleCloseModal }) => {
    const { state, onChange } = useContext(formContext)
    const { setInfoRequest, postItemMonitoringProgress } = useContext(requestContext)
    const refElement = useRef()
    const {
        pl_ob_data_prev_ini,
        pl_ob_data_prev_fim,
        acob_date_prev,
        acob_perc_prev,
        pl_ob_cod,
        acob_cod,
        acob_date_prev_fim,
    } = state

    const [isNew, setIsNew] = useState(false)

    useEffect(()=>{
        if(pl_ob_cod>0){
            setIsNew(false)
        }else{
            setIsNew(true)
        }
        
    },[pl_ob_cod])
    const handleSave = async () => {
        const payload = {
            id: acob_cod,
            pl_ob_data_prev_ini,
            pl_ob_data_prev_fim,
            acob_date_prev,
            acob_perc_prev,
            acob_pl_ob_cod: pl_ob_cod,
            acob_date_prev_fim,
        }
        if (Number(acob_perc_prev) > 100) {
            return setInfoRequest(handleWaring('O valor % do cronograma é mais de 100%, preencha o valor corretamente'))
        }
        const valid = isValid(payload)
        if (!valid) {
            return setInfoRequest(handleWaring('Você deve preencher todos os campos'))
        }
        postItemMonitoringProgress({
            ...payload,
        })
    }


    const handleAdd = async () => {
        const item =  {
            id: randomNumber(),
            "acob_pl_ob_cod":"-",
            "acob_date_prev": acob_date_prev,
            "acob_date_prev_fim": acob_date_prev_fim,
            "acob_perc_prev": acob_perc_prev,
        }
        setMonitoringProgressItems([...monitoringProgressItems,item])

    }


    const handleDelete = (id) => {
        let tempArr = [...monitoringProgressItems]
        let filteredArr = tempArr.filter(obj => obj.id !== id);
        setMonitoringProgressItems(filteredArr)
    }


    const handleAutomatic = () => {

        if(!pl_ob_data_prev_ini || !pl_ob_data_prev_fim){
            return setInfoRequest(handleWaring('Preencha data de Obra prevista para começar e terminar.'))
        }

        
        const data1 = new Date(pl_ob_data_prev_ini)
        const data2 = new Date(pl_ob_data_prev_fim)

        const sameMonth = data1.getFullYear() === data2.getFullYear() && data1.getMonth() === data2.getMonth();

        if(sameMonth){
            return setInfoRequest(handleWaring('As datas de Obra prevista para começar e terminar não podem ser no mesmo mês.'))
        }

        let ano1 = data1.getFullYear();
        let mes1 = data1.getMonth();
        let ano2 = data2.getFullYear();
        let mes2 = data2.getMonth();

        let diferencaEmMeses = (ano2 - ano1) * 12 + (mes2 - mes1);

        const result = Math.abs(diferencaEmMeses)
        
        const arrayFinal = divideMonthByPercentage(result, data1)
        setMonitoringProgressItems(arrayFinal)
        
    }
    return (
        <div className="row" ref={refElement}>
            <div className="form-group col-md-4 col-sm-4 mb-3">
                <label>Data programado inicial:</label>
                <div className="input-group">
                    <input
                        onChange={onChange}
                        type="date"
                        value={acob_date_prev}
                        name="acob_date_prev"
                        className={'form-control form-control-border'}
                    />
                </div>
            </div>
            <div className="form-group col-md-4 col-sm-4 mb-3">
                <label>Data programado final:</label>
                <div className="input-group">
                    <input
                        onChange={onChange}
                        type="date"
                        value={acob_date_prev_fim}
                        name="acob_date_prev_fim"
                        className={'form-control form-control-border'}
                    />
                </div>
            </div>
            <div className="form-group col-md-4 col-sm-4 mb-3">
                <label>% programada:</label>
                <div className="input-group">
                    <input
                        onChange={onChange}
                        type="number"
                        value={acob_perc_prev}
                        name="acob_perc_prev"
                        className={'form-control form-control-border'}
                        placeholder="0"
                        min={0}
                        max={100}
                    />
                </div>
            </div>
            {
                isNew
                ?<>
                <div className="btn btn-info mb-2" onClick={handleAdd}>
                    <i className="fa fa-plus"></i> Adicionar
                </div>
                <div className="btn btn-secondary ml-2 mb-2" onClick={handleAutomatic}>
                    <i className="fa fa-cog"></i> Gerar automáticamente
                </div>
                {monitoringProgressItems?.length>0&&<MonitoringTable items={monitoringProgressItems} isNew={isNew} handleDelete={handleDelete} />}
                </>
                :<div className="btn btn-info" onClick={handleSave}>
                    <i className="fa fa-save"></i> Salvar
                </div>
            }
            
        </div>
    )
}
export default FormMonitoringProgressConstructionPlan