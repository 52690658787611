import React from "react";
import { Link } from "react-router-dom";
const TableMonitoring = ({ items, pages, fetchItemsMonitoring, widthList }) => {
    if (!Array.isArray(items)) {
        return <div className="col-12 p-3">
            <div className="card-title col-12">
                MONITORAMENTO ANUAL DE PAVIMENTO - PLANILHA PADRÃO PARA LEVANTAMENTO DE CONDIÇÕES DE SUPERFÍCIE (IGG)
            </div>
            <p className="col-12">Não há itens para serem exibidos</p>
        </div>
    }
    const handleLink = (link) => {
        window.open(link).focus()
    }
    const RenderHeaderAobs = ({ avaliacaoes_objetivas_superficie }) => {
        if (!Array.isArray(avaliacaoes_objetivas_superficie)) {
            return <></>
        }
        return (
            avaliacaoes_objetivas_superficie.sort((prev, next) => prev.tip_ind_pav_desc - next.tip_ind_pav_desc).map((item, i) => (
                <th key={i}>
                    {item.tip_ind_pav_desc}
                </th>
            ))
        )
    }
    const RenderLinkFooter = ({ item }) => {
        return (

            <li className="page-item" key={item.label}>
                <Link
                    className={`page-link ${item.active && 'text-light bg-info'
                        }`}
                    to="#"
                    onClick={() => handlePage(item)}
                >
                    {item.label === 'pagination.previous' ? (
                        <i className="fa fa-angle-left"></i>
                    ) : item.label === 'pagination.next' ? (
                        <i className="fa fa-angle-right"></i>
                    ) : (
                        item.label
                    )}
                </Link>
            </li>

        )
    }
    const handlePage = (item) => {
        if (!item) {
            return
        }
        const { label } = item
        const { current_page, last_page } = pages
        window.scrollTo(0, 0)
        if (label === 'pagination.previous') {
            return fetchItemsMonitoring(current_page > 1 `${0}?page=${current_page - 1}`)
        }
        if (label === 'pagination.next') {
            return fetchItemsMonitoring(current_page < last_page && `${0}?page=${current_page + 1}`)
        }
        if (!label) {
            return fetchItemsMonitoring()
        }
        fetchItemsMonitoring(`${0 || 0}?page=${label}`)
    }
    return (
        <>
            <div className="card-title p-2">
                MONITORAMENTO ANUAL DE PAVIMENTO - PLANILHA PADRÃO PARA LEVANTAMENTO DE CONDIÇÕES DE SUPERFÍCIE (IGG)
            </div>
            <div className='table-responsive p-2' style={{
                overflow: 'scroll',
                maxWidth: widthList
            }}>
                <table className="table table-dark table-hover p-0 m-0 table-bordered">
                    <thead>
                        <tr>
                            <th>Rodovia</th>
                            <th>Sentido</th>
                            <th>KM</th>
                            <th>Seção Terr.</th>
                            <th>Tipo Revest.</th>
                            {items.map(({ avaliacaoes_objetivas_superficie }, i) => (
                                i === 0 &&
                                <RenderHeaderAobs avaliacaoes_objetivas_superficie={avaliacaoes_objetivas_superficie} />
                            ))}
                            <th>Longitude e Latitude</th>
                            <th>Observações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(({
                            localizacao_levantamento_indice_pavimento_consolidado: {
                                rod_km,
                                rod_sent_desc,
                                loc_lipc_obs,
                                loc_lipc_km_init,
                                loc_lipc_km_init_mts,
                                mon_pav_lat,
                                mon_pav_lng,
                            },
                            avaliacaoes_objetivas_superficie,
                            sctrr_desc,
                            tip_reves_desc
                        }, i) => (
                            <tr key={i}>
                                <td>{rod_km}</td>
                                <td>{rod_sent_desc}</td>
                                <td>{`${loc_lipc_km_init},${loc_lipc_km_init_mts} mts`}</td>
                                <td>{sctrr_desc}</td>
                                <td>{tip_reves_desc}</td>
                                {Array.isArray(avaliacaoes_objetivas_superficie) && avaliacaoes_objetivas_superficie.sort((prev, next) => prev.tip_ind_pav_desc - next.tip_ind_pav_desc).map(({
                                    ipc_val,
                                    ipc_cod
                                }) => (
                                    <td key={ipc_cod}>
                                        {ipc_val}
                                    </td>
                                ))}
                                <th>
                                    <Link to={'#'} onClick={() => handleLink(`https://www.google.com/maps/search/?api=1&query=${mon_pav_lat},${mon_pav_lng}`)}>
                                        Ver no mapa
                                    </Link>
                                </th>
                                <td>{loc_lipc_obs}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {pages && (
                    <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                            {Array.isArray(pages?.links) &&
                                pages.links.map((item) => (
                                    <RenderLinkFooter item={item} key={item.label} />
                                ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    )
}
export default TableMonitoring