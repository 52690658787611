import React, { useContext } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { useNavigate } from 'react-router-dom'
import FormSector from '../../../components/form/sector'
const NewSector = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemSector } = useContext(requestContext)
	const naviate = useNavigate()
	const {
		set_desc,
		set_disp,
		set_cod
	} = state
	const handleCancel = () => {
		handleForm({
			set_desc: '',
			set_disp: '',
			set_cod: ''
		})
		return naviate('/sector')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			set_desc,
			set_disp,
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		return postItemSector({
			...item,
			id: set_cod
		})
	}
	return (
		<>
			<ContentHeader title={'Novo Setor'} />
			<div className='content'>
				<div className="card card-info">
					<div className="card-header">
						<h3 className="card-title">Dados do setor</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<form onSubmit={onSubmit}>
							<FormSector />
							<div className="card">
								<div className="card-body">
									<button type="submit" className="btn btn-info mr-3">
                                        Salvar <i className="fa fa-save"></i>
									</button>
									<div className="btn btn-secondary" onClick={() => handleCancel()}>
                                        Cancelar <i className="fa fa-times-circle"></i>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}
export default NewSector