import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import SelectComplements from '../selectComplements'
import HighwayExtension from '../highwayExtension'
import SelectCardinalPoints from '../selectCardinalPoints'
import { find, verifyLength } from '../../../services/arrayHandless'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
const Highway = () => {
	const [showSelectInput, setshowSelectInput] = useState(false)
	const [textOption, setTextOption] = useState(null)
	const [valuesFixed, setValuesFixed] = useState(null)
	const [disabled, setDisabled] = useState(false)
	const location = useLocation()
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const {
		state,
		onChange,
		handleForm,
		onChangeSelectInput
	} = useContext(formContext)
	const {
		highway,
		setDealership,
		setHighway,
	} = useContext(requestContext)
	const {
		rod_cod,
		rod_desc,
		rod_km,
		pv_loc_obs,
		conc_cod,
	} = state
	useEffect(() => {
		const loadValuesFixed = () => {
			if (rod_cod && Array.isArray(highway?.data)) {
				const { data: highway_data } = highway
				const { data: findHigway } = find(highway_data, 'rod_cod', rod_cod)
				if (!findHigway) {
					return
				}
				setValuesFixed({
					...findHigway
				})
			}
		}
		loadValuesFixed()
	}, [rod_cod, highway])
	useEffect(() => {
		if (conc_cod) {
			handleForm({
				rod_concessionaria: conc_cod,
			})
		}
	}, [conc_cod])
	useEffect(() => {
		setTextOption(verifyLength(highway))
	}, [highway])

	const onSumitRoad = () => {
		handleForm({
			rod_cod: '',
			conc_cod: '',
			rod_desc: '',
			rod_km: '',
			rod_sent_cod: '',
			rod_km_ini: '',
			rod_km_fim: '',
			rod_km_ini_mt: '',
			rod_km_fim_mt: '',
			rod_extencao: '',
			rod_tipo: '',
			rod_concessionaria: '',
			conc_nome: '',
		})
		setDealership(null)
		setHighway(null)
		setshowSelectInput(!showSelectInput)
	}

	const handleCancel = () => {
		handleForm({
			pv_loc_obs: '',
			comp_cod: '',
			comp_descricao: '',
		})
	}
	const handleCancelSelectHighway = () => {
		handleForm({
			rod_desc: '',
			rod_cod: '',
		})
		setshowSelectInput(!showSelectInput)
	}
	return (
		<div className="row">
			<div className="form-group col-md-12 col-sm-12">
				<label>Rodovia:</label>
				{rod_desc ? (
					<div className="input-group">
						<input className="form-control form-control-border" value={rod_desc} disabled />
						{rod_desc && !disabled && (
							<div className="input-group-prepend">
								<span
									className="input-group-text bg-info"
									style={styleButtonSearch}
									onClick={handleCancelSelectHighway}
								>
									<i className="fa fa-times-circle" aria-hidden="true"></i>
								</span>
							</div>
						)}
					</div>
				) : (
					<div className="input-group">
						<select
							onChange={onChangeSelectInput}
							className={`form-control form-control-border ${!rod_cod && 'is-invalid'
							}`}
						>
							<option>{conc_cod ? textOption : 'Selecionar uma rodovia'}</option>
							{Array.isArray(highway?.data) &&
								highway.data.map((item) => (
									<option key={item.rod_cod} value={JSON.stringify(item)}>
										{item.rod_desc}  - {item.rod_km} (KM ini: {item.rod_km_ini}, {item.rod_km_ini_mt} | KM Final {item.rod_km_fim}, {item.rod_km_fim_mt})
									</option>
								))}
						</select>
						{rod_cod && (
							<div className="input-group-prepend">
								<span
									className="input-group-text bg-info"
									style={styleButtonSearch}
									onClick={onSumitRoad}
								>
									<i className="fa fa-times-circle" aria-hidden="true"></i>
								</span>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="form-group col-md-6 col-12">
				<label>Código Rodovia</label>
				<div className="form-control form-control-border">{rod_km}</div>
			</div>
			<SelectCardinalPoints />
			<HighwayExtension valuesFixed={valuesFixed} />
			{!disabled ? (
				<SelectComplements />
			) : ''}
			<div className="form-group col-12">
				<label>Complementos adicionados:</label>
				<div className="input-group">
					<textarea
						type="text"
						className={`form-control form-control-border ${!pv_loc_obs && 'is-invalid'
						}`}
						onChange={onChange}
						name="pv_loc_obs"
						value={pv_loc_obs}
						maxLength={500}
						placeholder="Descreva brevemente sobre o local onde você está"
						disabled={disabled}
					/>
					<div className="input-group-prepend">
						<span
							className="input-group-text bg-info"
							style={styleButtonSearch}
							onClick={handleCancel}
						>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Highway
