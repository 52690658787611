import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formContext } from '../../../context/formContext'
const ListDeadline = ({ data }) => {
	const { handleForm } = useContext(formContext)
	const [items, setItems] = useState(null)
	useEffect(() => {
		const loadItems = () => {
			if (Array.isArray(data?.data)) {
				const { data: items_data } = data
				setItems(items_data)
			}
		}
		loadItems()
	}, [data])
	const navigate = useNavigate()
	const handleEdit = (item) => {
		handleForm(item)
		navigate('/deadline/new')
	}
	const handleDelete = (item) => {
		const confirm = window.confirm(`Deseja mesmo remover o prazo para o serviço ${item.serv_sub_cat_desc} ?`)
		if (confirm) {
			return
		}
	}
	if (!Array.isArray(items)) {
		return (
			<div className='row'>
				<div className='col-12'>
					Não há itens para serem exibidos
				</div>
			</div>
		)
	}
	return (
		items.map(item => (
			<div className='row p-3' key={item.praz_cod}>
				<div className='col-md-10 col-sm-8'>
					<div className='row'>
						<div className='col-12'>
							<div className='row'>
								<div className='col-md-4 col-sm-12'>
									<span className='text-secondary mr-2'>Atividade: </span>
									{item.serv_sub_cat_desc || ''}
								</div>
								<div className='mb-3 col-md-4 col-sm-12'>
									<span className='text-secondary mr-2'>
										Tipo Atividade:
									</span>
									{item.serv_desc}
								</div>
								<div className='mb-3 col-md-4 col-sm-12'>
									<span className='text-secondary mr-2'>
										Grupo Atividade:
									</span>
									{item.serv_cat_desc}
								</div>
							</div>
						</div>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								Concessionária:
							</span>
							{item.conc_nome}
						</div>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								Lote:
							</span>
							{item.lot_conc_desc}
						</div>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								Prazo em dias:
							</span>
							{item.praz_dias}
						</div>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								Contrato:
							</span>
							{item.cont_ncontrato}
						</div>
						<div className='mb-3 col-md-6 col-sm-12'>
							<span className='text-secondary mr-2'>
								Anexo Inflação:
							</span>
							{item.ane_desc_infla}
						</div>
					</div>
				</div>
				<div className='col-md-2 col-sm-4'>
					<button className='btn btn-info btn-sm mr-2' onClick={() => handleEdit(item)}>
						<i className='fa fa-edit'></i>
					</button>
					<button className='btn btn-secondary btn-sm' onClick={() => handleDelete(item)}>
						<i className='fa fa-trash'></i>
					</button>
				</div>
				<div className='col-12'>
					<div className='row'>
						<div className='mb-3 col-md-6 col-sm-12'>
							<span className='text-secondary mr-2'>
								Penalidade:
							</span>
							{item.cont_pen_infra}
						</div>
						<div className='mb-3 col-md-6 col-sm-12'>
							<span className='text-secondary mr-2'>
								Tip de Penalidade:
							</span>
							{item.cont_pen_tip}
						</div>
					</div>
				</div>
				<hr className='w-100 mb-3' />
			</div >
		))
	)
}
export default ListDeadline