import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import SelectCardinalPoints from '../selectCardinalPoints'
import HighwayExtension from '../highwayExtension'
import { requestContext } from '../../../context/requestContext'
import FormConsolidatedPavementIndex from '../consolidatedPavementIndex'
import { handleError, handleSuccess } from '../../../services/isValid'
import { formateValidatedDate } from '../../../services/dateHandless'
import { find, handleNewItemPavementLIPC } from '../../../services/arrayHandless'
const FormPavementIndicatorsSection = ({ setItems, items }) => {
	const { state, handleForm, onChange } = useContext(formContext)
	const { highway, setInfoRequest, complements } = useContext(requestContext)
	const { rod_cod, rod_sent_cod, rod_sent_desc, rod_km_ini,
		rod_km_fim, rod_km_ini_mt, rod_km_fim_mt,
		loc_lipc_obs, ipc_date, rod_km } = state
	const [valuesFixed, setValuesFixed] = useState(null)
	useEffect(() => {
		const loadValuesFixed = () => {
			if (rod_cod && Array.isArray(highway?.data)) {
				const { data: highway_data } = highway
				const { data: findHigway } = find(highway_data, 'rod_cod', rod_cod)
				if (!findHigway) {
					return
				}
				const { rod_concessionaria } = findHigway
				setValuesFixed({
					...findHigway,

				})
				handleForm({
					loc_lipc_rod_cod: rod_cod,
					loc_lipc_rod_conc: rod_concessionaria,
				})
			}
		}
		loadValuesFixed()
	}, [rod_cod, highway])
	useEffect(() => {
		handleForm({
			ipc_date: formateValidatedDate()
		})
	}, [])
	const handleNewItem = async () => {
		const localizacao_levantamento_indice_pavimento_consolidado = {
			loc_lipc_rod_sent_cod: rod_sent_cod,
			rod_sent_desc,
			loc_lipc_km_init: rod_km_ini,
			loc_lipc_km_init_mts: rod_km_ini_mt,
			loc_lipc_km_fim: rod_km_fim,
			loc_lipc_km_fim_mts: rod_km_fim_mt,
			rod_km,
			loc_lipc_obs,
		}
		const ipcs = []
		const { data: complements_data } = complements
		if (Array.isArray(complements_data)) {
			for (const item of complements_data) {
				const { tip_ind_pav_desc, tip_ind_pav_cod } = item
				if (!tip_ind_pav_desc || !tip_ind_pav_cod) {
					continue
				}
				else {
					const selectElement = document.getElementById(`select_${item.tip_ind_pav_cod}`)
					const ipc_gp_ind_pav_cod_selectElement = selectElement.value
					ipcs.push({
						ipc_val: Number(window.document.getElementById(tip_ind_pav_cod).value),
						ipc_tip_cod: tip_ind_pav_cod,
						ipc_perc: 0,
						ipc_gp_ind_pav_cod: ipc_gp_ind_pav_cod_selectElement,
						ipc_date,
						tip_ind_pav_desc,
					})
					window.document.getElementById(tip_ind_pav_cod).value = ''
				}
			}
		}
		const result = await handleNewItemPavementLIPC({
			state: {
				...localizacao_levantamento_indice_pavimento_consolidado,
				ipcs: [...ipcs]
			},
			complements: complements_data,
		})
		if (!result || result?.isError) {
			return setInfoRequest(handleError('Não foi possível adicionar o seguimento desejado'))
		}
		if (Array.isArray(items)) {
			setItems([...items, { ...result }])
		} else {
			setItems([{ ...result }])
		}
		return setInfoRequest(handleSuccess(`Segmento para a rodovia ${rod_km} adicionado com sucesso`))
	}
	return (
		<>
			<div className='row'>
				<SelectCardinalPoints />
				<HighwayExtension valuesFixed={valuesFixed} />
				<div className="form-group col-12">
					<label>Informações adicionais sobre o trecho:</label>
					<div className="input-group">
						<textarea
							type="text"
							className={`form-control form-control-border ${!loc_lipc_obs && 'is-invalid'
							}`}
							onChange={onChange}
							name="loc_lipc_obs"
							value={loc_lipc_obs}
							maxLength={500}
							placeholder="Descreva brevemente sobre o local onde você está"
						/>
					</div>
				</div>
			</div>
			<FormConsolidatedPavementIndex />
			<button onClick={handleNewItem} type="button" className="btn btn-info btn-block">Adicionar o levantamento desse trecho <i className="fa fa-plus" aria-hidden="true"></i></button>
		</>
	)
}
export default FormPavementIndicatorsSection