import { useContext, useEffect, useRef, useState } from "react"
import { formContext } from "../../../../context/formContext"
import { requestContext } from "../../../../context/requestContext"
import './style.css'

export const Complement = () => {

    const { state, handleForm, onChangeSelectInput } = useContext(formContext)
    const { complements, setInfoRequest, getAllItemComplements } = useContext(requestContext)
    const { comp_descricao, pv_loc_obs  } = state
    const [localComplement, setLocalComplement] = useState('')

    useEffect(() => {
		getAllItemComplements()
	}, [])

    const onChange = ({ target }) => {
		const value = JSON.parse(target?.value)
        if(value?.comp_descricao){
            setLocalComplement(value?.comp_descricao)
        }
	}

    const handleAdd = () => {
        let finalString = `${comp_descricao}, ${localComplement}`
        if(!comp_descricao){
            finalString = localComplement
        }
        handleForm({comp_descricao:finalString})
	}

    const handleClear = () => {
        handleForm({comp_descricao:''})
    }

    return (
        <>
            <label>Complemento</label>
            <div className="input-group">
                <select
                    name="comp_cod"
                    onChange={onChange}
                    className={'form-control' }
                >
                    <option value={null}>Selecionar</option>
                    {Array.isArray(complements?.data) &&
                        complements.data.map((item) => (
                            <option key={item.comp_cod} value={JSON.stringify(item)}>
                                {item.comp_descricao}
                            </option>
                        ))}
                </select>
				<div className="input-group-prepend">
					<span
						className="input-group-text ButtonStyle"
						onClick={handleAdd}
					>
						<i className="fa fa-plus IconStyle" aria-hidden="true"></i>
					</span>
				</div>
			</div>
            <div className="input-group mt-2">
                <input className="form-control" value={comp_descricao} disabled></input>
                <div className="input-group-prepend">
                <span
                    className="input-group-text ButtonStyle"
                    onClick={handleClear}
                >
                    <i className="fa fa-trash IconStyle" aria-hidden="true" ></i>
                </span>
            </div>
            </div>
        </>
    )
}