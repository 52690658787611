import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ContentHeader from '../../components/contentHeader'
import SearchHigway from '../../components/form/searchHigway'
import ListHighway from '../../components/list/highway'
import Spinner from '../../components/spinner'
import { requestContext } from '../../context/requestContext'
import { formContext } from '../../context/formContext'
import FilterHighway from '../../components/form/filters/highway'
const ManagerHighway = () => {
	const { getItemHighway, getWhereItemHighway, highway } = useContext(requestContext)
	const { handleForm, state: { rod_desc, conc_cod, rod_cod } } = useContext(formContext)
	const navigate = useNavigate()

	useEffect(() => {
		getItemHighway(rod_cod, conc_cod)
	}, [conc_cod, rod_cod])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = highway
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return rod_desc ? getWhereItemHighway(current_page > 1 && `${rod_desc}?page=${current_page - 1}`) : getItemHighway(0, current_page > 1 && `${conc_cod}?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return rod_desc ? getWhereItemHighway(current_page < last_page && `${rod_desc}?page=${current_page + 1}`) : getItemHighway(0, current_page < last_page && `${conc_cod}?page=${current_page + 1}`)
		}
		if (!label) {
			return rod_desc ? getWhereItemHighway(rod_desc) : getItemHighway(rod_cod, conc_cod)
		}
		if (rod_desc) {
			return getWhereItemHighway(`${rod_desc}?page=${label}`)
		}
		getItemHighway(rod_cod, `${conc_cod}?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			lim_cod: '',
			lim_gp: '',
			lim_emp: '',
			lim_rod: '',
			lim_conc: '',
			lim_km_ini: '',
			lim_km_fim: '',
			gp_desc: '',
		})
		navigate('/highway/new')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Rodovias'} />
			<div className='content'>
				<div className='card sticky-top'>
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">Rodovidas cadastradas</h3>
						<div className="card-tools col-md-4 col-sm-12">
							<SearchHigway handleNew={handleNew} />
						</div>
					</div>
					<div className='card-body'>
						<FilterHighway />
					</div>
				</div>
				<div className="card">
					<div className='card-body'>
						<Spinner direction={'center'} label='carregando' />
						<ListHighway data={highway} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(highway?.links) && highway.links.map((item) => (
								<li className="page-item" key={item.label}>
									<Link className={`page-link ${item.active && 'text-light bg-info'}`} to="#" onClick={() => handlePage(item)}>
										{item.label === 'pagination.previous' ? <i className="fa fa-angle-left"></i> :
											item.label === 'pagination.next' ? <i className="fa fa-angle-right"></i> :
												item.label
										}
									</Link>
								</li>
							))}
						</ul>
						<ul className="pagination pagination-sm m-0 float-left">
							<li className="page-item">
								<Link className="page-link" to="/highway/new">
									Novo <i className='fa fa-plus mr-1 ml-1'></i>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerHighway