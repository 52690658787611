import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const ListService = ({ data }) => {
    const { handleForm, setShow } = useContext(formContext)
    const { deleteItemService } = useContext(requestContext)
    const navigate = useNavigate()
    const handleClick = (item) => {
        handleForm(item)
        setShow(true)
        navigate('/service/new')
    }
    const handleDelete = (item) => {
        const confirm = window.confirm(`Deseja mesmo excluir o serviço ${item.serv_desc}?`)
        if (confirm) {
            deleteItemService(item.serv_cod)
        }
    }
    return (
        data &&
            data.total > 0 ?
            data.data.map(item => (
                <div key={item.serv_cod}>
                    <div className='d-flex justify-content-between align-center p-3'>
                        <div>{item.serv_desc}</div>
                        <div className='row'>
                            <button className='btn btn-info btn-sm mr-2' onClick={() => handleClick(item)}>
                                <i className='ml-1 fa fa-edit'></i>
                            </button>
                            <button className='btn btn-secondary btn-sm' onClick={() => handleDelete(item)}>
                                <i className='fa fa-trash'></i>
                            </button>
                        </div>
                    </div>
                    <div className='text-secondary ml-2 col-12'>
                        Disponível: {item.serv_disp === 1 ? 'Sim' : 'Não'}
                    </div>
                    <hr />
                </div>
            ))
            : <div className='col-12 p-3 mb-3'>{data?.message}</div>
    )
}
export default ListService