import React, { createContext, useReducer, useState } from 'react'
import { formatPhoneNumber } from '../../services/stringHandless'
export const formContext = createContext()
const initialState = {
	login: '',
	password: '',
	new_password: '',
	password_compare: '',
	old_password: '',
	conc_cod: '',
	conc_cnpj: '',
	conc_nome: '',
	desc: null,
	conc_razaosocial: '',
	conc_cep: '',
	conc_endereco: '',
	conc_numero: '',
	conc_bairro: '',
	conc_cidade: '',
	conc_telefone: '',
	conc_celular: '',
	conc_email: '',
	conc_complemento: '',
	conc_lote: '',
	conc_ativa: '',
	dd: '',
	est_uf: '',
	ane_desc: '',
	em_email: '',
	em_respon: '',
	ane_desc_infla: '',
	ane_clausula_infla: '',
	rod_cod: '',
	rod_desc: '',
	ob_ativ_medida: '',
	ob_ativ_desc: '',
	ob_ativ_preco: '',
	rod_lat: '',
	rod_lng: '',
	rod_concessionaria: '',
	rod_sent_cod: '',
	rod_sent_rodovia: '',
	rod_km: '',
	rod_km_: '',
	rod_km_ini: '',
	rod_km_fim: '',
	rod_km_ini_mt: '',
	rod_km_fim_mt: '',
	rod_extencao: '',
	rod_extencao_: '',
	rod_tipo: '',
	lot_conc_cod: '',
	lot_conc_desc: '',
	lot_lat: '',
	lot_lng: '',
	serv_cod: '',
	serv_valor: '',
	serv_disp: '',
	serv_desc: '',
	serv_cat_cod: '',
	serv_cat_desc: '',
	serv_cat_prazo: '',
	serv_cat_disp: '',
	serv_cat_serv: '',
	serv_cat_medida: '',
	serv_sub_cat_cod: '',
	serv_sub_cat_desc: '',
	serv_sub_cat_disp: '',
	serv_sub_cat_serv_cat: '',
	serv_sub_cat_qtd: '',
	serv_sub_cat_valor: '',
	reload: '',
	pv_servico: '',
	cont_cod: '',
	cont_concessionaria: '',
	cont_ncontrato: '',
	cont_data_ass: '',
	cont_atual: '',
	pv_loc_lat: '',
	pv_loc_lng: '',
	pvs_lat: '',
	pvs_lng: '',
	ped_venda_cod: '',
	ped_venda_date: '',
	ped_venda_date_ini: '',
	ped_venda_date_fim: '',
	ped_venda_status: '',
	ped_venda_concessionaria: '',
	st_cod: '',
	st_desc: '',
	st_cor: '',
	st_cor_valor: '',
	name: '',
	pv_loc_cod: '',
	pv_loc_pedidovenda: '',
	pv_loc_sentido: '',
	pv_loc_km_ini: '',
	pv_loc_km_init_mts: '',
	pv_loc_km_fim: '',
	pv_loc_km_fim_mts: '',
	pv_loc_obs: '',
	rod_sent_desc: '',
	pvs_concessionaria: '',
	pvs_valor: '',
	pvs_qtd: '',
	pvs_servico: '',
	pvs_respo: '',
	pvs_servico_cat: '',
	pvs_servico_sub_cat: '',
	pvs_obs: '',
	pvs_fisc: '',
	pvs_prazo_ini: '',
	pvs_prazo_fim: '',
	pvs_data: '',
	pvs_pedidovenda: '',
	pvs_loc: '',
	pv_loc_address: '',
	fisc_responsavel: '',
	fisc_ped_venda: '',
	fisc_lat: '',
	fisc_lng: '',
	fisc_photo: '',
	fisc_obs: '',
	fisc_current_obs: '',
	praz_dias: '',
	praz_ane_inf: '',
	praz_lote: '',
	praz_serv_sub_cat: '',
	os_obs_prazo: '',
	bases64: '',
	tip_ob_cod: '',
	tip_ob_desc: '',
	tip_ob_sub: '',
	tip_ob_sub_cod: '',
	tip_ob_sub_desc: '',
	tip_ob_sub_tip_ob: '',
	tip_sub_ob: '',
	pl_ob_cod: '',
	pl_ob_cod_item: '',
	pl_ob_desc_object: '',
	current_prog_serv_qtd_acul: '',
	pl_ob_data_program_ini: '',
	pl_ob_data_program_fim: '',
	pl_ob_data_exec_ini: '',
	pl_ob_data_exec_fim: '',
	pl_ob_data_ana_ini: '',
	pl_ob_data_ana_fim: '',
	pl_ob_data_prev_ini: '',
	pl_ob_data_prev_fim: '',
	prog_ob_por_cen_progress: '',
	prog_serv_percen_progress: '',
	prog_serv_percen_acul: '',
	pl_ob_valor: '',
	pl_ob_tipo: '',
	ane_pl_ob_cod: '',
	ane_pl_ob_obra_cod: '',
	ane_pl_ob_path: '',
	ane_pl_ob_tipo: '',
	ane_pl_ob_obs: '',
	ane_pl_ob_pvs_cod: '',
	ob_serv_med_desc: '',
	usu_set: '',
	usu_ativo: '',
	id: '',
	usu_emp: '',
	usu_email: '',
	serv_cat_prazo_from: '',
	serv_cat_prazo_to: '',
	type_date: '',
	rod_km_ini_from: '',
	rod_km_ini_to: '',
	emp_cod: '',
	emp_nome: '',
	emp_cnpj: '',
	emp_ativo: '',
	st_ativo: '',
	st_cor_cod: '',
	ped_venda_sifs: '',
	vers_cod: '',
	vers_number: '',
	vers_ambiente: '',
	vers_url: '',
	hel_cod: '',
	help_desc: '',
	hel_usu_request: '',
	hel_usu_response: '',
	help_desc_response: '',
	lim_cod: '',
	lim_gp: '',
	lim_emp: '',
	lim_rod: '',
	lim_conc: '',
	lim_km_ini: '',
	lim_km_fim: '',
	lim_km_ini_mts: '',
	lim_km_fim_mts: '',
	gp_cod: '',
	gp_desc: '',
	lim_rod_usu_cod: '',
	usu_gp: '',
	set_nivel: '',
	ob_art_cod: '',
	ob_art_desc: '',
	ob_art_item_cod: '',
	ob_art_tipo: '',
	ob_art_class: '',
	ob_art_elem: '',
	ob_art_loc_km: '',
	ob_art_loc_mts: '',
	ob_art_rodovia: '',
	ob_art_lote: '',
	ob_art_sent: '',
	ob_art_ativo: '',
	tip_serv_sub_cat_cod: '',
	tip_serv_sub_cat_desc: '',
	tip_serv_sub_cat_disp: '',
	ob_art_tipo_cod: '',
	ob_art_tipo_desc: '',
	ob_art_tipo_ativo: '',
	ob_art_class_cod: '',
	ob_art_class_desc: '',
	ob_art_class_ativo: '',
	tut_cod: '',
	tut_path: '',
	tut_titulo: '',
	tut_descricao: '',
	tut_tipo_arquivo: '',
	tut_disp: '',
	tut_set_nivel: '',
	tut_criador: '',
	prog_cod: '',
	config_para_cod: '',
	config_para_desc: '',
	config_para_value: '',
	config_para_settings: '',
	sett_cod: '',
	sett_desc: '',
	comp_cod: '',
	comp_descricao: '',
	comp_ativo: '',
	tip_ped_venda_desc: '',
	tip_ped_venda_cod: '',
	sinc_st_cod: '',
	sisf_sinc_id: '',
	sinc_st_desc: '',
	tut_base_64: '',
	ob_art_elem_cod: '',
	ob_art_elem_oae: '',
	ob_art_elem_desc: '',
	ob_art_elem_ativo: '',
	mode_sisf: false,
	selected_concs: '',
	sisf_sinc_int_amp_id: '',
	pmo_cod: '',
	pmo_path: '',
	pmo_tip_pl_ob: '',
	pmo_usu_cod: '',
	serv_sub_cat_subitem_der: '',
	prog_serv_qtd_acul: '',
	tip_ind_pav_cod: '',
	tip_ind_pav_desc: '',
	gp_ind_pav_cod: '',
	gp_ind_pav_desc: '',
	ipc_lipc_cod: '',
	ipc_loc_lipc_cod: '',
	ipc_val: '',
	ipc_tip_cod: '',
	ipc_perc: '',
	ipc_gp_ind_pav_cod: '',
	ipc_date: '',
	navp_tip_cod: '',
	navp_val: '',
	navp_perc: '',
	show_info_construction_plan: '',
	show_work_schedule: '',
	show_progress_of_Disciplinas: '',
	show_progress_all_service: '',
	show_history_check_inspection: '',
	show_chart_resume_progress: false,
	show_photos_in_report: false,
	show_attachments: false,
	apos_data_from: '',
	apos_data_to: '',
	acob_date_prev: '',
	acob_perc_prev: 0,
	sum_acob_perc_prev: 0,
	acob_date_prev_fim: '',
	lipc_cod: '',
	lipc_conc_cod: '',
	ob_serv_med_cod: '',
	conc_color: '',
	serv_tip_obra: '',
}
const reducer = (state, { field, value }) => {
	return {
		...state,
		[field]: value,
	}
}
const FormContext = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [show, setShow] = useState(true)
	const [roads, setRoads] = useState(null)
	const [bases64, setBases64] = useState(null)
	const [showCamera, setShowCamera] = useState(false)
	const onChange = ({ target }) => {
		const { name } = target
		dispatch({ field: name, value: target.value })
	}
	const onChangeNumber = ({ target }) => {
		const { name, value } = target
		dispatch({ field: name, value: Number(value.replace('^[0-9]*$', '')) })
	}
	const onBlurValidMinMax = ({ target }) => {
		const { name, min, max, value } = target
		if (Number(value) < Number(min)) {
			dispatch({ field: name, value: '' })
		}
		if (Number(value) > Number(max)) {
			dispatch({ field: name, value: '' })
		}
	}
	const handleForm = (field) => {
		Object.keys(field).map((key) => {
			return dispatch({ field: key, value: field[key] })
		})
	}
	const onChangeSelectInput = ({ target }) => {
		const value = JSON.parse(target.value)
		handleForm(value)
	}
	const onChangePhone = ({ target }) => {
		const { value, name } = target
		const r = formatPhoneNumber(value)
		return dispatch({ field: name, value: r })
	}
	const CEP = ({ target }) => {
		return dispatch({
			field: target.name,
			value: target.value.replace(/^(\d{5})(\d)/, '$1-$2'),
		})
	}
	const CPF = (v) => {
		v = v.replace(/\D/g, '')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
		if (v.length > 14) {
			return CPF(v)
		} else {
			return v
		}
	}
	const CNPJ = (v) => {
		v = v.replace(/\D/g, '')
		v = v.replace(/^(\d{2})(\d)/, '$1.$2')
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
		v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
		v = v.replace(/(\d{4})(\d)/, '$1-$2')
		if (v.length > 18) {
			return CNPJ(v.substring(0, 18))
		} else {
			if (v.length < 15) {
				return CPF(v)
			} else {
				return v
			}
		}
	}
	const validaDateExtension = (valuesFixed) => {
		if (!state.rod_cod) {
			return
		}
		let validate_km_ini = ''
		let validate_km_ini_mt = ''
		let validate_km_fim = ''
		let validate_km_fim_mt = ''
		if (state.rod_km_ini < valuesFixed.rod_km_ini) {
			validate_km_ini = '+Rodovia KM inicial menor que Rodovia KM final.'
			dispatch({ field: 'rod_km_ini', value: '' })
		} else if (state.rod_km_ini > valuesFixed.rod_km_fim) {
			validate_km_ini = '+Rodovia KM inicial maior que o Rodovia KM final.'
			dispatch({ field: 'rod_km_ini', value: '' })
		} else {
			validate_km_ini = ''
		}
		if (validate_km_ini === '') {
			if (state.rod_km_ini === valuesFixed.rod_km_ini) {
				if (state.rod_km_ini_mt < valuesFixed.rod_km_ini_mt) {
					validate_km_ini_mt =
						'+Rodovia mts inicial menor que Rodovia mts inicial.'
					dispatch({ field: 'rod_km_ini_mt', value: '' })
				} else validate_km_ini_mt = ''
			} else validate_km_ini_mt = ''
		} else {
			validate_km_ini_mt =
				'Você não preencheu +Rodovia KM inicial corretamente.'
			dispatch({ field: 'rod_km_ini', value: '' })
		}
		if (state.rod_km_fim > valuesFixed.rod_km_fim) {
			validate_km_fim = '+Rodovia KM final maior que Rodovia KM final.'
			dispatch({ field: 'rod_km_fim', value: '' })
		}
		if (state.rod_km_fim < state.rod_km_ini) {
			validate_km_fim = '+Rodovia KM final que o +Rodovia KM inicial.'
			dispatch({ field: 'rod_km_fim', value: '' })
		} else {
			validate_km_fim = ''
		}
		if (validate_km_fim === '') {
			if (state.rod_km_fim === valuesFixed.rod_km_fim) {
				if (state.rod_km_fim_mt > valuesFixed.rod_km_fim_mt) {
					validate_km_fim_mt =
						'+Rodovia mts final maior que Rodovia mts final.'
					dispatch({ field: 'rod_km_fim_mt', value: '' })
				} else {
					validate_km_fim_mt = ''
				}
			} else validate_km_fim_mt = ''
		} else {
			validate_km_fim_mt =
				'Você não preencheu corretamente +Rodovia mts final.'
			dispatch({ field: 'rod_km_fim_mt', value: '' })
		}
		if (
			validate_km_ini ||
			validate_km_ini_mt ||
			validate_km_fim ||
			validate_km_fim_mt
		) {
			return `${validate_km_ini}${validate_km_ini_mt}${validate_km_fim}${validate_km_fim_mt}`
		}
	}

	return (
		<formContext.Provider
			value={{
				state,
				dispatch,
				onChange,
				onChangeNumber,
				onBlurValidMinMax,
				onChangeSelectInput,
				handleForm,
				roads,
				setRoads,
				setShow,
				show,
				onChangePhone,
				CEP,
				CNPJ,
				bases64,
				setBases64,
				showCamera,
				setShowCamera,
				validaDateExtension,
			}}
		>
			{children}
		</formContext.Provider>
	)
}
export default FormContext
