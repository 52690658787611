import React, { useContext, useEffect, useRef, useState } from 'react'
import ContentHeader from '../../components/contentHeader'
import Calender from '../../components/calendar'
import { filterAndRemove, find } from '../../services/arrayHandless'
import { formateValidatedDate, infoCalendar } from '../../services/dateHandless'
import { requestContext } from '../../context/requestContext'
import FormTimeLine from '../../components/form/timeline'
import { handleWaring } from '../../services/isValid'
import { formContext } from '../../context/formContext'
import { scrollToElement } from '../../services/scroll'
import RendermodalEvent from '../../components/modal/modalTimeLineOversight'
import ListTimeLine from '../../components/list/listTimeline'
import groupBy from '../../services/groupBy'
import FormoversightByDate from '../../components/form/filters/oversightByDate'
const ManagerTimeLine = () => {
	const [selectedDays, setSelectedDays] = useState(null)
	const [currentMonth, setCurrentMonth] = useState(null)
	const [showCreateEvent, setShowCreateEvent] = useState(false)
	const [titleModal, setTitleModal] = useState(false)
	const [show, setShow] = useState(false)
	const [items, setItems] = useState(null)
	const {
		getAllItemDealershipLot,
		getAllItemHighway,
		setInfoRequest,
		postItemTimelineOversight,
		getAllTimeLineOversight,
		oversight
	} = useContext(requestContext)
	const { handleForm, state: { fisc_obs, pvs_cod, ped_venda_cod, conc_cod, ped_venda_status, serv_cat_prazo_from, serv_cat_prazo_to } } = useContext(formContext)
	const [infoDays, setInfoDays] = useState(null)
	const [itemsEvent, setItemsEvents] = useState(null)
	const cardFormTimeline = useRef()
	useEffect(() => {
		const loaditemsEvent = () => {
			setShow(true)
		}
		if (Array.isArray(itemsEvent)) {
			loaditemsEvent()
		}
	}, [itemsEvent])
	useEffect(() => {
		const controlEnabledViewEvent = () => {
			if (selectedDays?.length >= 2) {
				setShow(false)
			}
		}
		controlEnabledViewEvent()
	}, [selectedDays])
	useEffect(() => {
		getAllTimeLineOversight({
			id: conc_cod,
			ped_venda_date_from: '',
			ped_venda_date_to: '',
			type_date: '',
		})
		getAllItemDealershipLot()
		getAllItemHighway()
		onClearValues()
	}, [])
	useEffect(() => {
		const loadInfoDays = () => {
			const { data } = oversight
			if (!Array.isArray(data)) {
				return
			}
			const newItems = data.map(item => {
				const { pedido_venda_servico, ...rest } = item
				let day_init = null
				let day_fim = null
				let month = null
				let title_event = null
				if (pedido_venda_servico?.length) {
					for (const pv_servico of pedido_venda_servico) {
						const { pvs_prazo_fim, pvs_prazo_ini, pvs_obs } = pv_servico
						day_init = new Date(pvs_prazo_ini).getDate()
						day_fim = new Date(pvs_prazo_fim).getDate()
						month = new Date(pvs_prazo_fim).getMonth()
						title_event = pvs_obs
					}
				}
				return {
					...rest,
					pedido_venda_servico,
					day_init,
					day_fim,
					month,
					title_event,
					color_event: rest.pedido_venda?.st_cor_valor,
				}
			})
			setInfoDays(newItems)
		}
		if (oversight) {
			loadInfoDays()
		}
	}, [oversight])
	useEffect(() => {
		const scrollInCardFormTimeline = () => {
			scrollToElement(cardFormTimeline.current)
		}
		if (cardFormTimeline && showCreateEvent) {
			scrollInCardFormTimeline()
		}
	}, [cardFormTimeline, showCreateEvent])
	useEffect(() => {
		const scrollInCardFormTimeline = () => {
			scrollToElement(cardFormTimeline.current)
		}
		if (pvs_cod) {
			scrollInCardFormTimeline()
		}
	}, [pvs_cod])
	const onClearValues = () => {
		window.scrollTo(0, 0)
		handleForm({
			fisc_obs: '',
			rod_desc: '',
			conc_cod: '',
			rod_concessionaria: '',
			conc_nome: '',
			rod_sent_desc: '',
			rod_sent_cod: '',
			rod_km_ini: '',
			rod_km_ini_mt: '',
			rod_km_fim: '',
			rod_km_fim_mt: '',
			serv_cod: '',
			serv_cat_cod: '',
			serv_sub_cat_cod: '',
			serv_sub_cat_desc: '',
			pv_loc_obs: '',
			pvs_servico: '',
			pvs_servico_cat: '',
			pvs_servico_sub_cat: '',
			pvs_obs: '',
			pvs_qtd: '',
			ped_venda_date_from: '',
			ped_venda_date_to: '',
		})
		setShowCreateEvent(false)
		setItems(null)
		setSelectedDays(null)
	}
	const onClick = (item) => {
		setTitleModal(`Cronograma para ${item.weekday} dia ${item.day} de ${item.nameMonth}`)
		if (item?.infoday?.length >= 1) {
			if (item.infoday === itemsEvent) {
				setItemsEvents(null)
			} else {
				setItemsEvents(item.infoday)
			}
		}
		handleAdItems(item)
	}
	const handleAdItems = (item) => {
		const items = []
		const {
			currentYear,
			currentHours,
			currenMinutes,
			currentSeconds,
		} = infoCalendar(currentMonth)
		if (Array.isArray(selectedDays)) {
			const { data } = find(selectedDays, 'key', item.key)
			if (data) {
				const findAndRemoveItem = filterAndRemove(selectedDays, 'key', item.key)
				const length = findAndRemoveItem.data.length
				const intialDate = findAndRemoveItem.data[0].day
				const finalDate = findAndRemoveItem.data[length - 1].day
				for (let i = intialDate; i <= finalDate; i++) {
					const date = new Date(currentYear, currentMonth, i, currentHours, currenMinutes, currentSeconds)
					const weekday = date.toLocaleDateString('pt-BR', { weekday: 'long' })
					items.push({
						day: i,
						fullDate: date,
						weekday: String(weekday).replace('-feira', ''),
						key: i,
						isToday: false,
						info: null
					})
				}
				return setSelectedDays(items)
			}
			const intialDate = selectedDays[0].day
			const finalDate = item.day
			for (let i = intialDate; i <= finalDate; i++) {
				const date = new Date(currentYear, currentMonth, i, currentHours, currenMinutes, currentSeconds)
				const weekday = date.toLocaleDateString('pt-BR', { weekday: 'long' })
				items.push({
					day: i,
					fullDate: date,
					weekday: String(weekday).replace('-feira', ''),
					key: i,
					isToday: false,
					info: null
				})
			}
			return setSelectedDays([
				...items,
			])
		}
		return setSelectedDays([{ ...item }])
	}
	const handleSubmit = () => {
		window.scrollTo(0, 0)
		if (!Array.isArray(items)) {
			return setInfoRequest(handleWaring())
		}
		const groupItems = groupBy(items, 'rod_cod')
		const pv_servico = []
		Object.keys(groupItems).forEach(item => {
			const findService = items.filter(element => element.rod_cod === parseInt(item))
			if (findService.length) {
				const { rod_cod: rod_cod_, conc_cod: conc_cod_ } = findService[0]
				const servicos = findService.map(serv => {
					const { pvs_cod, ...rest } = serv
					return {
						...rest,
						pvs_cod: pvs_cod || null
					}
				})
				pv_servico.push({
					rod_cod: rod_cod_,
					conc_cod: conc_cod_,
					ped_venda_cod: ped_venda_cod || null,
					pv_servico: servicos
				})
			}
		})
		return postItemTimelineOversight({
			id: ped_venda_cod,
			fisc_obs,
			pv_servico,
		}, () => onClearValues())
	}
	const handleCancel = () => {
		if (ped_venda_cod) {
			return onClearValues()
		}
		const confirm = window.confirm('Você deseja mesmo cancelar o preechimento desse cronograma?')
		if (!confirm) return
		onClearValues()
	}
	const handleClose = () => {
		setShow(false)
		setItemsEvents(null)
		setSelectedDays(null)
	}
	const handleEditItem = item => {
		try {
			if (!Array.isArray(itemsEvent)) {
				return setInfoRequest(handleWaring('Não há itens para editar'))
			}
			const filterItemsToEdit = itemsEvent.filter(item_ => item_.pedido_venda.ped_venda_concessionaria === item.pedido_venda.ped_venda_concessionaria)
			if (!filterItemsToEdit.length) {

				return setInfoRequest(handleWaring('Não há itens para editar'))
			}
			if (item?.pedido_venda && item?.pedido_venda_local) {
				const { pedido_venda, pedido_venda_servico } = item
				const { ped_venda_rodovia, ped_venda_concessionaria } = pedido_venda
				if (pedido_venda_servico?.length >= 1) {
					const length = pedido_venda_servico?.length
					const firstPedidovendaServico = pedido_venda_servico[length - 1]
					const { pvs_prazo_fim, pvs_prazo_ini } = firstPedidovendaServico
					handleForm({
						ped_venda_date_ini: formateValidatedDate(pvs_prazo_ini),
						ped_venda_date_fim: formateValidatedDate(pvs_prazo_fim),
					})
				}
				handleForm({
					...pedido_venda,
					conc_cod: ped_venda_concessionaria,
					rod_concessionaria: ped_venda_concessionaria,
					rod_cod: ped_venda_rodovia,
				})
			}
			const editItems = []
			for (const event of filterItemsToEdit) {
				const { pedido_venda, pedido_venda_servico, pedido_venda_local } = event
				for (const pv_servico of pedido_venda_servico) {
					const {
						pvs_loc, pvs_cod, pvs_qtd,
						pvs_servico: serv_cod, pvs_prazo_fim,
						pvs_prazo_ini, pvs_servico: serv_cat_cod,
						pvs_servico_cat: serv_sub_cat_cod,
						pvs_obs: serv_sub_cat_desc, serv_desc,
						serv_cat_desc, pv_servico_foto } = pv_servico
					const { conc_nome, ped_venda_concessionaria: conc_cod, ped_venda_rodovia: rod_cod, rod_desc } = pedido_venda
					const month = new Date(pvs_prazo_fim).toLocaleString('default', { month: 'long' })
					const { data: findPedidoVendaLocal } = find(pedido_venda_local, 'pv_loc_cod', pvs_loc)
					editItems.push({
						conc_nome,
						conc_cod,
						rod_cod,
						month,
						rod_desc,
						pvs_cod,
						pvs_loc,
						pvs_servico: serv_cod,
						pvs_servico_cat: serv_cat_cod,
						pvs_servico_sub_cat: serv_sub_cat_cod,
						pvs_obs: serv_sub_cat_desc,
						pvs_prazo_ini: `${pvs_prazo_ini}`,
						pvs_prazo_fim: `${pvs_prazo_fim}`,
						pvs_lat: 0,
						pvs_lng: 0,
						pvs_qtd: pvs_qtd || 0,
						serv_desc,
						serv_cat_desc,
						pedido_venda_local: { ...findPedidoVendaLocal, rod_desc },
						pv_servico_foto: pv_servico_foto,
					})
				}
			}
			if (editItems.length === 0) {
				return setInfoRequest(handleWaring('Não há itens para editar'))
			}
			setShow(false)
			setItemsEvents(null)
			setShowCreateEvent(true)
			setItems(editItems)
		} catch (error) {
			return setInfoRequest(handleWaring(String(error)))
		}
	}
	const handleClar = () => {
		onClearValues()
		handleForm({
			conc_cod: '',
			st_cod: '',
			serv_cat_prazo_from: '',
			serv_cat_prazo_to: '',
			conc_nome: '',
		})
		getAllTimeLineOversight({
			id: 0,
			ped_venda_status: 0,
			ped_venda_date_from: '',
			ped_venda_date_to: '',
		})
	}
	const handleAplickFilter = () => {
		getAllTimeLineOversight({
			id: conc_cod,
			ped_venda_status: ped_venda_status,
			ped_venda_date_from: serv_cat_prazo_from,
			ped_venda_date_to: serv_cat_prazo_to,
		})
	}
	return (
		<>
			<ContentHeader title={'Acompanhe a programação de atividades'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
                            Programação de atividades
						</h3>
					</div>
					<div className="card-body">
						<FormoversightByDate min max />
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-md-9 col-sm-8">
								<button className="btn btn-info mr-2" onClick={handleAplickFilter}>
									<i className="fa fa-filter" aria-hidden="true"></i>
                                    Aplicar filtro</button>
								<button className="btn btn-dark" onClick={handleClar}>
									<i className="fa fa-eraser" aria-hidden="true"></i>
                                    Limpar filtro
								</button>
							</div>
							<div className="col-md-3 col-sm-4">
								{oversight?.total} cronograma(s) encontrado(s)
							</div>
						</div>
					</div>
				</div>
				<div className="card">
					<Calender
						onRefresh={getAllTimeLineOversight}
						setShowCreateEvent={setShowCreateEvent}
						showCreateEvent={showCreateEvent}
						selectedDays={selectedDays}
						setSelectedDays={setSelectedDays}
						onClick={onClick}
						setCurrentMonth_={setCurrentMonth}
						data={infoDays}
						setItemsEvents={setItemsEvents}
					/>
				</div>
				{showCreateEvent && (
					<div className="card-info mt-3">
						<div className="card-header">
							<h3 className="card-title">Informações sobre o cronograma</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body" ref={cardFormTimeline}>
							<FormTimeLine items={items} setItems={setItems} />
							<ListTimeLine items={items} setItems={setItems} editItem={pvs_cod} />
						</div>
						<div className="card-body">
							<div className="row">
								<button className="btn btn-info mr-2" onClick={handleSubmit}>
									<i className="fa fa-save"></i> Salvar
								</button>
								<button className="btn btn-secondary" onClick={handleCancel}>
									<i className="fa fa-times-circle"></i> Cancelar
								</button>
							</div>
						</div>
					</div>
				)}
				{show && (
					<RendermodalEvent title={titleModal}
						handleAddEvent={() => setShow(false)}
						handleClose={handleClose} handleEditItem={handleEditItem} itemsEvent={itemsEvent} show={show} />
				)}
			</div >
		</>
	)
}
export default ManagerTimeLine