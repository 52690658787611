import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../../../context/requestContext'
import Dealership from '../../dealership'
import { formContext } from '../../../../context/formContext'
import SelectHighway from '../../selectHighway'
import SelectGroups from '../../selectGroups'
const FormFilterHighwayLimit = () => {
	const { getAllItemDealership, getAllItemHighway, getItemHighwayLimit } = useContext(requestContext)
	const { state: { gp_cod, conc_cod, rod_cod }, handleForm } = useContext(formContext)
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		}
	}, [conc_cod])
	const handleClearFilters = () => {
		handleForm({
			gp_cod: 0,
			conc_cod: 0,
			rod_cod: 0,
			gp_desc: '',
			conc_nome: '',
			rod_desc: '',
		})
		getItemHighwayLimit({
			gp_cod: 0,
			conc_cod: 0,
			rod_cod: 0
		})
	}
	const handleClick = () => {
		getItemHighwayLimit({
			id: gp_cod,
			conc_cod,
			rod_cod,
		})
	}
	return (
		<div className="row">
			<SelectGroups col={'col-12'} />
			<div className="col-md-6 col-sm-12">
				<Dealership />
			</div>
			<div className="col-md-6 col-sm-12  mb-3">
				<SelectHighway />
			</div>
			<div className='col-12'>
				<button className='btn btn-info mr-2' onClick={handleClick}>
                    Aplicar filtro <i className="fa fa-filter"></i>
				</button>
				<button className='btn btn-dark' onClick={handleClearFilters}>
                    Limpar filtros <i className="fa fa-eraser"></i>
				</button>
			</div>
		</div>
	)
}
export default FormFilterHighwayLimit