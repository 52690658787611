import React, { useContext, useEffect, useState } from "react";
import { requestContext } from "../../../context/requestContext";
import { formContext } from "../../../context/formContext";
import { useNavigate } from "react-router-dom";
const ListCompany = ({ data }) => {
    const [items, setItems] = useState(null)
    const { deleteItemCompany } = useContext(requestContext)
    const { handleForm } = useContext(formContext)
    const navigate = useNavigate();
    useEffect(() => {
        if (data?.total > 0) {
            setItems(data.data)
        }
    }, [data])
    const handleEdit = (item) => {
        handleForm(item)
        navigate('/company/new')
    }
    const handleDelete = (item) => {
        const { emp_nome, emp_cod } = item
        const confirm = window.confirm(`Você deseja mesmo excluir a empresa ${emp_nome} ?`)
        if (confirm) {
            deleteItemCompany(emp_cod)
        }
    }
    if (!Array.isArray(items)) {
        return <></>
    }
    return (
        items.map(item => (
            <div className="row p-3">
                <div className="col-md-12">
                    <span className="text-secondary mr-2">Empresa:</span>
                    {item.emp_nome}
                </div>
                <div className="col-md-4 col-sm-12">
                    <span className="text-secondary mr-2">CNPJ:</span>
                    {item.emp_cnpj}
                </div>
                <div className="col-md-6 col-sm-10">
                    <span className="text-secondary mr-2">Situação:</span>
                    {item.emp_ativo === 1 ? 'Ativo' : 'Inativo'}
                </div>
                <div className="col-md-2 col-sm-2">
                    <button
                        className="btn btn-info btn-sm mr-2"
                        onClick={() => handleEdit(item)}
                    >
                        <i className="ml-1 fa fa-edit"></i>
                    </button>
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => handleDelete(item)}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </div>
        ))
    )
}
export default ListCompany