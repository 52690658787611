import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { authContext } from '../../../context/authContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring } from '../../../services/isValid'
import ContentHeader from '../../../components/contentHeader'
import User from '../../../components/form/user'
import { useNavigate } from 'react-router-dom'
const ChangePass = () => {
	const { user, userProfile } = useContext(authContext)
	const [thisEmail, setThisEmail] = useState(null)
	useEffect(() => {
		const loadProfile = () => {
			if (!user) {
				return userProfile()
			}
			handleForm({
				...user
			})
			setThisEmail(user.usu_email)
		}
		loadProfile()
	}, [user])
	const { handleForm, state } = useContext(formContext)
	const { setInfoRequest, updatePassword } = useContext(requestContext)
	const {
		new_password,
		password_compare,
	} = state
	const navigate = useNavigate()

	const handleSubmit = () => {

		window.scrollTo(0, 0)
		if (!new_password || !password_compare) {
			return setInfoRequest(handleWaring())
		}
		if (new_password !== password_compare) {
			return setInfoRequest(handleWaring('Sua nova senha não confere'))
		}
		const item = {
			email: thisEmail,
			new_password,
			password_compare,
		}
		updatePassword(item)
		if (user?.set_nivel <= 1) {
			return navigate('/users')
		}
		navigate('/')

	}
	const handleCancel = () => {

		if (user?.set_nivel <= 1) {
			return navigate('/users')
		}
		navigate('/')
	}
	return (
		<>
			<ContentHeader title={'Alterar minha senha'} />
			<div className="content">
				<div className='card'>
					<div className='card-body'>
						<User />
					</div>
				</div>
				<div className='card'>
					<div className='card-body'>
						<div className='btn btn-info mr-2 ' onClick={handleSubmit}>
							<i className='fa fa-save mr-2'></i>
							Salvar
						</div>
						<div className='btn btn-secondary' onClick={handleCancel}>
							<i className='fa fa-times-circle mr-2'></i>
							Cancelar
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default ChangePass