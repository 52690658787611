/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import { formContext } from '../../context/formContext'
import Spinner from '../../components/spinner'
import SearchTypeOversight from '../../components/form/searchTypeOversight'
import ListTypeOversight from '../../components/list/typeOversight'
const ManagerTypeOversight = () => {
	const { getItemTypeOversight, getWhereItemTypeOversight, check_inspection, setCheckInspection } =
    useContext(requestContext)
	const { handleForm, state: { tip_ped_venda_desc } } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		getItemTypeOversight()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = check_inspection
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return tip_ped_venda_desc ? getWhereItemTypeOversight(current_page > 1 && `${tip_ped_venda_desc}?page=${current_page - 1}`) : getItemTypeOversight(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return tip_ped_venda_desc ? getWhereItemTypeOversight(current_page < last_page && `${tip_ped_venda_desc}?page=${current_page + 1}`) : getItemTypeOversight(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return tip_ped_venda_desc ? getWhereItemTypeOversight(tip_ped_venda_desc) : getItemTypeOversight()
		}
		if (tip_ped_venda_desc) {
			return getWhereItemTypeOversight(`${tip_ped_venda_desc}?page=${label}`)
		}
		getItemTypeOversight(`0?page=${label}`)
	}
	const handleNew = () => {
		setCheckInspection(null)
		handleForm({
			tip_ped_venda_desc: '',
			tip_ped_venda_cod: '',
		})
		navigate('/type_sales_order/new')
	}
	return (
		<>
			<ContentHeader title={'Tipos de NC\'s'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
              Tipos de NC's cadastradas
						</h3>
						<div className="card-tools col-md-4 col-sm-12">
							<SearchTypeOversight handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					{Array.isArray(check_inspection?.data) && (
						<ListTypeOversight data={check_inspection} />
					)}
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(check_inspection?.links) &&
                check_inspection.links.map((item, i) => (
                	<li className="page-item" key={item.label}>
                		<Link
                			className={`page-link ${item.active && 'text-light bg-info'
                			}`}
                			to="#"
                			onClick={() => handlePage(item)}
                		>
                			{item.label === 'pagination.previous' ? (
                				<i className="fa fa-angle-left"></i>
                			) : item.label === 'pagination.next' ? (
                				<i className="fa fa-angle-right"></i>
                			) : (
                				item.label
                			)}
                		</Link>
                	</li>
                ))}
						</ul>
						<ul className="pagination pagination-sm m-0 float-left">
							<li className="page-item">
								<div className="page-link" onClick={handleNew}>
                  Novo <i className="fa fa-plus mr-1 ml-1"></i>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerTypeOversight
