import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { useNavigate } from 'react-router-dom'
const ListHighwayLimit = ({ data, justList, selectUser }) => {
	const [items, setItems] = useState(null)
	const { deleteItemHighwayLimit } = useContext(requestContext)
	const { handleForm } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		if (data?.total > 0) {
			setItems(data.data)
		}
	}, [data])
	if (!Array.isArray(items)) {
		return <></>
	}
	const handleEdit = (item) => {
		handleForm({
			...item,
			rod_km_ini: item.lim_km_ini,
			rod_km_ini_mt: item.lim_km_ini_mts,
			rod_km_fim: item.lim_km_fim,
			rod_km_fim_mt: item.lim_km_fim_mts,
			conc_cod: item.lim_conc,
			rod_cod: item.lim_rod,
			emp_cod: item.lim_emp,
			gp_cod: item.lim_gp,
		})
		navigate('/highway_limit/new')
	}
	const handleDelete = (item) => {
		const { rod_desc, lim_cod } = item
		const confirm = window.confirm(`Você deseja mesmo excluir o limite para a rodovia ${rod_desc} ?`)
		if (confirm) {
			deleteItemHighwayLimit(lim_cod)
			handleForm({
				gp_cod: 0,
				conc_cod: 0,
				rod_cod: 0,
				gp_desc: '',
				conc_nome: '',
				rod_desc: '',
			})
		}
	}
	return (
		items.map(item => (
			<div className="row p-3" key={item.lim_cod}>
				<div className="col-md-10 col-sm-12">
					<div className="row">
						<div className="col-md-4 col-sm-12">
							<span className="text-secondary mr-2">Rodovia:</span>
							{item.rod_desc}
						</div>
						<div className="col-md-4 col-sm-12">
							<span className="text-secondary mr-2">Código Rodovia:</span>
							{item.rod_km}
						</div>
						<div className="col-md-4 col-sm-10">
							<span className="text-secondary mr-2">Concessionaria:</span>
							{item?.conc_nome}
						</div>
						<div className="col-md-3 col-sm-10">
							<span className="text-secondary mr-2">Empresa:</span>
							{item?.emp_nome}
						</div>
						<div className="col-md-3 col-sm-12">
							<span className="text-secondary mr-2">Grupo:</span>
							{item.gp_desc}
						</div>
						<div className="col-md-3 col-sm-12">
							<span className="text-secondary mr-2">KM inicial:</span>
							{item.lim_km_ini} + Mts: {item.lim_km_ini_mts || 0}
						</div>
						<div className="col-md-3 col-sm-12">
							<span className="text-secondary mr-2">Km final:</span>
							{item.lim_km_fim} + Mts: {item.lim_km_fim_mts || 0}
						</div>
					</div>
				</div>
				{!justList && (
					<div className="col-md-2 col-sm-2">
						<button
							className="btn btn-info btn-sm mr-2"
							onClick={() => handleEdit(item)}
						>
							<i className="ml-1 fa fa-edit"></i>
						</button>
						<button
							className="btn btn-secondary btn-sm"
							onClick={() => handleDelete(item)}
						>
							<i className="fa fa-trash"></i>
						</button>
					</div>
				)}
				{selectUser && (
					<div className="col-md-2 col-sm-2">
						<button
							className="btn btn-info btn-sm mr-2"
							onClick={() => handleEdit(item)}
						>
							<i className="ml-1 fa fa-edit"></i>
						</button>
					</div>
				)}
				<hr className="w-100" />
			</div>
		))
	)
}
export default ListHighwayLimit