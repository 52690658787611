import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import ContentHeader from '../../../components/contentHeader'
import { formContext } from '../../../context/formContext'
import Spinner from '../../../components/spinner'
import ListServiceConstruction from '../../../components/list/serviceConstruction'
import SearchServiceConstruction from '../../../components/form/searchServiceConstruction'
import { namesScreens } from '../../../constants'
import FormFilterServiceConstruction from '../../../components/form/filters/ServiceConstruction'
const ManegerServiceConstructions = () => {
	const { getItemServiceConstructions, data, setCheckInspection, getWhereItemServiceConstructions } =
		useContext(requestContext)
	const { handleForm, state } = useContext(formContext)
	const {
		serv_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_tip_obra,
		serv_sub_cat_desc
	} = state
	const navigate = useNavigate()
	const fetchItems = () => {
		getItemServiceConstructions({
			id: serv_cod,
			serv_cat_cod,
			serv_sub_cat_cod,
			serv_tip_obra
		})
	}
	useEffect(() => {
		fetchItems()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = data
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			if (serv_sub_cat_desc) {
				return getWhereItemServiceConstructions(current_page > 1 && {
					id: serv_cod,
					serv_cat_cod,
					serv_sub_cat_cod,
					serv_sub_cat_desc,
					serv_tip_obra: `${serv_tip_obra || 0}?page=${current_page - 1}`
				})
			}
			return getItemServiceConstructions(current_page > 1 && {
				id: serv_cod,
				serv_cat_cod,
				serv_sub_cat_cod,
				serv_tip_obra: `${serv_tip_obra || 0}?page=${current_page - 1}`
			})
		}
		if (label === 'pagination.next') {
			if (serv_sub_cat_desc) {
				return getWhereItemServiceConstructions(current_page > 1 && {
					id: serv_cod,
					serv_cat_cod,
					serv_sub_cat_cod,
					serv_sub_cat_desc,
					serv_tip_obra: `${serv_tip_obra || 0}?page=${current_page + 1}`
				})
			}
			return getItemServiceConstructions(current_page < last_page && {
				id: serv_cod,
				serv_cat_cod,
				serv_sub_cat_cod,
				serv_tip_obra: `${serv_tip_obra || 0}?page=${current_page + 1}`
			})
		}
		if (!label) {
			if (serv_sub_cat_desc) {
				return getItemServiceConstructions(current_page < last_page && {
					id: serv_cod,
					serv_cat_cod,
					serv_sub_cat_cod,
					serv_sub_cat_desc,
					serv_tip_obra
				})
			}
			return fetchItems()
		}
		if (serv_sub_cat_desc) {
			return getItemServiceConstructions(current_page < last_page && {
				id: serv_cod,
				serv_cat_cod,
				serv_sub_cat_cod,
				serv_sub_cat_desc,
				serv_tip_obra: `${serv_tip_obra || 0}?page=${label}`
			})
		}
		getItemServiceConstructions({
			id: serv_cod,
			serv_cat_cod,
			serv_sub_cat_cod,
			serv_tip_obra: `${serv_tip_obra || 0}?page=${label}`
		})
	}
	const handleNew = () => {
		setCheckInspection(null)
		handleForm({
			tip_ob_cod: '',
			tip_ob_desc: '',
			tip_ob_sub_cod: '',
			tip_ob_sub_desc: '',
			tip_ob_sub_tip_ob: '',
			tip_sub_ob: '',
		})
		navigate(namesScreens.SERVICES_CONSTRUCTIONS_NEW)
	}
	const handleClick = () => {
		fetchItems()
	}
	const handleClearFilters = () => {
		getItemServiceConstructions({
			id: 0,
			serv_cat_cod: 0,
			serv_sub_cat_cod: 0,
			serv_tip_obra: 0,
		})
		handleForm({
			tip_ob_cod: '',
			tip_ob_desc: '',
			tip_ob_sub_cod: '',
			tip_ob_sub_desc: '',
			tip_ob_sub_tip_ob: '',
			tip_sub_ob: '',
			serv_cat_cod: '',
			serv_sub_cat_cod: '',
			serv_tip_obra: '',
			serv_sub_cat_desc: '',
		})
	}
	return (
		<>
			<ContentHeader title={'Serviços e Disciplinas de Obras'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-4 d-none d-md-block">Serviços cadastradas</h3>
						<div className="card-tools col-md-8 col-sm-12">
							<SearchServiceConstruction />
						</div>
					</div>
					<div className='card-body'>
						<FormFilterServiceConstruction handleClearFilters={handleClearFilters} handleClick={handleClick} />
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					{Array.isArray(data?.data) && (
						<ListServiceConstruction data={data} />
					)}
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(data?.links) &&
								data.links.map((item, i) => (
									<li className="page-item" key={item.label}>
										<Link className={`page-link ${item.active && 'text-light bg-info'}`}
											to="#"
											key={i}
											onClick={() => handlePage(item)}
										>
											{item.label === 'pagination.previous' ? (
												<i className="fa fa-angle-left"></i>
											) : item.label === 'pagination.next' ? (
												<i className="fa fa-angle-right"></i>
											) : (
												item.label
											)}
										</Link>
									</li>
								))}
						</ul>
						<ul className="pagination pagination-sm m-0 float-left">
							<li className="page-item">
								<div className="page-link" onClick={handleNew}>
									Novo <i className="fa fa-plus mr-1 ml-1"></i>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default ManegerServiceConstructions
