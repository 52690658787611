import React, { useContext, useEffect } from 'react'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { namesScreens } from '../../../constants'
import SearchPavementIndicatorTypeIndex from '../../../components/form/searchPavementIndicatorTypeIndex'
import ListTypesIndex from '../../../components/list/typeIndex'
const ManagerTypesIndex = () => {
	const { state, handleForm } = useContext(formContext)
	const { complements, getItemTypeIndexPavementIndicators, getWhereItemTypeIndexPavementIndicators } = useContext(requestContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	const {
		desc,
	} = state
	useEffect(() => {
		getItemTypeIndexPavementIndicators({ id: 0 })
	}, [])
	const handleNew = () => {
		handleForm({
			tip_ind_pav_cod: '',
			gp_ind_pav_desc: ''
		})
		navigate(namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX_NEW)
	}
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = complements
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return desc ? getWhereItemTypeIndexPavementIndicators(current_page > 1 && `${desc}?page=${current_page - 1}`) : getItemTypeIndexPavementIndicators(current_page > 1 && {
				id: `0?page=${current_page - 1}`
			})
		}
		if (label === 'pagination.next') {
			return desc ? getWhereItemTypeIndexPavementIndicators(current_page > 1 && `${desc}?page=${current_page - 1}`) : getItemTypeIndexPavementIndicators(current_page < last_page && {
				id: `0?page=${current_page + 1}`
			})
		}
		if (!label) {
			return desc ? getWhereItemTypeIndexPavementIndicators(desc) : getItemTypeIndexPavementIndicators({
				id: 0
			})
		}
		if (desc) {
			return getWhereItemTypeIndexPavementIndicators(`${desc}?page=${label}`)
		}
		getItemTypeIndexPavementIndicators({
			id: `0?page=${label}`
		})
	}
	const RenderLinkFooter = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Tipos de índices'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title d-md-block d-none">Tipos de índices cadastrados</h3>
						<div className="card-tools">
							<SearchPavementIndicatorTypeIndex handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className='card'>
					<div className="card-body">
						<Spinner direction={'center'} label="carregando" />
						<ListTypesIndex data={complements?.data} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(complements?.links) &&
								complements.links.map((item) => (<RenderLinkFooter item={item} key={item.label} />
								))}
						</ul>
						{user?.set_nivel === 0 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerTypesIndex