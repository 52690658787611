import React, { useContext } from 'react'
import ContentHeader from '../../../../components/contentHeader'
import { handleError } from '../../../../services/isValid'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
import { useNavigate } from 'react-router'
import { namesScreens } from '../../../../constants'
import FormGroupsIndex from '../../../../components/form/groupIndex'
const NewGroupPavementIndex = () => {
	const { state: { gp_ind_pav_cod, gp_ind_pav_desc }, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemPavementIndicatorsGroupsIndex } = useContext(requestContext)
	const navigate = useNavigate()
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (!gp_ind_pav_desc) {
			return setInfoRequest(handleError())
		}
		window.scrollTo(0, 0)
		postItemPavementIndicatorsGroupsIndex({
			id: gp_ind_pav_cod,
			gp_ind_pav_desc
		})
	}
	const handleCancel = () => {
		handleForm({
			gp_ind_pav_cod: '',
			gp_ind_pav_desc: ''
		})
		navigate(namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX)
	}
	return (
		<>
			<ContentHeader title={'Novo Group de Indice'} />
			<form onSubmit={onSubmit} className='p-3'>
				<div className="card card-info">
					<div className="card-header">
						<h3 className="card-title text-capitalize"></h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<FormGroupsIndex />
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<button className="btn btn-info mr-3" type='submit'>
							Salvar <i className="fa fa-save"></i>
						</button>
						<div className="btn btn-secondary" onClick={handleCancel}>
							Cancelar <i className="fa fa-times-circle"></i>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}
export default NewGroupPavementIndex