import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { authContext } from '../../../context/authContext'
import Spinner from '../../spinner'
const FormServiceConstructionPlan = ({ closeModal }) => {
	const { postItemServiceConstructionPlan, setInfoRequest } = useContext(requestContext)
	const { onChange, state: { serv_sub_cat_subitem_der, pvs_servico_sub_cat, serv_sub_cat_desc, prog_serv_serv_cod, pvs_pedidovenda, pvs_qtd, pvs_valor, serv_sub_cat_valor, prog_serv_cod, prog_serv_qtd_acul }, onChangeNumber } = useContext(formContext)
	const [disabledInput, setDisabledInput] = useState(true)
	const { user } = useContext(authContext)
	useEffect(() => {
		const loadDisabledInput = () => {
			if (!user) {
				return
			}
			if (user?.set_nivel === 0) {
				setDisabledInput(false)
			}
		}
		loadDisabledInput()
	}, [user])
	const handleSubmit = async () => {
		const item = {
			prog_serv_serv_cod,
			pvs_pedidovenda,
			pvs_valor,
			pvs_qtd,
			prog_serv_cod,
			prog_serv_qtd_acul,
			serv_sub_cat_desc,
			pvs_servico_sub_cat,
		}
		const valid = isValid(item)
		if (!valid) {
			return setInfoRequest(handleWaring())
		}
		const response = await postItemServiceConstructionPlan({
			...item,
			id: prog_serv_serv_cod,
			pvs_cod: prog_serv_serv_cod,
		})
		if (closeModal && response) {
			closeModal()
		}
	}
	return (
		<div className="row">
			<div className="form-group col-md-8 col-sm-12">
				<label>Descrição do serviço:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						disabled={disabledInput}
						name='serv_sub_cat_desc' value={serv_sub_cat_desc}
						className={`form-control form-control-border ${!serv_sub_cat_desc && 'is-invalid'}`}
						placeholder='Descrição da Categoria' />
				</div>
			</div>
			<div className='form-group col-md-4 col-sm-12'>
				<label>Código Sub item DER</label>
				<div className='input-group'>
					<input type="text"
						onChange={onChange}
						disabled={disabledInput}
						name='serv_sub_cat_subitem_der' value={serv_sub_cat_subitem_der}
						className={`form-control form-control-border ${!serv_sub_cat_subitem_der && 'is-invalid'}`}
						placeholder='Descrição da Categoria' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Quantidade:</label>
				<div className="input-group">
					<input type="number"
						onChange={onChangeNumber}
						name='pvs_qtd' value={pvs_qtd}
						min={0}
						className={`form-control form-control-border ${!pvs_qtd && 'is-invalid'}`}
						placeholder='Quantidade do serviço' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Valor unitário:</label>
				<div className="input-group">
					<input type="number"
						onChange={onChangeNumber}
						name='pvs_valor' value={pvs_valor}
						min={0}
						className={`form-control form-control-border ${!pvs_valor && 'is-invalid'}`}
						placeholder={serv_sub_cat_valor} />
				</div>
			</div>
			<div className='col-12'>
				<div className='btn btn-info' onClick={handleSubmit}>
					<i className='fa fa-save'></i> Salvar <Spinner direction={'center'} label={' '}/>
				</div>
			</div>
		</div>
	)
}
export default FormServiceConstructionPlan