import React, { useContext, useEffect } from 'react'
import ContentHeader from '../../components/contentHeader'
import { requestContext } from '../../context/requestContext'
import Spinner from '../../components/spinner'
import { Link, useNavigate } from 'react-router-dom'
import { formContext } from '../../context/formContext'
import { authContext } from '../../context/authContext'
import ListTutoriais from '../../components/list/tutoriais'
import SearchTutorial from '../../components/form/searchTutorial'
const HelpMovies = () => {
	const { getItemTutoriais, getWhereItemTutoriais, data } = useContext(requestContext)
	const { state, handleForm } = useContext(formContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	const {
		tut_descricao,
	} = state
	useEffect(() => {
		getItemTutoriais()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = data
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return tut_descricao ? getWhereItemTutoriais(current_page > 1 && `${tut_descricao}?page=${current_page - 1}`) : getItemTutoriais(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return tut_descricao ? getWhereItemTutoriais(current_page < last_page && `${tut_descricao}?page=${current_page + 1}`) : getItemTutoriais(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return tut_descricao ? getWhereItemTutoriais(tut_descricao) : getItemTutoriais()
		}
		if (tut_descricao) {
			return getWhereItemTutoriais(`${tut_descricao}?page=${label}`)
		}
		getItemTutoriais(`0?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			tut_cod: '',
			tut_path: '',
			tut_titulo: '',
			tut_descricao: '',
			tut_tipo_arquivo: '',
			tut_disp: '',
			tut_set_nivel: '',
			tut_criador: '',
		})
		navigate('/tutorial/new')
	}
	const RenderTutorial = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
						}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Seção de Tutorial'} />
			<div className='content'>
				<div className="card">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
							Tutoriais para ajudar você
						</h3>
						<div className="card-tools col-md-4 col-sm-12">
							<SearchTutorial handleNew={handleNew} />
						</div>
					</div>
					<Spinner direction={'center'} label='carregando' />
					<div className="card-body">
						<ListTutoriais data={data} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(data?.links) &&
								data.links.map((item) => (<RenderTutorial item={item} key={item.label} />))}
						</ul>
						{user && user.set_nivel === 0 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default HelpMovies