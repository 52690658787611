import React, { useEffect, useState } from 'react'
import { nameVersion } from '../../../constants'
const ListVersion = ({ data }) => {
	const [items, setItems] = useState(null)
	useEffect(() => {
		if (data?.total > 0) {
			setItems(data.data)
		} else {
			setItems(null)
		}
	}, [data])
	if (!Array.isArray(items)) {
		return <></>
	}
	return (
		items.map(item => (
			<div className="row p-3" key={item.vers_cod}>
				<div className="col-md-10 col-sm-12">
					<div className="row p-3">
						<div className="col-4">
							<span className="text-secondary mr-2">Versão:</span>
							{item.vers_number}
						</div>
						<div className="col-4">
							<span className="text-secondary mr-2">Nome da Versão:</span>
							{item.vers_name}
						</div>
						<div className="col-4">
							<span className="text-secondary mr-2">Ambiente:</span>
							{item.vers_ambiente === 'Teste' ? (
								<span className="badge badge-info text-light">
									{item.vers_ambiente}
								</span>
							) : (
								<span className="badge badge-success text-light">
									{item.vers_ambiente}
								</span>
							)}
						</div>
						<div className="col-4">
							<span className="text-secondary mr-2">Tipo:</span>
							{item.vers_tipo}
						</div>
						{item.vers_tipo === nameVersion.APP ? (
							<div className="col-4">
								<span className="text-secondary mr-2">Download:</span>
								<a href={item.vers_url} className="text-dark" target="_blank" rel="noreferrer">Clique aqui</a>
							</div>
						) : (
							<div className="col-4">
								<span className="text-secondary mr-2">Acessar:</span>
								<a href={item.vers_url} className="text-dark" target="_blank" rel="noreferrer">Clique aqui</a>
							</div>
						)}
					</div>
				</div>
				<div className="col-md-2 col-sm-12 d-none d-md-flex">
					{item.vers_tipo === nameVersion.APP ? (
						<div className="row">
							<i className="fa fa-mobile fa-6x" aria-hidden="true"></i>
							{item.vers_ambiente === 'Teste' && <i className="fa fa-cog" aria-hidden="true"></i>}
						</div>
					) : (
						<div className="row">
							<i className="fa fa-desktop fa-6x" aria-hidden="true"></i>
							{item.vers_ambiente === 'Teste' && <i className="fa fa-cog" aria-hidden="true"></i>}
						</div>
					)}
				</div>
			</div>
		))
	)
}
export default ListVersion