import { useEffect, useRef } from "react"
import { MenuItem } from "../MenuItem"

export const Menu = ({activeMenu, setActiveMenu, onSubmit}) => {
    
    const firstItem = useRef(null)
    const secondItem = useRef(null)
    const thirdItem = useRef(null)
    const fourthItem = useRef(null)
    const fifthItem = useRef(null)

    useEffect(()=>{

        switch(activeMenu){
            case 1:
                firstItem.current.click()
                break
            case 2:
                secondItem.current.click()
                break
            case 3:
                thirdItem.current.click()
                break
            case 4:
                fourthItem.current.click()
                break
            case 5:
                fifthItem.current.click()
                break
            case 6:
                onSubmit()
                break
            case 7:
                setActiveMenu(6)    
                break
        }

    },[activeMenu])

    return(<ul className="nav flex-column d-flex justify-content-between ">
        <li className="nav-item">
            <a ref={firstItem} data-toggle="tab" href="#menu1" onClick={() => setActiveMenu(1)}>
                <MenuItem title={'Fotos'} desc={'Upload de imagens'} icon={'camera'} menuName={1} activeMenu={activeMenu} />
            </a>
        </li>
        <li className="nav-item">
            <a ref={secondItem} data-toggle="tab" href="#menu2" onClick={() => setActiveMenu(2)}>
                <MenuItem title={'Rodovias'} desc={'Concessionárias'} icon={'directions'} menuName={2} activeMenu={activeMenu} />
            </a>
        </li>
        <li className="nav-item">
            <a ref={thirdItem} data-toggle="tab" href="#menu3" onClick={() => setActiveMenu(3)}>
                <MenuItem title={'Localização'} desc={'Km inicial e final'} icon={'location-arrow'} menuName={3} activeMenu={activeMenu} />
            </a>
        </li>
        <li className="nav-item">
            <a ref={fourthItem} data-toggle="tab" href="#menu4" onClick={() => setActiveMenu(4)}>
                <MenuItem title={'Atividades'} desc={'Grupos e subgrupos'} icon={'tag'} menuName={4} activeMenu={activeMenu} />
            </a>
        </li>
        <li className="nav-item">
            <a ref={fifthItem} data-toggle="tab" href="#menu5" onClick={() => setActiveMenu(5)}>
                <MenuItem title={'Concluir'} desc={'Resumo final'} icon={'arrow-right'} menuName={5} activeMenu={activeMenu} />
            </a>
        </li>
    </ul>)
}