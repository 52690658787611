import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ContentHeader from '../../components/contentHeader'
import SearchDeadline from '../../components/form/searchDeadline'
import ListDeadline from '../../components/list/deadline'
import Spinner from '../../components/spinner'
import { formContext } from '../../context/formContext'
import { requestContext } from '../../context/requestContext'
import FilterDeadline from '../../components/form/filters/deadline'
const DeadlineManager = () => {
	const { getAllItemDealershipLot, getAllItemService, getItemDeadline, getWhereItemDeadline, deadline, setDeadline } = useContext(requestContext)
	const { handleForm, state: { lot_conc_desc, lot_conc_cod, serv_cod, serv_cat_cod, serv_sub_cat_cod } } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		setDeadline(null)
		getItemDeadline(lot_conc_cod, serv_cod, serv_cat_cod, serv_sub_cat_cod)
		getAllItemDealershipLot()
		getAllItemService()
	}, [])
	const onSubmit = () => {
		getItemDeadline(lot_conc_cod, serv_cod, serv_cat_cod, serv_sub_cat_cod)
	}
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = deadline
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return lot_conc_desc ? getWhereItemDeadline(current_page > 1 && `${lot_conc_desc}?page=${current_page - 1}`) : getItemDeadline(
				lot_conc_cod, serv_cod, serv_cat_cod, current_page > 1 && `${serv_sub_cat_cod || 0}?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return lot_conc_desc ? getWhereItemDeadline(current_page < last_page && `${lot_conc_desc}?page=${current_page + 1}`) : getItemDeadline(
				lot_conc_cod, serv_cod, serv_cat_cod, current_page < last_page && `${serv_sub_cat_cod || 0}?page=${current_page + 1}`)
		}
		if (!label) {
			return lot_conc_desc ? getWhereItemDeadline(lot_conc_desc) : getItemDeadline(lot_conc_cod, serv_cod, serv_cat_cod, serv_sub_cat_cod)
		}
		if (lot_conc_desc) {
			return getWhereItemDeadline(`${lot_conc_desc}?page=${label}`)
		}
		if (lot_conc_desc) {
			return getWhereItemDeadline(`${lot_conc_desc}?page=${label}`)
		}
		getItemDeadline(lot_conc_cod, serv_cod, serv_cat_cod, `${serv_sub_cat_cod}?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			praz_cod: '',
			praz_dias: '',
			praz_ane_inf: '',
			praz_lote: '',
			praz_serv_sub_cat: '',
			serv_sub_cat_cod: '',
			lot_conc_cod: '',
			lot_cont_desc: '',
			conc_cod: '',
			conc_nome: '',
			serv_sub_cat_desc: '',
			lot_conc_desc: '',
		})
		navigate('/deadline/new')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Prazos de serviços'} />
			<div className='content'>
				<div className="card">
					<div className="card-header sticky-top">
						<h3 className="card-title col-8 d-none d-md-block">Prazos cadastradas</h3>
						<div className="card-tools col-md-4 col-sm-12">
							<SearchDeadline handleNew={handleNew} />
						</div>
					</div>
					<div className='card-body'>
						<FilterDeadline onSubmit={onSubmit} />
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label='carregando' />
					<ListDeadline data={deadline} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(deadline?.links) && deadline.links.map((item) => (
								<li className="page-item" key={item.label}>
									<Link className={`page-link ${item.active && 'text-light bg-info'}`}
										to="#" onClick={() => handlePage(item)}>
										{item.label === 'pagination.previous' ? <i className="fa fa-angle-left"></i> :
											item.label === 'pagination.next' ? <i className="fa fa-angle-right"></i> :
												item.label
										}
									</Link>
								</li>
							))}
						</ul>
						<ul className="pagination pagination-sm m-0 float-left">
							<li className="page-item">
								<Link to='/deadline/new' className="page-link">
									Novo <i className='fa fa-plus mr-1 ml-1'></i>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default DeadlineManager