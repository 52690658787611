import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import FormDealershipNew from '../../../components/form/dealershipNew'
import DealershipContact from '../../../components/form/dealershipContact'
import DealershipContract from '../../../components/form/dealershipContract'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { isValid, handleWaring } from '../../../services/isValid'
const NewDealership = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemDealership,
		getItemDealershipEmail, getItemDealershipContract,
		getItemDealershipContractAttachment,
		contract, contract_Attachment, setContractAttachment,
		load } = useContext(requestContext)
	const navigation = useNavigate()
	const {
		conc_cod,
		conc_nome,
		conc_cnpj,
		conc_razaosocial,
		conc_endereco,
		conc_numero,
		conc_bairro,
		conc_cep,
		conc_cidade,
		conc_celular,
		conc_telefone,
		conc_email,
		conc_lote,
		cont_ncontrato,
		cont_data_ass,
		ane_desc,
		cont_cod,
		conc_complemento,
		lot_conc_desc,
		conc_color
	} = state
	useEffect(() => {
		if (conc_cod) {
			getItemDealershipEmail(conc_cod)
			getItemDealershipContract(conc_cod)
		}
	}, [conc_cod])
	useEffect(() => {
		setContractAttachment(null)
		if (!conc_lote) {
			handleForm({
				ane_desc: ''
			})
		}
	}, [])
	useEffect(() => {
		if (contract?.total > 0) {
			const data = contract.data[0]
			handleForm({
				...data
			})
		}
	}, [contract])
	useEffect(() => {
		if (conc_cod) {
			getItemDealershipContractAttachment(conc_cod)
		}
	}, [conc_cod])
	useEffect(() => {
		if (contract_Attachment?.total > 0 && conc_cod) {
			handleForm({
				ane_desc: contract_Attachment.data
			})
		}
	}, [contract_Attachment])
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			conc_nome,
			conc_cnpj,
			conc_razaosocial,
			conc_endereco,
			conc_numero,
			conc_bairro,
			conc_cep,
			conc_cidade: parseInt(conc_cidade),
			conc_celular,
			conc_telefone,
			conc_email,
			conc_lote: conc_cod ? conc_lote : lot_conc_desc,
			cont_ncontrato,
			cont_data_ass,
			ane_desc,
			lot_conc_desc
		}
		const validate = isValid(item)
		if (!Array.isArray(ane_desc)) {
			return setInfoRequest(handleWaring())
		}
		if (!Array.isArray(conc_email)) {
			return setInfoRequest(handleWaring())
		}
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemDealership({
			...item,
			id: conc_cod,
			conc_cod,
			conc_complemento,
			cont_cod,
			conc_color
		})

	}
	const handleCancel = () => {
		clearValues()
		navigation('/dealership')
	}
	const clearValues =() => {
		handleForm({
			conc_nome: '',
			conc_cnpj: '',
			conc_razaosocial: '',
			conc_endereco: '',
			conc_numero: '',
			conc_bairro: '',
			conc_cep: '',
			conc_cidade: '',
			conc_celular: '',
			conc_telefone: '',
			conc_email: '',
			conc_lote: '',
			cont_ncontrato: '',
			cont_data_ass: '',
			ane_desc: '',
			em_email: '',
			dd: '',
			lot_conc_desc: '',
		})
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Concessionária'} />
			<div className='content'>
				<Spinner show={load} />

				<form onSubmit={onSubmit}>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Concessionária</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormDealershipNew />
						</div>
					</div>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Contrato da Concessionária</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<DealershipContract />
						</div>
					</div>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Contatos da Concessionária</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<DealershipContact />
						</div>
					</div>
					<div className="card">
						<div className='card-body'>
							<button className='btn btn-info mr-3'>
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewDealership