import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { authContext } from '../../../context/authContext'
import SearchPavementCheckList from '../../../components/form/searchPavement'
import { namesScreens } from '../../../constants'
import ListChecklists from '../../../components/list/listChecklists'
const ManagerCheckListPaviment = () => {
	const { checklists, getAllItemChecklist } = useContext(requestContext)
	const { handleForm, state: { sett_desc } } = useContext(formContext)
	const { user } = useContext(authContext)
	useEffect(() => {
		getAllItemChecklist()
	}, [])

	const navigate = useNavigate()
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = checklists
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return sett_desc ? console.log(current_page > 1 && `${sett_desc}?page=${current_page - 1}`) : console.log(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return sett_desc ? console.log(current_page > 1 && `${sett_desc}?page=${current_page - 1}`) : console.log(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return sett_desc ? console.log(sett_desc) : console.log()
		}
		if (sett_desc) {
			return console.log(`${sett_desc}?page=${label}`)
		}
		console.log(`0?page=${label}`)
	}
	const handleNew = () => {
		handleForm({

		})
		navigate(namesScreens.PAVEMENT_CHECKLIST_NEW)
	}
	const RenderLinks = ({ item }) => {
		return (

			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>

		)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Checklists'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
							Lista de Checklists cadastrados
						</h3>
						<div className="card-tools">
							<SearchPavementCheckList handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListChecklists data={checklists} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(checklists?.links) &&
								checklists.links.map((item) => (<RenderLinks item={item} key={item.label} />))}
						</ul>
						{user && user.set_nivel === 0 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerCheckListPaviment
