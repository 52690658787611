import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Dealership from '../../../components/form/dealership'
import FormNewHighway from '../../../components/form/roadNew'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, isValid } from '../../../services/isValid'
const NewHighway = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, getAllItemDealershipLot, postItemHighway,
		getItemTypeHigway, getAllItemCardinalPoints } = useContext(requestContext)
	const navigate = useNavigate()
	const {
		rod_cod,
		rod_desc,
		rod_concessionaria,
		rod_km,
		rod_km_ini,
		rod_km_fim,
		rod_km_ini_mt,
		rod_km_fim_mt,
		rod_extencao,
		rod_tipo,
		rod_sent_rodovia
	} = state
	useEffect(() => {
		getItemTypeHigway()
	}, [])
	useEffect(() => {
		const loadHighway = () => {
			if (!rod_cod) {
				return getAllItemDealershipLot()
			}
		}
		const loadHighwayCardinalPoints = () => {
			if (rod_concessionaria && rod_cod) {
				return getAllItemCardinalPoints(rod_concessionaria, rod_cod)
			}
		}
		loadHighwayCardinalPoints()
		loadHighway()
	}, [rod_concessionaria, rod_cod])

	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			rod_desc,
			rod_concessionaria,
			rod_km,
			rod_km_ini: +parseInt(rod_km_ini),
			rod_km_fim: +parseInt(rod_km_fim),
			rod_km_ini_mt: +parseFloat(rod_km_ini_mt).toFixed(2),
			rod_km_fim_mt: +parseFloat(rod_km_fim_mt).toFixed(2),
			rod_extencao: +parseFloat(rod_extencao).toFixed(2),
			rod_tipo: +parseInt(rod_tipo),
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemHighway({
			...item,
			id: rod_cod,
			rod_cod,
			rod_sent: !Array.isArray(rod_sent_rodovia) ? [] : rod_sent_rodovia || []
		})
	}
	const handleCancel = () => {
		handleForm({
			rod_cod: '',
			rod_desc: '',
			rod_lot: '',
			rod_km: '',
			rod_sent_cod: '',
			rod_km_ini: '',
			rod_km_fim: '',
			rod_km_ini_mt: '',
			rod_km_fim_mt: '',
			rod_extencao: '',
			rod_tipo: '',
		})
		navigate('/highway')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Rodovia'} />
			<div className='content'>
				<form onSubmit={onSubmit} method="post">
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Concessionária</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<Dealership edit_road />
						</div>
					</div>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Rodovia</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormNewHighway edit={rod_cod ? true : false} />
						</div>
					</div>
					<div className="card">
						<div className='card-body'>
							<button type='submit' className='btn btn-info mr-3'>
								Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewHighway