import React, { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import ContentHeader from "../../../components/contentHeader"
import { authContext } from "../../../context/authContext"
const Logout = () => {
    const { logout } = useContext(authContext)
    const handleSubmit = () => {
        logout(navigate('/'))
    }
    const navigate = useNavigate()
    return (
        <>
            <ContentHeader title={'Logoff'} />
            <div className='content'>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="card-title">Deseja mesmo sair?</h3>
                    </div>

                    <div className="card-body">
                        <div className="d-flex flex-row align-items-center">
                            <div className="btn btn-block btn-info mr-3 mt-2" onClick={handleSubmit}>Sim</div>
                            <Link to='/' className="btn btn-block btn-secondary">Não</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Logout