import React, { useContext, useEffect, useRef, useState } from 'react'
import { CAMERA_CONFIG } from '../../config/flavor'
import { formContext } from '../../context/formContext'
import { requestContext } from '../../context/requestContext'
import { styleCanvas, styleVideo } from '../../css/globlalStyle'
import { storageCache } from '../../services/cacheManager'
import returnPosition from '../../services/geolocation'
import mediaStrean from '../../services/mediaStream'
import { getToday } from '../../services/dateHandless'
import { execptionWithIcons } from '../../api'
const Camera = ({ setImages, images, pvs_loc_foto_legenda }) => {
	const video = useRef(null)
	const canvas = useRef(null)
	const { setInfoRequest, isConection } = useContext(requestContext)
	const { bases64, setBases64, setShowCamera } = useContext(formContext)
	const [base64, setBase64] = useState(null)
	const [captured, setCaptured] = useState(false)
	useEffect(() => {
		const facingMode = {
			facingMode: 'environment',
		}
		const loadCamera = async () => {
			try {
				const { constraints } = CAMERA_CONFIG
				constraints.video = {
					width: window.innerWidth || constraints.video.width,
					height: window.innerHeight || constraints.video.height,
					...facingMode,
				}
				const videoStream = await navigator.mediaDevices.getUserMedia({
					...constraints,
				})
				video.current.srcObject = videoStream
			} catch (error) {
				const execptionWithicons = execptionWithIcons(500)
				setInfoRequest({
					status: 500,
					message:
            'Não foi possível iniciar sua câmera, verifique se sua câmera está funcionando ou se você deu permissão para utilizar a câmera',
					...execptionWithicons,
				})
			}
		}
		const isCamera = mediaStrean()
		if (isCamera) {
			loadCamera()
		}
	}, [setInfoRequest])

	const handleCapture = () => {
		canvas.current.width = video.current.videoWidth
		canvas.current.height = video.current.videoHeight
		canvas.current.getContext('2d').drawImage(video.current, 0, 0)
		setCaptured(true)
		setBase64(canvas.current.toDataURL('image/png'))
	}
	const onSubmitCapture = async () => {
		try {
			const position = isConection
				? isConection
					? await returnPosition()
					: { lat: 0, lng: 0 }
				: { lat: 0, lng: 0 }
			if (setImages) {
				return handleAddOtherComponents(position)
			}
			Array.isArray(bases64)
				? setBases64([
					...bases64,
					{
						base64: base64,
						pvs_loc_foto_lat: position.lat,
						pvs_loc_foto_lng: position.lng,
						pvs_loc_foto_legenda: `Foto tirada dia ${getToday()}. ${pvs_loc_foto_legenda}`,
					},
				])
				: setBases64([
					{
						base64: base64,
						pvs_loc_foto_lat: position.lat,
						pvs_loc_foto_lng: position.lng,
						pvs_loc_foto_legenda: `Foto tirada dia ${getToday()}. ${pvs_loc_foto_legenda}`,
					},
				])
			const execptionWithicons = execptionWithIcons(200)
			setInfoRequest({
				status: 200,
				message: 'Foto adicionada com sucesso',
				...execptionWithicons,
			})
			setShowCamera(false)
		} catch (error) {
			const execptionWithicons = execptionWithIcons(500)
			setInfoRequest({
				status: 500,
				message: 'Não foi possível salvar sua imagem, tente mais tarde',
				...execptionWithicons,
			})
			storageCache('photo', error)
		} finally {
			setCaptured(false)
		}
	}
	const handleRemove = () => {
		setBase64(null)
		setCaptured(false)
	}
	const handleAddOtherComponents = (position) => {
		Array.isArray(images)
			? setImages([
				...images,
				{
					base64: base64,
					pvs_loc_foto_lat: position.lat,
					pvs_loc_foto_lng: position.lng,
					pvs_loc_foto_legenda: pvs_loc_foto_legenda || `Foto tirada dia ${getToday()}`,
				},
			])
			: setImages([
				{
					base64: base64,
					pvs_loc_foto_lat: position.lat,
					pvs_loc_foto_lng: position.lng,
					pvs_loc_foto_legenda: pvs_loc_foto_legenda || `Foto tirada dia ${getToday()}`,
				},
			])
		const execptionWithicons = execptionWithIcons(200)
		setInfoRequest({
			status: 200,
			message: 'Foto adicionada com sucesso',
			...execptionWithicons,
		})
		setShowCamera(false)
	}
	return (
		<div
			className="d-flex flex-column align-items-center justify-content-between"
			style={{
				position: 'fixed',
				height: '100vh',
				zIndex: 1,
				width: '100%',
				left: 0,
				right: 0,
				top: 0,
				background: '#FFFF',
				overflowY: 'scroll',
				overflowX: 'hidden',
			}}
			id="camera"
		>
			<canvas
				ref={canvas}
				style={{
					...styleCanvas,
					display: captured ? 'block' : 'none',
				}}
			></canvas>
			<video
				autoPlay
				ref={video}
				style={{
					...styleVideo,
					width: window.innerWidth,
					height: window.innerHeight,
					display: captured ? 'none' : 'block',
				}}
			></video>
			{captured ? (
				<div className="row">
					<div className="mb-3 mt-3 btn btn-info mr-3" onClick={handleRemove}>
						<i className="fa fa-trash" aria-hidden="true"></i>
					</div>
					<div className="mb-3 mt-3 btn btn-info" onClick={onSubmitCapture}>
						<i className="fa fa-save" aria-hidden="true"></i>
					</div>
				</div>
			) : (
				<div className="row">
					<div
						className="mb-3 mt-3 btn btn-info mr-3"
						onClick={handleCapture}
					>
						<i className="fa fa-camera" aria-hidden="true"></i>
					</div>
					<div
						className="mb-3 mt-3 btn btn-info"
						onClick={() => setShowCamera(false)}
					>
						<i className="fa fa-times-circle" aria-hidden="true"></i>
					</div>
				</div>
			)}
		</div>
	)
}
export default Camera
