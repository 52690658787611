import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, isValid } from '../../../services/isValid'
import FormTutorial from '../../../components/form/tutorial'
const NewTutorial = () => {
	const { handleForm, state } = useContext(formContext)
	const {
		setInfoRequest,
		postItemTutoriais,
	} = useContext(requestContext)
	const { tut_titulo, tut_descricao, tut_tipo_arquivo, tut_base_64, tut_disp} = state
	const navigate = useNavigate()

	const onSubmit = (e) => {
		window.scrollTo(0, 0)
		e.preventDefault()
		e.stopPropagation()
		const item = {
			tut_titulo, 
			tut_descricao, 
			tut_tipo_arquivo,
			tut_base_64,
			tut_disp
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}

		postItemTutoriais(item)
		
		handleForm({
			tut_titulo: '',
			tut_descricao: '', 
			tut_tipo_arquivo : '',
			tut_disp: '',
			tut_set_nivel: '',
			tut_base_64: '',
		})
	}
	const handleCancel = () => {
		handleForm({
			tut_titulo: '',
			tut_descricao: '', 
			tut_tipo_arquivo : '',
			tut_disp: '',
			tut_set_nivel: '',
			tut_base_64: '',
		})
		navigate('/tutorial')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Novos Tutoriais'} />
			<div className="content">
				<Spinner label={'Salvando dados'} />
				<form onSubmit={onSubmit}>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Tutorial</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormTutorial />
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<button type="submit" className="btn btn-info mr-3">
                Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={() => handleCancel()}>
                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewTutorial
