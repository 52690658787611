import { useEffect, useRef } from "react"
import { MenuItem } from "../MenuItem"

export const MenuBottom = ({activeMenu, setActiveMenu}) => {
    
    const firstItem = useRef(null)
    const secondItem = useRef(null)
    const thirdItem = useRef(null)
    const fourthItem = useRef(null)
    const fifthItem = useRef(null)

    return(<ul className="nav flex-row justify-content-around">
        <div className="col-1"></div>
        <li className="nav-item col-2">
            <a ref={firstItem} data-toggle="tab" href="#menu1" onClick={() => setActiveMenu(1)}>
                <MenuItem title={'Fotos'} desc={'Upload de imagens'} icon={'camera'} menuName={1} activeMenu={activeMenu} miniMenu={true}/>
            </a>
        </li>
        <li className="nav-item col-2">
            <a ref={secondItem} data-toggle="tab" href="#menu2" onClick={() => setActiveMenu(2)}>
                <MenuItem title={'Rodovias'} desc={'Concessionárias'} icon={'directions'} menuName={2} activeMenu={activeMenu} miniMenu={true} />
            </a>
        </li>
        <li className="nav-item col-2">
            <a ref={thirdItem} data-toggle="tab" href="#menu3" onClick={() => setActiveMenu(3)}>
                <MenuItem title={'Localização'} desc={'Km inicial e final'} icon={'location-arrow'} menuName={3} activeMenu={activeMenu} miniMenu={true} />
            </a>
        </li>
        <li className="nav-item col-2">
            <a ref={fourthItem} data-toggle="tab" href="#menu4" onClick={() => setActiveMenu(4)}>
                <MenuItem title={'Atividades'} desc={'Grupos e subgrupos'} icon={'tag'} menuName={4} activeMenu={activeMenu} miniMenu={true} />
            </a>
        </li>
        <li className="nav-item col-2">
            <a ref={fifthItem} data-toggle="tab" href="#menu5" onClick={() => setActiveMenu(5)}>
                <MenuItem title={'Concluir'} desc={'Resumo final'} icon={'arrow-right'} menuName={5} activeMenu={activeMenu} miniMenu={true} />
            </a>
        </li>
        <div className="col-1"></div>
    </ul>)
}