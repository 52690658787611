import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import Spinner from '../../components/spinner'
import { formContext } from '../../context/formContext'
import { Link } from 'react-router-dom'
import ListManageEmails from '../../components/list/manageEmails'
const ManageEmails = () => {
	const { getItemGerEm, getItemWhereGerEm, gerEm, getItemSector, reSendGerEm, setGerEm } = useContext(requestContext)
	const { state: { emp_nome } } = useContext(formContext)
	const [selectedItems, setSelectedItems] = useState([])
	useEffect(() => {
		setGerEm()
		getItemGerEm()
		getItemSector()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = gerEm
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return emp_nome ? getItemWhereGerEm(current_page > 1 && `${emp_nome}?page=${current_page - 1}`) : getItemGerEm(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return emp_nome ? getItemWhereGerEm(current_page > 1 && `${emp_nome}?page=${current_page - 1}`) : getItemGerEm(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return emp_nome ? getItemWhereGerEm(emp_nome) : getItemGerEm()
		}
		if (emp_nome) {
			return getItemWhereGerEm(`${emp_nome}?page=${label}`)
		}
		getItemGerEm(`0?page=${label}`)
	}
	const resendMultiple = async () => {
		selectedItems.map(async (item) => {
			if (item.selected) {
				reSendGerEm(item.ger_em_cod)
			}
		})
	}
	const RenderLinkFooter = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Gerenciar E-mails'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
                            E-mails
						</h3>
						<div className="card-tools">
							<button
								className="btn btn-info btn-sm mr-2"
								disabled={!selectedItems.length > 0}
								onClick={resendMultiple}
							>
                                Reenviar selecionados
							</button>
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListManageEmails data={gerEm} setSelectedItems={setSelectedItems} selectedItems={selectedItems} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(gerEm?.links) &&
                                gerEm.links.map((item) => (<RenderLinkFooter item={item} key={item.label} />))}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default ManageEmails
