import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import Spinner from '../../components/spinner'
import SearchVersion from '../../components/form/searchVersion'
import { Link } from 'react-router-dom'
import ListVersion from '../../components/list/version'
const ManagerVersion = () => {
	const { getItemVersion, data, setData } = useContext(requestContext)
	useEffect(() => {
		setData(null)
		getItemVersion()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = data
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return getItemVersion(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return getItemVersion(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return getItemVersion()
		}
		getItemVersion(`0?page=${label}`)
	}
	const RenderVersions = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Histórico de Versões'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
                            Versões lançadas
						</h3>
						<div className="card-tools">
							<SearchVersion />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListVersion data={data} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(data?.links) &&
                                data.links.map((item) => (<RenderVersions item={item} key={item.label} />
                                ))}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerVersion
