import React, { useEffect, useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { Link } from 'react-router-dom'
import { styleButtonSearch } from '../../../css/globlalStyle'
const FormConfigReportConstructionPlan = ({ handlePrint, fetchServicesMonitoring }) => {
	const { state, handleForm, onChange } = useContext(formContext)
	const {
		ped_venda_date_from,
		ped_venda_date_to,
		show_info_construction_plan,
		show_work_schedule,
		show_progress_all_service,
		show_chart_resume_progress,
		show_photos_in_report,
		show_attachments,
	} = state
	useEffect(() => {
		handleForm({
			show_info_construction_plan: true,
			show_work_schedule: true,
			show_progress_of_Disciplinas: true,
			show_progress_all_service: true,
			show_history_check_inspection: true,
			show_chart_resume_progress: false,
		})
	}, [])
	const onClick = () => {
		if (!handlePrint) {
			return
		}
		handlePrint()
	}
	return (
		<div className='row'>
			<div className="form-group col-md-6 col-sm-12">
				<div className='custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger'>
					<input
						type="checkbox"
						className="custom-control-input"
						id="show_info_construction_plan"
						name="show_info_construction_plan"
						checked={show_info_construction_plan}
						onChange={({ target: { name } }) =>
							handleForm({
								[name]: !show_info_construction_plan
							})
						}
					/>
					<label
						className="custom-control-label"
						htmlFor="show_info_construction_plan"
					>Mostrar informações básicas sobre a obra</label>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<div className='custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger'>
					<input
						type="checkbox"
						className="custom-control-input"
						id="show_work_schedule"
						name="show_work_schedule"
						checked={show_work_schedule}
						onChange={({ target: { name } }) =>
							handleForm({
								[name]: !show_work_schedule
							})
						}
					/>
					<label
						className="custom-control-label"
						htmlFor="show_work_schedule"
					>Mostrar informações de cronograma</label>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<div className='custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger'>
					<input
						type="checkbox"
						className="custom-control-input"
						id="show_chart_resume_progress"
						name="show_chart_resume_progress"
						checked={show_chart_resume_progress}
						onChange={({ target: { name } }) =>
							handleForm({
								[name]: !show_chart_resume_progress
							})
						}
					/>
					<label
						className="custom-control-label"
						htmlFor="show_chart_resume_progress"
					>Mostrar gráficos</label>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<div className='custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger'>
					<input
						type="checkbox"
						className="custom-control-input"
						id="show_progress_all_service"
						name="show_progress_all_service"
						checked={show_progress_all_service}
						onChange={({ target: { name } }) =>
							handleForm({
								[name]: !show_progress_all_service
							})
						}
					/>
					<label
						className="custom-control-label"
						htmlFor="show_progress_all_service"
					>Mostrar progresso de todos os seviços</label>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<div className='custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger'>
					<input
						type="checkbox"
						className="custom-control-input"
						id="show_attachments"
						name="show_attachments"
						checked={show_attachments}
						onChange={({ target: { name } }) =>
							handleForm({
								[name]: !show_attachments
							})
						}
					/>
					<label
						className="custom-control-label"
						htmlFor="show_attachments"
					>Mostrar anexos</label>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<div className='custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger'>
					<input
						type="checkbox"
						className="custom-control-input"
						id="show_photos_in_report"
						name="show_photos_in_report"
						checked={show_photos_in_report}
						onChange={({ target: { name } }) =>
							handleForm({
								[name]: !show_photos_in_report
							})
						}
					/>
					<label
						className="custom-control-label"
						htmlFor="show_photos_in_report"
					>Mostrar fotos</label>
				</div>
			</div>
			{fetchServicesMonitoring ? (
				<div className='col-12 mb-3'>
					<div className='row'>
						<div className='col-md-6 col-sm-12'>
							<div className="input-group">
								<input
									type="date"
									className="form-control form-control-border"
									name="ped_venda_date_from"
									value={ped_venda_date_from}
									onChange={onChange}
								/>
							</div>
						</div>
						<div className='col-md-6 col-sm-12'>
							<div className="input-group">
								<input
									type="date"
									className="form-control form-control-border"
									name="ped_venda_date_to"
									value={ped_venda_date_to}
									onChange={onChange}
								/>
								<div className="input-group-prepend" onClick={() => fetchServicesMonitoring()}>
									<span
										className="input-group-text bg-danger"
										style={styleButtonSearch}
									>
										<i className="fa fa-search" aria-hidden="true"></i>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
			<div className='col-12'>
				<button className='btn btn-info mr-2' onClick={onClick}>
					<i className='fa fa-printer'></i>
					Imprimir
				</button>
				<Link to='/construction_plan/view' className="btn btn-secondary">
					<i className="fa fa-arrow-left"></i> Voltar
				</Link>
			</div>
		</div>
	)
}
export default FormConfigReportConstructionPlan