import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { formateDate } from '../../../services/dateHandless'
import { authContext } from '../../../context/authContext'
import SelectHighway from '../selectHighway'
import Dealership from '../dealership'
import { requestContext } from '../../../context/requestContext'
const FormDetailsOversight = ({ showPhotos, setShowPhotos, showMap, setShowMap, disableShowCheckInspection, showCheckInspector, setShowCheckInspector }) => {
	const { state, handleForm } = useContext(formContext)
	const { user } = useContext(authContext)
	const { getAllItemDealership, getAllItemHighway, postItemOversight } = useContext(requestContext)
	const [edit, setEdit] = useState(false)
	const {
		conc_nome,
		ped_venda_date,
		st_desc,
		st_cor_valor,
		name,
		rod_desc,
		lot_conc_desc,
		sisf_sinc_id,
		ped_venda_cod,
		ped_venda_concessionaria,
		ped_venda_rodovia,
		conc_cod,
		rod_cod,
		ped_venda_status
	} = state
	const [actual_details, setActualDetais] = useState({
		act_rod_desc: '',
		act_conc_nome: '',
		act_ped_venda_concessionaria: '',
		act_ped_venda_rodovia: '',
		act_lot_conc_desc: '',
	})
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		}
	}, [conc_cod])
	const handleEdit = () => {
		if (edit) {
			setEdit(false)
			handleForm({
				rod_desc: actual_details.act_rod_desc,
				conc_nome: actual_details.act_conc_nome,
				ped_venda_concessionaria: actual_details.act_ped_venda_concessionaria,
				ped_venda_rodovia: actual_details.act_ped_venda_rodovia,
				lot_conc_desc: actual_details.act_lot_conc_desc,
			})
			return setActualDetais({
				act_rod_desc: '',
				act_conc_nome: '',
				act_ped_venda_concessionaria: '',
				act_ped_venda_rodovia: '',
				act_lot_conc_desc: '',
			})
		}
		setEdit(!edit)
		handleForm({
			conc_cod: ped_venda_concessionaria,
			rod_cod: ped_venda_rodovia,
		})
		setActualDetais({
			act_conc_nome: conc_nome,
			act_ped_venda_concessionaria: ped_venda_concessionaria,
			act_ped_venda_rodovia: ped_venda_rodovia,
			act_rod_desc: rod_desc,
			act_lot_conc_desc: lot_conc_desc,
		})
	}
	const handleSubmit = () => {
		const confirm = window.confirm(`Você deseja mesmo fazer essas alterações na NC n° ${sisf_sinc_id || ped_venda_cod}?`)
		if (!confirm) {
			return
		}
		postItemOversight({
			id: ped_venda_cod,
			ped_venda_concessionaria: conc_cod,
			ped_venda_rodovia: rod_cod,
			sisf_sinc_id: sisf_sinc_id ? sisf_sinc_id : undefined,
			fisc_lat: 0,
			fisc_lng: 0,
		})
	}
	return (
		<div className="card-body">
			{edit && (
				<div className='row'>
					<div className="col-md-6 col-sm-12">
						<Dealership />
					</div>
					<div className="col-md-6 col-sm-12  mb-3">
						<SelectHighway />
					</div>
				</div>
			)}
			<div className="row">
				<div className="col-md-4 col-sm-8 mb-3">
					<span className="text-secondary p-0 m-0">Não Conformidade para: </span>{' '}
					{conc_nome}
				</div>
				<div className="col-md-4 col-sm-4 mb-3">
					<span className="text-secondary p-0 m-0">Lote:</span>{' '}
					{lot_conc_desc}
				</div>
				{name && (
					<div className="col-md-4 col-sm-4">
						<span className="text-secondary p-0 m-0">Aberta por: </span>{' '}
						{name}
					</div>
				)}
				{ped_venda_date ? (
					<div className="col-md-4 mb-3 col-sm-4">
						<span className="text-secondary p-0 m-0">No dia: </span>{' '}
						{formateDate(`${ped_venda_date} 00:00:00`)}
					</div>
				) : (
					<div className="col-md-4 col-sm-4 mb-3">
						<i className="fa fa-wifi" aria-hidden="true"></i>...
					</div>
				)}
				<div className="col-md-4 col-sm-4 mb-3">
					<span className="text-secondary p-0 mr-2">Status: </span>
					<span
						className="badge text-center text-light mr-2 p-2"
						style={{ backgroundColor: st_cor_valor }}
					>
						{st_desc}
					</span>
				</div>
				<div className="col-md-4 col-sm-12 mb-3">
					<span className="text-secondary p-0 m-0">Para rodivia: </span>{' '}
					{rod_desc}
				</div>
			</div>
			<div className="row">
				<div className="col-md-4 col-sm-12 mb-3">
					<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
						<input
							type="checkbox"
							className="custom-control-input"
							id="customSwitch1"
							checked={showPhotos}
							onChange={() => setShowPhotos(!showPhotos)}
						/>
						<label
							className="custom-control-label"
							htmlFor="customSwitch1"
						>
							Mostrar fotos
						</label>
					</div>
				</div>
				<div className="col-md-4 col-sm-12 mb-3">
					<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
						<input
							type="checkbox"
							className="custom-control-input"
							id="customSwitch2"
							checked={showMap}
							onChange={() =>
								setShowMap(!showMap)
							}
						/>
						<label
							className="custom-control-label"
							htmlFor="customSwitch2"
						>
							Mostrar Mapa
						</label>
					</div>
				</div>
				{user
					? user.set_nivel <= 2 && (
						<div className="col-md-4 col-sm-12 mb-3">
							<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
								<input
									type="checkbox"
									className="custom-control-input"
									id="customSwitch3"
									disabled={disableShowCheckInspection}
									checked={showCheckInspector}
									onChange={() =>
										setShowCheckInspector(!showCheckInspector)
									}
								/>
								<label
									className="custom-control-label"
									htmlFor="customSwitch3"
								>
									Mostrar Retornos
								</label>
							</div>
						</div>
					)
					: ''}
			</div>
			{user?.set_nivel <= 2 && ped_venda_status !== 4 && (
				edit ? (
					<button className='btn btn-info mr-2' onClick={handleSubmit}>
						<i className='fa fa-save'></i> Salvar
					</button>

				) : (
					<button className='btn btn-info mr-2' onClick={handleEdit}>
						<i className='fa fa-edit'></i> Editar Concessionaria ou Rodovia
					</button>
				)
			)}
			{edit && (
				<button className='btn btn-secondary' onClick={handleEdit}>
					<i className='fa fa-times-circle'></i> Cancelar
				</button>
			)}
		</div >
	)
}
export default FormDetailsOversight