import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { requestContext } from '../../../context/requestContext'
import MyMap from '../../../components/map'
import { formateDate } from '../../../services/dateHandless'
import { getQueryParams } from '../../../api'
import FormReport from '../../../components/form/report'
import { formContext } from '../../../context/formContext'
import { LOGO } from '../../../config/flavor'
import InfoWindowOversight from '../../../components/map/infoWindow/oversight'
import { renderToString } from 'react-dom/server'
const ReportMapByOversight = () => {
	const { getItemReportOversightMap,
		report,
		setReport,
		setHighway,
		getAllItemHighway,
		getAllItemDealership,
		getAllItemCardinalPoints,
		getAllItemService
	} = useContext(requestContext)
	const [position, setPosition] = useState({
		pv_loc_lat: '',
		pv_loc_lng: '',
	})
	const { state, handleForm } = useContext(formContext)
	const {
		conc_cod,
		ped_venda_status,
		ped_venda_responsavel,
		rod_cod,
		serv_cat_prazo_from,
		serv_cat_prazo_to,
		serv_cod,
		rod_km_ini_from,
		rod_km_ini_to,
		rod_sent_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		gp_cod,
	} = state
	const [markersService, setMarkersService] = useState(null)
	const [printer, setPrinter] = useState(null)
	const navigate = useNavigate()
	useEffect(() => {
		resetValues()
		setHighway()
		getAllItemHighway()
		getAllItemDealership()
		getAllItemCardinalPoints()
		getAllItemService(null, 1)
	}, [])
	useEffect(() => {
		if (printer) {
			window.print()
			setPrinter(false)
		}
	}, [printer])
	useEffect(() => {
		if (report?.data) {
			const { data } = report
			if (Array.isArray(data)) {
				const findPostion = data.find(el => el?.pv_loc_lat && el?.pv_loc_lng)
				if (!findPostion) {
					return
				}
				setPosition({
					lat: Number(findPostion?.pv_loc_lat),
					lng: Number(findPostion?.pv_loc_lng),
					content: 'Local abertura Não Conformidade',
				})

				const items = data.map(item => {
					const htmlString = renderToString(<InfoWindowOversight state={{
						conc_nome: item.conc_nome,
						pvs_obs: item.pvs_obs,
						serv_desc: item.serv_desc,
						serv_cat_desc: item.serv_cat_desc,
						serv_sub_cat_desc: item.serv_sub_cat_desc,
						st_cor_valor: item.st_cor_valor,
						st_desc: item.st_desc,
						pvs_prazo_fim: item.pvs_prazo_fim,
						pv_loc_km_ini: item.pv_loc_km_ini,
						pv_loc_km_init_mts: item.pv_loc_km_init_mts,
						pv_loc_km_fim: item.pv_loc_km_fim,
						pv_loc_km_fim_mts: item.pv_loc_km_fim_mts,
						rod_sent_desc: item.rod_sent_desc,
						pv_loc_obs: item.pv_loc_obs,
					}} />)
					return {
						lat: Number(item.pvs_lat || item.pv_loc_lat),
						lng: Number(item.pvs_lng || item.pv_loc_lng),
						icon_color: item.st_cor_valor,
						content: htmlString,
					}
				})
				if (items.length >= 0) {
					setMarkersService(items)
				}
			}
		} else {
			setReport(null)
			setMarkersService(null)
		}
	}, [report])
	const handleCancel = () => {
		navigate('/')
	}
	const handleSubmit = () => {
		setPrinter(!printer)
	}
	const resetValues = () => {
		window.scrollTo(0, 0)
		setReport(null)
		onSubmit()
		handleForm({
			ped_venda_concessionaria: '',
			ped_venda_status: '',
			ped_venda_responsavel: '',
			ped_venda_rodovia: '',
			ped_venda_date_from: '',
			ped_venda_date_to: '',
			pvs_servico: '',
			pvs_servico_cat: '',
			pvs_servico_sub_cat: '',
			pv_loc_km_ini_from: '',
			pv_loc_km_ini_to: '',
			pv_loc_sentido: '',
			gp_cod: '',
		})
	}
	const onSubmit = () => {
		window.scrollTo(0, 0)
		const whereFilters = {
			ped_venda_concessionaria: conc_cod || null,
			ped_venda_status: ped_venda_status || null,
			ped_venda_responsavel: ped_venda_responsavel || null,
			ped_venda_rodovia: rod_cod || null,
			ped_venda_date_from: serv_cat_prazo_from || null,
			ped_venda_date_to: serv_cat_prazo_to || null,
			pvs_servico: serv_cod || null,
			pvs_servico_cat: serv_cat_cod || null,
			pvs_servico_sub_cat: serv_sub_cat_cod || null,
			pv_loc_km_ini_from: rod_km_ini_from || null,
			pv_loc_km_ini_to: rod_km_ini_to || null,
			pv_loc_sentido: rod_sent_cod || null,
			gp_cod: gp_cod || null,
		}
		const queryFilter = getQueryParams(whereFilters)
		getItemReportOversightMap(queryFilter)
	}
	return (
		<>
			{!printer && (
				<ContentHeader title={'Relatório por ocorrência'} />
			)}
			<div className="content">
				{!printer && (
					<div className="card">
						<div className="card-body">
							<FormReport onSubmit={onSubmit} />
						</div>
					</div>
				)}
				<div className="card">
					{!printer && report?.data && (
						<div className="card-header">
							<h3 className="card-title d-md-block d-none">Ocorrências apresentadas no Mapa</h3>
							<div className="card-tools">
								<Link
									to="#"
									className="btn btn-tool btn-sm"
									onClick={onSubmit}
								>
									<i className="fa fa-refresh"></i> Atualizar lista
								</Link>
							</div>
						</div>
					)}
					{printer && (
						<div className="card-header">
							<div className="row">
								<div className="col-6">
									<img src={LOGO} style={{ maxWidth: 250 }} alt="image_logo" />
								</div>
								<div className="col-6 mt-3">
									Relatório  de conservação
									<div className="text-secondary">
										Gerado no dia {formateDate()}
									</div>
								</div>
							</div>
						</div>
					)}
					<Spinner direction={'center'} label="carregando" />
					{position && markersService && (
						<MyMap
							zoom={5}
							position={position}
							markersPhotos={markersService}
						/>
					)}
				</div>
			</div>
			{!printer && report?.data && (
				<div className="card sticky-top">
					<div className="card-body">
						<button className="btn btn-info mr-3" onClick={handleSubmit}>
							Gerar PDF <i className="fa fa-download"></i>
						</button>

						<div className="btn btn-secondary" onClick={handleCancel}>
							Cancelar <i className="fa fa-times-circle"></i>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
export default ReportMapByOversight
