import React, { useContext, useEffect, useRef, useState } from 'react'
import ContentHeader from '../../../../components/contentHeader'
import FormPavementsIndicators from '../../../../components/form/pavementIndicators'
import { formContext } from '../../../../context/formContext'
import { convertToMySQLDate, formateValidatedDate } from '../../../../services/dateHandless'
import { convertToTitleCase, extractNumbers, replaceWords, searchSomeWordInString, splitString } from '../../../../services/stringHandless'
import FormPavementIndicatorsSection from '../../../../components/form/pavementIndicatorsIndexPerSection'
import TableSegments from '../../../../components/table/pavement/seguimentos'
import { Link, useNavigate } from 'react-router-dom'
import { handleError, handleSuccess, handleWaring } from '../../../../services/isValid'
import { requestContext } from '../../../../context/requestContext'
import { generateBse64, getExt, importExcelToArray } from '../../../../services/files'
import { allowedXlsExtensions, namesCache, namesScreens } from '../../../../constants'
import { cleanArray, filter, find } from '../../../../services/arrayHandless'
import { scrollToElement } from '../../../../services/scroll'
import Spinner from '../../../../components/spinner'
import { recoverCache, storageCache } from '../../../../services/cacheManager'
import { randomNumber } from '../../../../services/randomId'
import { getPropsDimissions } from '../../../../services/getPropsElNode'
import CardHeader from '../cardHeader'
import CardNavp from '../cardNavp'
import { controllCrosInfo } from '../../../../services/paviment'
import FormPavementShowTables from '../../../../components/form/pavementShowTables'
import TableMonitoring from '../../../../components/table/pavement/monitoring'
const NewPavementIndicators = () => {
	const { state, handleForm } = useContext(formContext)
	const {
		setLoading,
		loading,
		setInfoRequest,
		data,
		complements,
		highway,
		type_constructions,
		cardinal_points,
		limithigway,
		userLimithigway,
		getAllItemDealership,
		getAllItemHighway,
		getAllItemCardinalPoints,
		postItemPavementSegments,
		getItemSpreesheetPavementSegments,
		getAllItemTypeCoating,
		getAllItemEarchWorkSection
	} = useContext(requestContext)
	const [items, setItems] = useState(null)
	const [itemsNavp, setItemsNavp] = useState(null)
	const [payloadNavp, setPayloadNavp] = useState(null)
	const [monitoring, setMonitoring] = useState(null)
	const [crossMonitoring, setCrossMonitorin] = useState(null)
	const [widthList, setWidhtList] = useState(214)
	const [fileSheetCSP, setFileSheetCSP] = useState({
		fileName: '',
		base64: '',
	})
	const [fileSheetRT, setFileSheetRT] = useState({
		fileName: '',
		base64: '',
	})
	const [seeItems, setSeeItems] = useState(true)
	const [seeMonitoring, setSeeMonitoring] = useState(false)
	useEffect(() => {
		if (!seeMonitoring) {
			return setSeeItems(true)
		}
	}, [seeMonitoring])
	const [showInputFileSheetCSP, setShowInputFileSheetCSP] = useState(false)
	const [showInputFileSheetRT, setShowInputFileSheetRT] = useState(true)
	const [filesOnZip, setFilesOnZip] = useState(null)
	const inputSheetCSP = useRef(null)
	const inputSheetRT = useRef(null)
	const refList = useRef(null)
	const navigate = useNavigate()
	const { lot_conc_desc, conc_nome, rod_km } = state
	useEffect(() => {
		getAllItemDealership()
		getAllItemHighway()
		getAllItemCardinalPoints()
		getAllItemTypeCoating()
		getAllItemEarchWorkSection()
	}, [])
	useEffect(() => {
		const getWidhtRefList = () => {
			const { width } = getPropsDimissions(refList?.current?.id)
			if (width) setWidhtList(width)
		}
		getWidhtRefList()
	}, [refList])
	useEffect(() => {
		if (conc_nome && lot_conc_desc) {
			handleForm({
				fisc_obs: `Lote ${lot_conc_desc} - ${conc_nome} - ${convertToTitleCase('LEVANTAMENTO DE ÍNDICES DO PAVIMENTO CONSOLIDADO DO ANO')} ${String(formateValidatedDate()).substring(0, 4)}`
			})
		}
	}, [conc_nome, lot_conc_desc])
	useEffect(() => {
		const controllShowInputRT = () => {
			if (items?.length && cardinal_points?.data?.length && limithigway?.data && userLimithigway?.data) {
				return setShowInputFileSheetRT(true)
			}
			setShowInputFileSheetRT(false)
		}
		controllShowInputRT()
	}, [items, cardinal_points, limithigway, userLimithigway])
	useEffect(() => {
		const controllCrossMonitoring = () => {
			if (monitoring && items) {
				setLoading(true)
				const tempCrossInfo = controllCrosInfo({ items, monitoring })
				if (tempCrossInfo?.length) {
					setCrossMonitorin(tempCrossInfo)
				} else {
					setCrossMonitorin(monitoring)
				}
				setLoading(false)
			}
		}
		controllCrossMonitoring()
	}, [monitoring, items])
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (!payloadNavp?.length || !crossMonitoring?.length || !items?.length) {
			return setInfoRequest(handleWaring('Você precisa inserir os seguimentos e o monitoramento'))
		}
		const {
			lipc_conc_cod,
			conc_cod,
			lipc_obs,
			lipc_tot_seg,
		} = state
		window.scrollTo(0, 0)
		const payload = {
			lipc_tot_seg: lipc_tot_seg,
			lipc_conc_cod: lipc_conc_cod || conc_cod,
			lipc_obs: lipc_obs,
			nota_avaliacao_pavimento: payloadNavp.map(item => {
				const { tip_ind_pav_cod, ...rest } = item
				return {
					...rest,
					navp_tip_cod: tip_ind_pav_cod
				}
			}),
			levantamento_indice_pavimento_consolidado: items.filter(item => item.localizacao_levantamento_indice_pavimento_consolidado.loc_lipc_rod_cod),
			lipc_path_file: fileSheetCSP?.base64,
			mon_items: crossMonitoring,
			mon_attach_path: fileSheetRT.base64
		}
		postItemPavementSegments(payload)
	}
	const handleCancel = () => {
		handleForm({
			lot_conc_desc: '',
			conc_nome: '',
			fisc_obs: '',
			rod_cod: '',
			rod_desc: '',
			conc_cod: '',
		})
		navigate(namesScreens.PAVEMENT_INDICATOR)
	}
	const handleGetSpreedSheet = async () => {
		if (loading) {
			return
		}
		const request_spreadshet = recoverCache(namesCache.REQUEST_SPREDSHEET_MONITORING_PAVAMENT)
		if (request_spreadshet) {
			const { filesOnZip: files } = request_spreadshet
			setFilesOnZip(files)
			return setShowInputFileSheetCSP(true)
		}
		const confirm = window.confirm('Você já tem a planilha modelo baixada no seu computador?')
		if (confirm) {
			if (showInputFileSheetCSP) {
				return handleFile()
			}
			return setShowInputFileSheetCSP(true)
		}
		const { success: response, files } = await getItemSpreesheetPavementSegments()
		if (response && files?.length) {
			setFilesOnZip(files)
			setInfoRequest(handleSuccess('Planilha Modelo baixado com sucesso'))
			storageCache(namesCache.REQUEST_SPREDSHEET_MONITORING_PAVAMENT, {
				sucess: response,
				filesOnZip: files
			})
		}
		setShowInputFileSheetCSP(response)
	}
	const handleFile = ({
		type_sheet
	}) => {
		if (inputSheetCSP.current && type_sheet === 'CSP') {
			return inputSheetCSP.current.click()
		}
		if (inputSheetRT.current && type_sheet === 'RT') {
			return inputSheetRT.current.click()
		}
	}
	const findGPAndTip = ({
		gp_ind_pav_desc, tip_ind_pav_desc,
	}) => {
		try {
			const { data: findTip } = find(complements?.data, 'tip_ind_pav_desc', convertToTitleCase(tip_ind_pav_desc))
			const { data: findGp } = find(type_constructions?.data, 'gp_ind_pav_desc', convertToTitleCase(gp_ind_pav_desc))
			return {
				gp_ind_pav_cod: findGp?.gp_ind_pav_cod,
				tip_ind_pav_cod: findTip?.tip_ind_pav_cod
			}
		} catch (error) {
			console.log(error)
			return {
				gp_ind_pav_cod: '',
				tip_ind_pav_cod: ''
			}
		}
	}
	const findCoatAndEarchWork = ({
		sctrr_desc,
		tip_reves_desc,
	}) => {
		try {
			if (!tip_reves_desc && !sctrr_desc) {
				return {
					tip_reves_cod: '',
					sctrr_cod: '',
				}
			}
			const { data: findEarchWork } = find(limithigway?.data, 'sctrr_desc', convertToTitleCase(sctrr_desc))
			const { data: findCoat } = find(userLimithigway?.data, 'tip_reves_desc', convertToTitleCase(tip_reves_desc))
			if (!findEarchWork || !findCoat) {
				return {
					tip_reves_cod: '',
					sctrr_cod: '',
				}
			}
			const { sctrr_cod } = findEarchWork
			const { tip_reves_cod } = findCoat
			return {
				sctrr_cod,
				tip_reves_cod,
			}
		} catch (error) {
			console.log(error)
			return {
				tip_reves_cod: '',
				sctrr_cod: '',
			}
		}
	}
	const prepareDataToCreateSgments = ({ items, highway_by_dealership, temp_rod_conc }) => {
		if (!items.length) {
			return []
		}
		const items_levantamento_indice_pavimento_consolidado = []
		for (const item of items) {
			try {
				if (item?.length !== 13) {
					continue
				}
				const temp_rod_km = item[0]
				const temp_sent_desc = item[1]
				const loc_lipc_obs = `Faixa 0${item[2]}`
				const { firstPart: loc_lipc_km_init = 0, secondPart: loc_lipc_km_init_mts = 0 } = splitString(String(item[3]), '.')
				const { firstPart: loc_lipc_km_fim = 0, secondPart: loc_lipc_km_fim_mts = 0 } = splitString(String(item[4]), '.')
				const { data: highway_data } = find(highway_by_dealership, 'rod_km', temp_rod_km)
				let localizacao_levantamento_indice_pavimento_consolidado
				if (!highway_data) {
					localizacao_levantamento_indice_pavimento_consolidado = {
						rod_desc: false,
						rod_km: temp_rod_km,
						loc_lipc_rod_cod: 0,
						loc_lipc_rod_conc: temp_rod_conc,
						loc_lipc_rod_sent_cod: null,
						loc_lipc_km_init: Number(loc_lipc_km_init),
						loc_lipc_km_init_mts: Number(loc_lipc_km_init_mts),
						loc_lipc_km_fim: Number(loc_lipc_km_fim),
						loc_lipc_km_fim_mts: Number(loc_lipc_km_fim_mts),
						loc_lipc_obs,
					}
				} else {
					const {
						rod_cod: loc_lipc_rod_cod,
						rod_desc,
						rod_km,
						rod_concessionaria: loc_lipc_rod_conc
					} = highway_data
					const { data: cardinal_points_data } = filter(cardinal_points?.data, 'rod_sent_rodovia', loc_lipc_rod_cod)
					let temp_loc_lipc_rod_sent_cod = 0, temp_rod_sent_desc = temp_sent_desc
					if (cardinal_points_data?.length) {
						temp_loc_lipc_rod_sent_cod = cardinal_points_data[0].rod_sent_cod
						temp_rod_sent_desc = cardinal_points_data[0].rod_sent_desc
					}
					const { data: findRodSent } = find(cardinal_points_data, 'rod_sent_desc', convertToTitleCase(temp_sent_desc))
					if (findRodSent) {
						const { rod_sent_cod, rod_sent_desc } = findRodSent
						temp_loc_lipc_rod_sent_cod = rod_sent_cod
						temp_rod_sent_desc = rod_sent_desc
					}
					localizacao_levantamento_indice_pavimento_consolidado = {
						rod_desc,
						rod_km,
						rod_sent_desc: temp_rod_sent_desc,
						loc_lipc_rod_cod,
						loc_lipc_rod_conc,
						loc_lipc_rod_sent_cod: temp_loc_lipc_rod_sent_cod,
						loc_lipc_km_init: Number(loc_lipc_km_init),
						loc_lipc_km_init_mts: Number(loc_lipc_km_init_mts),
						loc_lipc_km_fim: Number(loc_lipc_km_fim),
						loc_lipc_km_fim_mts: Number(loc_lipc_km_fim_mts),
						loc_lipc_obs,
					}
				}

				let tempfindGPAndTip = findGPAndTip({
					gp_ind_pav_desc: 'Irregularidade Longitudinal',
					tip_ind_pav_desc: 'Iri (Mm/M)'
				})
				let ipc_val = 0, ipc_perc = 0, ipc_date
				if (searchSomeWordInString(String(item[5]), ['%'])) {
					ipc_val = 0
					ipc_perc = parseFloat(item[5])
				}
				else {
					if (searchSomeWordInString(String(item[5]), ['-'])) {
						ipc_val = 0
						ipc_perc = 0
					} else {
						ipc_val = parseFloat(item[5])
						ipc_perc = 0
					}
				}
				ipc_date = item[7]
				const ipc_type_1 = {
					ipc_tip_cod: tempfindGPAndTip?.tip_ind_pav_cod,
					ipc_gp_ind_pav_cod: tempfindGPAndTip?.gp_ind_pav_cod,
					ipc_val: isNaN(ipc_val) ? 0.0 : ipc_val,
					ipc_perc: isNaN(ipc_perc) ? 0.0 : ipc_perc,
					ipc_date,
					tip_ind_pav_desc: 'Iri (Mm/M)'
				}
				tempfindGPAndTip = findGPAndTip({
					gp_ind_pav_desc: 'Irregularidade Longitudinal',
					tip_ind_pav_desc: 'Qi (Cont./Km)'
				})
				if (searchSomeWordInString(String(item[6]), ['%'])) {
					ipc_val = 0
					ipc_perc = parseFloat(item[6])
				} else {
					if (searchSomeWordInString(String(item[6]), ['-'])) {
						ipc_val = 0
						ipc_perc = 0
					}
					ipc_val = parseFloat(item[6])
					ipc_perc = 0
				}
				ipc_date = item[7]
				const ipc_type_2 = {
					ipc_tip_cod: tempfindGPAndTip?.tip_ind_pav_cod,
					ipc_gp_ind_pav_cod: tempfindGPAndTip?.gp_ind_pav_cod,
					ipc_val: isNaN(ipc_val) ? 0.0 : ipc_val,
					ipc_perc: isNaN(ipc_perc) ? 0.0 : ipc_perc,
					ipc_date,
					tip_ind_pav_desc: 'Qi (Cont./Km)'
				}
				tempfindGPAndTip = findGPAndTip({
					gp_ind_pav_desc: 'Superfície',
					tip_ind_pav_desc: 'Igg'
				})
				if (searchSomeWordInString(String(item[8]), ['%'])) {
					ipc_val = 0
					ipc_perc = parseFloat(item[8])
				} else {
					if (searchSomeWordInString(String(item[9]), ['-'])) {
						ipc_val = 0
						ipc_perc = 0
					}
					ipc_val = parseFloat(item[8])
					ipc_perc = 0
				}
				ipc_date = item[9]
				const ipc_type_3 = {
					ipc_tip_cod: tempfindGPAndTip?.tip_ind_pav_cod,
					ipc_gp_ind_pav_cod: tempfindGPAndTip?.gp_ind_pav_cod,
					ipc_val: isNaN(ipc_val) ? 0.0 : ipc_val,
					ipc_perc: isNaN(ipc_perc) ? 0.0 : ipc_perc,
					ipc_date,
					tip_ind_pav_desc: 'Igg'
				}

				const indice_pavimento_consolidado = [
					{ ...ipc_type_1 },
					{ ...ipc_type_2 },
					{ ...ipc_type_3 },
				]
				const f = ipc_type_1.ipc_val
				const i = ipc_type_3.ipc_val
				const f_result = (f <= 3.46 && i <= 40) ? 1 : 0
				const m_result = Number(String(item[4]).replace(',', '.')) - Number(String(item[3]).replace(',', '.'))
				const id = randomNumber()
				items_levantamento_indice_pavimento_consolidado.push({
					lipc_id: id,
					localizacao_levantamento_indice_pavimento_consolidado,
					indice_pavimento_consolidado,
					l: f_result,
					m: m_result,
				})
			} catch (error) {
				console.log(error)
				continue
			}
		}
		return items_levantamento_indice_pavimento_consolidado
	}
	const prepareDataToCreateNavp = ({ nota_avaliacao_pavimento }) => {
		const stringsToRemove = ['total atendido ', ':']
		const desc_irr = String(nota_avaliacao_pavimento[5][2]).replace(new RegExp(stringsToRemove.join('|'), 'g'), '')
		const desc_igg = String(nota_avaliacao_pavimento[5][4]).replace(new RegExp(stringsToRemove.join('|'), 'g'), '')
		const tiposIndices = []
		if (desc_irr) {
			tiposIndices.push(desc_irr)
		}
		if (desc_igg) {
			tiposIndices.push(desc_igg)
		}
		const cleanComplemnts = complements.data.map(item => {
			const { tip_ind_pav_desc, ...rest } = item
			const cleanString = replaceWords(tip_ind_pav_desc, ['(mm/m)', '(cont./km)', ' '])
			return {
				tip_ind_pav_desc: cleanString.replace('()', ''),
				...rest,
			}
		})
		const finalData = []
		tiposIndices.forEach(item => {
			const { data: findData } = find(cleanComplemnts, 'tip_ind_pav_desc', convertToTitleCase(item))
			if (findData) {
				finalData.push({ ...findData })
			}
		})
		return finalData
	}
	const prepareDataToCreateMon = async ({ xlsrows, name, files }) => {
		const cabecalho = xlsrows[2]
		const cabecalho_aobs = cabecalho.slice(11, 37)
		const mon_items = xlsrows.slice(3, xlsrows.length)
		if (!mon_items?.length) {
			return setInfoRequest(handleWaring('Não há itens nessa planilha'))
		}
		const temp_lot_conc_desc = xlsrows[3][0]
		const dealership = data?.data.find(item => String(item.lot_conc_desc) === String(temp_lot_conc_desc))
		const { conc_cod: temp_rod_conc } = dealership
		const { data: highway_by_dealership } = filter(highway?.data, 'rod_concessionaria', temp_rod_conc)
		const finalData = []
		for (const item of mon_items) {
			if (item.length !== 44) {
				continue
			}
			const temp_rod_km = item[1]
			const loc_lipc_obs = `${cabecalho[2]}: ${item[2]}, ${cabecalho[4]}: ${item[4]}, ${cabecalho[7]}: ${item[7]}`
			const temp_sent_desc = item[3]
			const sctrr_desc = item[9]
			const tip_reves_desc = item[10]
			const { sctrr_cod, tip_reves_cod } = findCoatAndEarchWork({
				sctrr_desc,
				tip_reves_desc
			})
			if (!sctrr_cod || !tip_reves_cod) {
				continue
			}
			const { firstPart: loc_lipc_km_init, secondPart: loc_lipc_km_init_mts } = splitString(String(item[8]), '.')
			const ipc_date = convertToMySQLDate(item[38])
			const lat = item[41]
			const lng = item[42]
			const avaliacaoes = item.slice(11, 37)
			const result_avaliacoes = []
			for (let index = 0; index < avaliacaoes.length; index++) {
				const value = avaliacaoes[index]
				let ipc_val = 0
				if (value === 'X') {
					ipc_val = 1
				}
				if (typeof value === 'number') {
					ipc_val = Number(value)
				}
				if (typeof value === 'undefined') {
					ipc_val = 0
				}
				const { gp_ind_pav_cod, tip_ind_pav_cod } = findGPAndTip({
					gp_ind_pav_desc: 'Avaliação Objetiva Da Superfície',
					tip_ind_pav_desc: convertToTitleCase(cabecalho_aobs[index])
				})
				result_avaliacoes.push({
					tip_ind_pav_desc: cabecalho_aobs[index],
					ipc_val: ipc_val,
					gp_ind_pav_cod,
					tip_ind_pav_cod,
					ipc_date,
					ipc_cod: randomNumber(),
				})
			}
			if (!dealership) {
				continue
			}
			const { data: highway_data } = find(highway_by_dealership, 'rod_km', temp_rod_km)
			if (!highway_data) {
				continue
			}
			const { rod_cod, rod_km: mon_rod_km } = highway_data
			const { data: cardinal_points_data } = filter(cardinal_points?.data, 'rod_sent_rodovia', rod_cod)
			const { data: findRodSent } = find(cardinal_points_data, 'rod_sent_desc', convertToTitleCase(temp_sent_desc))
			if (!findRodSent) {
				continue
			}
			const { rod_sent_cod: loc_lipc_rod_sent_cod, rod_sent_desc: mon_rod_sent_desc } = findRodSent
			const localizacao_levantamento_indice_pavimento_consolidado = {
				rod_km: mon_rod_km,
				rod_sent_desc: mon_rod_sent_desc,
				loc_lipc_rod_cod: rod_cod,
				loc_lipc_rod_conc: temp_rod_conc,
				loc_lipc_rod_sent_cod: loc_lipc_rod_sent_cod,
				loc_lipc_km_init: Number(loc_lipc_km_init),
				loc_lipc_km_init_mts: Number(loc_lipc_km_init_mts),
				loc_lipc_km_fim: Number(loc_lipc_km_init),
				loc_lipc_km_fim_mts: Number(loc_lipc_km_init_mts),
				loc_lipc_obs,
				mon_pav_lat: lat,
				mon_pav_lng: lng,
			}
			const avaliacaoes_objetivas_superficie = result_avaliacoes
			const id = randomNumber()
			finalData.push({
				mon_id: id,
				localizacao_levantamento_indice_pavimento_consolidado,
				avaliacaoes_objetivas_superficie,
				sctrr_desc,
				tip_reves_desc,
				mon_pav_sectrr_cod: sctrr_cod,
				mon_pav_tip_reves_cod: tip_reves_cod,
				ipc_date,
			})
		}
		if (finalData.length) {
			const filegenerateBse64 = await generateBse64(files[0])
			setFileSheetRT({
				fileName: name,
				base64: filegenerateBse64
			})
			setMonitoring(finalData)
		}
		setLoading(false)
	}
	const readXlsSegments = (rows) => {
		try {
			const services = rows.slice(0, rows.length)
			const clean_rows = cleanArray(services)
			const segments = [...clean_rows]
			const temp_nota_avaliacao_pavimento = segments.slice(0, 8)
			const lot_desc = extractNumbers(segments[0][0])
			const temp_lot_conc_desc = String(lot_desc).substring(0, 2)
			const dealership = data?.data.find(item => Number(item.lot_conc_desc) === Number(+temp_lot_conc_desc))
			if (!dealership) {
				return {
					levantamento_indice_pavimento_consolidado: [],
					lipc_conc_cod: null,
					lipc_obs: '',
					lipc_tot_seg: 0,
					nota_avaliacao_pavimento: [],
				}
			}
			const { conc_cod: temp_rod_conc } = dealership
			const total_length = segments.length
			const temp_levantamento_indice_pavimento_consolidado = segments.slice(9, total_length)
			const { data: highway_by_dealership } = filter(highway?.data, 'rod_concessionaria', temp_rod_conc)
			const items_levantamento_indice_pavimento_consolidado = prepareDataToCreateSgments({
				items: temp_levantamento_indice_pavimento_consolidado,
				highway_by_dealership,
				temp_rod_conc
			})
			const lipc_conc_cod = items_levantamento_indice_pavimento_consolidado[0].localizacao_levantamento_indice_pavimento_consolidado.loc_lipc_rod_conc
			const lipc_tot_seg = items_levantamento_indice_pavimento_consolidado.filter(item => item.localizacao_levantamento_indice_pavimento_consolidado.rod_desc).length
			const nota_avaliacao_pavimento = prepareDataToCreateNavp({ nota_avaliacao_pavimento: temp_nota_avaliacao_pavimento })
			const xls = {
				total_length,
				levantamento_indice_pavimento_consolidado: items_levantamento_indice_pavimento_consolidado.sort(function (x, y) {
					return (x.localizacao_levantamento_indice_pavimento_consolidado.rod_desc === y.localizacao_levantamento_indice_pavimento_consolidado.rod_desc) ? 0 : x.localizacao_levantamento_indice_pavimento_consolidado.rod_desc ? -1 : 1
				}),
				lipc_conc_cod,
				lipc_obs: convertToTitleCase(segments[1][0]),
				lipc_tot_seg: lipc_tot_seg,
				nota_avaliacao_pavimento: nota_avaliacao_pavimento,
			}
			const { data: findConc } = find(data?.data, 'conc_cod', xls.lipc_conc_cod)
			const lassLIPC = xls.levantamento_indice_pavimento_consolidado.filter(item => item.localizacao_levantamento_indice_pavimento_consolidado.loc_lipc_rod_cod !== 0)[0].localizacao_levantamento_indice_pavimento_consolidado
			if (findConc) {
				handleForm({
					...findConc,
				})
			}
			handleForm({
				conc_cod: xls.lipc_conc_cod,
				lipc_obs: xls.lipc_obs,
				lipc_tot_seg: xls.lipc_tot_seg,
				rod_cod: lassLIPC.loc_lipc_rod_cod,
				rod_desc: lassLIPC.rod_desc,
				rod_km: lassLIPC.rod_km,
			})
			return xls
		} catch (error) {
			console.log(error)
			return {
				total_length: 0,
				levantamento_indice_pavimento_consolidado: [],
				lipc_conc_cod: null,
				lipc_obs: '',
				lipc_tot_seg: 0,
				nota_avaliacao_pavimento: [],
			}
		}
	}
	const onChangeFile = async ({ target }) => {
		setLoading(true)
		const { files } = target
		if (files.length === 0) {
			return setFileSheetCSP({
				fileName: '',
				base64: '',
			})
		}
		if (files.length > 1) {
			setFileSheetCSP({
				base64: '',
				fileName: ''
			})
			return setInfoRequest(handleWaring('Você só pode selecionar uma planilha'))
		}
		const { name } = files[0]
		const fileExtension = String(getExt(name)).toLowerCase()
		if (!allowedXlsExtensions.includes(fileExtension)) {
			return setInfoRequest(handleWaring('O arquivo selecionado não é uma planilha'))
		}
		const xlsrows = await importExcelToArray(files[0])
		if (searchSomeWordInString(name, ['CSP'])) {
			return readSheetCSP({
				xlsrows, files, name
			})
		}
		if (searchSomeWordInString(name, ['RT'])) {
			return prepareDataToCreateMon({
				xlsrows, files, name
			})
		}
		return setInfoRequest(handleWaring('O arquivo selecionado não tem em seu nome o sufixo CSP ou RT'))
	}
	const readSheetCSP = async ({ xlsrows, files, name }) => {
		try {
			const { levantamento_indice_pavimento_consolidado, nota_avaliacao_pavimento, total_length, lipc_tot_seg: add_lipc_tot_seg } = readXlsSegments(xlsrows)
			if (!levantamento_indice_pavimento_consolidado.length) {
				setLoading(false)
				setFileSheetCSP({
					base64: '',
					fileName: '',
				})
				return setInfoRequest(handleWaring('Planilha preenchida incorretamente ou não há seguimentos inseridos nela'))
			}
			setLoading(false)
			setInfoRequest(handleSuccess(`Planilha selecionada com sucesso. \n Foram adicionados ${add_lipc_tot_seg} seguimentos de ${total_length}`))
			setItems(levantamento_indice_pavimento_consolidado)
			setItemsNavp(nota_avaliacao_pavimento)
			const filegenerateBse64 = await generateBse64(files[0])
			setFileSheetCSP({
				fileName: name,
				base64: filegenerateBse64
			})
			return scrollToElement(refList.current)
		} catch (error) {
			setInfoRequest(handleError(String(error)))
		}
	}
	return (
		<>
			<ContentHeader title={'Novo Monitoramento de Pavimento'} />
			<Spinner direction={'center'} label={'Carregando...'} />
			<form onSubmit={onSubmit} className='p-3'>
				<div className="card card-info">
					<div className="card-header">
						<CardHeader />
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className='card-body'>
						<div className='row'>
							{!fileSheetCSP.fileName && Array.isArray(complements?.data)
								&& Array.isArray(highway?.data)
								&& Array.isArray(cardinal_points?.data)
								&& Array.isArray(data?.data) && (
									<div className="col-md-4 col-sm-12">
										{filesOnZip?.length ? (
											<div className='text-secondary'>
												<p>Você deve anexar as seguintes planilhas:</p>
												{filesOnZip.map(item => (
													<p key={item}>
														{item}
													</p>
												))}
											</div>
										) : (
											<Link to="#" onClick={handleGetSpreedSheet} className="text-dark">
												Deseja usar uma planilha modelo para inserir os seguimentos e então usar essa planilha? Fazer isso agiliza seu trabalho 😁{' '}
												{!loading && (
													<i className="fa fa-download"></i>
												)} <Spinner direction={'center'} label={'carregando'} />
											</Link>
										)}
									</div>
								)}
							{showInputFileSheetCSP &&
								(
									<div className="col-md-4 col-sm-6 mb-3">
										<div className={fileSheetCSP?.fileName ? 'btn btn-success' : "btn btn-info"} onClick={() => handleFile({ type_sheet: 'CSP' })}>
											{fileSheetCSP.fileName ? (
												<>
													<span className='ml-2'>Anexado {fileSheetCSP.fileName}</span>
													<i className="fa fa-check text-light" aria-hidden="true"></i>
												</>
											) : `Anexar L${lot_conc_desc || '0'} CSP - IndicadoresPavimento`}
										</div>
									</div>
								)}
							{showInputFileSheetRT &&
								(
									<div className="col-md-4 col-sm-6 mb-3">
										<div className={fileSheetRT.fileName ? 'btn btn-success' : 'btn btn-info'} onClick={() => handleFile({
											type_sheet: 'RT'
										})}>

											{fileSheetRT.fileName ? (
												<>
													<span className='ml-2'>Anexado {fileSheetRT.fileName}</span>
													<i className="fa fa-check text-light" aria-hidden="true"></i>
												</>
											) : `Anexar RT-${rod_km || 'SP'}-486.510-628-P15-006-R0 (IGG)`}
										</div>
									</div>
								)}
							<div className='col-12'>
								<input
									className="d-none"
									type="file"
									onChange={onChangeFile}
									ref={inputSheetCSP}
								/>
							</div>
							<div className='col-12'>
								<input
									className="d-none"
									type="file"
									onChange={onChangeFile}
									ref={inputSheetRT}
								/>
							</div>
						</div>
					</div>
					<div className="card-body">
						<FormPavementsIndicators />
					</div>
				</div>
				<CardNavp
					nota_avaliacao_pavimento={itemsNavp}
					segmentsData={items}
					setPayloadNavp={setPayloadNavp}
				/>
				<div className="card card-info">
					<div className="card-header">
						<h3 className="card-title text-capitalize">Informações sobre o trecho</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<FormPavementIndicatorsSection items={items} setItems={setItems} />
					</div>
				</div>
				<div className="card card-info">
					<div className="card-header">
						<h3 className="card-title text-capitalize">
							{seeItems ? 'Tabela de seguimentos' : 'Tabela de monitoramento'}
						</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div ref={refList} className="card-body p-0 m-0" id='refList'>
						<FormPavementShowTables
							seeItems={seeItems}
							setSeeItems={setSeeItems}
							seeMonitoring={seeMonitoring}
							setSeeMonitoring={setSeeMonitoring}
						/>
						{seeItems ? (
							<TableSegments data={items} crossMonitoring={crossMonitoring} widthList={widthList} setData={setItems} />
						) : ''}
						{seeMonitoring ? (
							<TableMonitoring items={crossMonitoring} widthList={widthList} />
						) : ''}
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<button className="btn btn-info mr-3">
							Salvar <i className="fa fa-save"></i>
						</button>
						<div className="btn btn-secondary" onClick={handleCancel}>
							Cancelar <i className="fa fa-times-circle"></i>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}
export default NewPavementIndicators