import React, { useContext, useEffect, useState } from "react"
import '../../../services/bootstrapjs/bootstrap.min.js'
import "./style.css"
import { PhotoSection } from "../../../components/newOversight/PhotoSection/index.js"
import { Menu } from "../../../components/newOversight/Menu/index.js"
import { LocalizationSection } from "../../../components/newOversight/LocalizationSection/index.js"
import { KmSection } from "../../../components/newOversight/KmSection/index.js"
import { ServiceSection } from "../../../components/newOversight/ServiceSection/index.js"
import { ResumeSection } from "../../../components/newOversight/ResumeSection/index.js"
import { MenuBottom } from "../../../components/newOversight/MenuBottom/index.js"
import { formContext } from "../../../context/formContext/index.js"
import { handleWaring, isValid } from "../../../services/isValid/index.js"
import { requestContext } from "../../../context/requestContext/index.js"
import { addDate, isBeforeDate } from "../../../services/dateHandless/index.js"
import { filterAndRemoveRepeated } from "../../../services/arrayHandless/index.js"
import returnPosition from "../../../services/geolocation/index.js"
import { useNavigate } from "react-router-dom";

const OverSightNew = () => {

    const {
		setInfoRequest,
		postItemOversight,
		deadline,
		getAllItemDeadline
	} = useContext(requestContext)
    const {
		bases64,
		state,
		handleForm } = useContext(formContext)
    const {
        conc_cod,
        rod_cod,
        pv_servico,
        lot_conc_cod,
        serv_sub_cat_cod,
        serv_desc,
        serv_cod,
        serv_cat_cod,
        serv_sub_cat_desc,
        serv_cat_prazo,
        praz_dias,
        rod_sent_cod,
        rod_km_ini,
        rod_km_ini_mt,
        rod_km_fim,
        rod_km_fim_mt,
        pv_loc_obs,
        rod_sent_desc,
        serv_cat_desc,
     } = state

	const navigate = useNavigate(); 
    const [activeMenu, setActiveMenu] = useState(1)  

    const pressBack = () => {
        setActiveMenu(activeMenu-1)
    }

    const pressNext = () => {
        setActiveMenu(activeMenu+1)
    }

	useEffect(() => {
        getAllItemDeadline(lot_conc_cod, serv_sub_cat_cod)
    }, [serv_sub_cat_cod, lot_conc_cod])

	useEffect(() => {
		if (deadline?.total > 0 && serv_sub_cat_cod && lot_conc_cod) {
			try {
				const { data: deadline_data } = deadline
				const findDeadline = deadline_data.find(item => item.serv_sub_cat_cod === serv_sub_cat_cod && item.conc_cod === lot_conc_cod);
				if (findDeadline) {
					handleForm({
						...findDeadline,
					})
				}
			} catch (error) {
				setInfoRequest(handleWaring(String(error?.error)))
			}
		}
	}, [serv_sub_cat_cod, lot_conc_cod, deadline])

	useEffect(() => {
		if (praz_dias) {
			handleForm({
				serv_cat_prazo: praz_dias > 0 ? addDate(praz_dias) : '',
			})
		}
	}, [praz_dias])

    const onSubmit = async () => {
		if (!praz_dias && isBeforeDate(serv_cat_prazo, false)) {
			handleForm({
				serv_cat_prazo: '',
			})
			return setInfoRequest(
				handleWaring(
					'Você não definir o prazo do serviço menor ou igual ao dia de hoje'
				)
			)
		}
		if (!serv_cod || !serv_cat_cod || !serv_sub_cat_cod || !serv_cat_prazo) {
			return setInfoRequest(
				handleWaring(
					'Você precisa preencher todos os campos relacionados ao serviço'
				)
			)
		}
		const position = await returnPosition()
		const pedido_venda_local = {
			pv_loc_km_ini: rod_km_ini,
			pv_loc_km_init_mts: rod_km_ini_mt,
			pv_loc_km_fim: rod_km_fim,
			pv_loc_km_fim_mts: rod_km_fim_mt,
			pv_loc_lat: position.lat,
			pv_loc_lng: position.lng,
			pv_loc_obs: pv_loc_obs,
			rod_sent_desc,
			pv_loc_sentido: rod_sent_cod,
		}
		if (!isValid(pedido_venda_local)) {
			return setInfoRequest(
				handleWaring('Você precisa informar corretamente a sua localização')
			)
		}
		if (bases64?.length >= 1) {

			bases64.forEach(obj => {
				if (obj.pvs_loc_foto_legenda) {
				  obj.pvs_loc_foto_legenda += ` Referente ao serviço ${serv_sub_cat_desc}`;
				}
			  });
			
			const items = {
				rod_cod,
				conc_cod,
				pv_servico:[{
					pvs_servico: serv_cod,
					pvs_servico_cat: serv_cat_cod,
					pvs_servico_sub_cat: serv_sub_cat_cod,
					pvs_obs: serv_sub_cat_desc,
					serv_desc,
					serv_cat_desc,
					serv_sub_cat_desc,
					pvs_prazo_fim: `${serv_cat_prazo} 11:59:00`,
					pvs_lat: position.lat || 0,
					pvs_lng: position.lng || 0,
					pedido_venda_local: {
						...pedido_venda_local,
					},
					pv_servico_foto:bases64,
				}],
			}
			handleForm({
				serv_cat_prazo: '',
			})
			if (!isValid(items)) {
				return setInfoRequest(handleWaring())
			}
			await postItemOversight({
				...items,
			})
		}
	}

    return(
        <div style={{backgroundColor:'white'}} className="row m-2">
            <div className="col-md-4 d-none d-md-flex justify-content-end">
                <Menu activeMenu={activeMenu} setActiveMenu={setActiveMenu} onSubmit={onSubmit} />
            </div>
            <div className="col-md-8 col-sm-12 mb-5 ContentBox">
                <div className="tab-content">
                    <div id="menu1" className="tab-pane fade in active">
                        <PhotoSection />
                    </div>
                    <div id="menu2" className="tab-pane fade">
                        <LocalizationSection />
                    </div>
                    <div id="menu3" className="tab-pane fade">
                        <KmSection />
                    </div>
                    <div id="menu4" className="tab-pane fade">
                        <ServiceSection />
                    </div>
                    <div id="menu5" className="tab-pane fade">
                        <ResumeSection />
                    </div>
                </div>
                <button onClick={pressBack} className={`d-none d-md-flex btn btn-${activeMenu===1?'secondary':'info'} align-items-center BackButton`} disabled={activeMenu===1?true:false}>Voltar</button>
                <button onClick={pressNext} className='d-none d-md-flex btn btn-info align-items-center NextButton'>Próximo</button>

            </div>
            <div className="col-12 d-flex d-md-none">
                <MenuBottom activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
            </div>
        </div>
    )
}

export default OverSightNew