import React, { useContext, useEffect, useRef, useState } from 'react'
import ContentHeader from '../../../../components/contentHeader'
import { formContext } from '../../../../context/formContext'
import CardHeader from '../cardHeader'
import FormPavementsIndicators from '../../../../components/form/pavementIndicators'
import CardNavp from '../cardNavp'
import TableSegments from '../../../../components/table/pavement/seguimentos'
import { requestContext } from '../../../../context/requestContext'
import { find, groupBy } from '../../../../services/arrayHandless'
import { parseFromMysqlDateToJSDate } from '../../../../services/dateHandless'
import { controllCrosInfo, loadConfigIGG, loadConfigIRI } from '../../../../services/paviment'
import TableMonitoring from '../../../../components/table/pavement/monitoring'
import FormPavementShowTables from '../../../../components/form/pavementShowTables'
import { useNavigate } from 'react-router-dom'
import { namesScreens } from '../../../../constants'
import { getPropsDimissions } from '../../../../services/getPropsElNode'
const ViewPavementIndicators = () => {
	const { state: { lipc_cod, lipc_conc_cod }, handleForm } = useContext(formContext)
	const {
		getItemAllPavementIndicators,
		getItemAllMonitoringIndicators,
		getItemAllConsolidatedPavementIndex
	} = useContext(requestContext)
	const [smallerValueIRI, setSmallerValueIRI] = useState(null)
	const [smallerValueIGG, setSmallerValueIGG] = useState(null)
	const [items, setItems] = useState(null)
	const [monitoring, setMonitoring] = useState(null)
	const [itemsNavp, setItemsNavp] = useState(null)
	const [payloadNavp, setPayloadNavp] = useState(null)
	const [crossMonitoring, setCrossMonitorin] = useState(null)
	const [seeItems, setSeeItems] = useState(true)
	const [seeMonitoring, setSeeMonitoring] = useState(false)
	const [pages, setPages] = useState(null)
	const refList = useRef(null)
	const [widthList, setWidhtList] = useState(214)
	const navigate = useNavigate()
	useEffect(() => {
		const getWidhtRefList = () => {
			const { width } = getPropsDimissions(refList?.current?.id)
			if (width) setWidhtList(width)
		}
		getWidhtRefList()
	}, [refList])
	useEffect(() => {
		const loadConfig = async () => {
			try {
				await loadConfigIRI(setSmallerValueIRI)
				await loadConfigIGG(setSmallerValueIGG)
			} catch (error) {
				console.log(error)
			}
		}
		loadConfig()
	}, [])
	useEffect(() => {
		const controllSetValues = () => {
			if (lipc_conc_cod && lipc_cod) {
				handleForm({
					conc_cod: lipc_conc_cod,
				})
			}
		}
		controllSetValues()
	}, [lipc_conc_cod, lipc_cod])
	useEffect(() => {
		const controllMonitoring = () => {
			if (!items || !monitoring) {
				return
			}
			const resultCrossMonitoring = controllCrosInfo({ items: items, monitoring: monitoring })
			if (resultCrossMonitoring?.length) {
				return setCrossMonitorin(resultCrossMonitoring)
			}
			setCrossMonitorin(monitoring)
		}
		controllMonitoring()
	}, [items, monitoring])
	const fetchItemsMonitoring = async (page = null) => {
		try {
			if (!lipc_cod || !lipc_conc_cod) {
				return
			}
			const { data: result, ...rest } = await getItemAllMonitoringIndicators({
				id: lipc_cod,
				rod_cod: 0,
				rod_concessionaria: lipc_conc_cod,
				mon_date_from: null,
				mon_date_to: null,
				rod_km_ini_from: 0,
				rod_km_ini_to: page || 0,
			})
			const tempResult = await Promise.all(result.map(async (item, i) => {
				const {
					loc_lipc_cod,
					loc_lipc_rod_cod,
					loc_lipc_rod_conc,
					loc_lipc_rod_sent_cod,
					loc_lipc_km_init,
					loc_lipc_km_init_mts,
					loc_lipc_km_fim,
					loc_lipc_km_fim_mts,
					loc_lipc_obs,
					mon_pav_lng,
					mon_pav_lat,
					rod_km,
					rod_sent_desc,
					rod_desc,
					...rest
				} = item
				const localizacao_levantamento_indice_pavimento_consolidado = {
					loc_lipc_cod,
					loc_lipc_rod_cod,
					loc_lipc_rod_conc,
					loc_lipc_rod_sent_cod,
					loc_lipc_km_init,
					loc_lipc_km_init_mts,
					loc_lipc_km_fim,
					loc_lipc_km_fim_mts,
					loc_lipc_obs,
					mon_pav_lng,
					mon_pav_lat,
					rod_km,
					rod_sent_desc,
					rod_desc,
				}
				const { data: consolidated_pavement_index } = await getItemAllConsolidatedPavementIndex({ id: loc_lipc_cod })
				return {
					localizacao_levantamento_indice_pavimento_consolidado,
					avaliacaoes_objetivas_superficie: consolidated_pavement_index.sort((prev, next) => prev.tip_ind_pav_desc - next.tip_ind_pav_desc),
					...rest
				}
			}))
			setMonitoring(tempResult)
			setPages(rest)
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		const fetchItemsIndicator = async () => {
			try {
				if (lipc_cod && lipc_conc_cod && smallerValueIRI && smallerValueIGG) {
					const response = await getItemAllPavementIndicators({
						id: lipc_cod,
						conc_cod: lipc_conc_cod,
					})
					if (!response?.data) {
						return
					}
					const { indice_pavimento_consolidado, levantamento_indice_pavimento_consolidado,
						nota_avaliacao
					} = response?.data[response?.data.length - 1]
					const tempNotaAvaliacao = nota_avaliacao.map(item => ({
						...item,
						id: item.tip_ind_pav_cod,
					}))
					setItemsNavp(tempNotaAvaliacao)
					handleForm({
						...levantamento_indice_pavimento_consolidado
					})
					if (!indice_pavimento_consolidado?.length) {
						return
					}
					const group = groupBy(indice_pavimento_consolidado, 'loc_lipc_km_init')
					const result = []
					Object.keys(group).map((item) => {
						if (!group[item]?.length) {
							return
						}
						const temp_local = group[item][group[item]?.length - 1]
						const {
							rod_sent_desc,
							rod_km,
							rod_desc,
							loc_lipc_rod_sent_cod,
							loc_lipc_rod_conc,
							loc_lipc_rod_cod,
							loc_lipc_obs,
							loc_lipc_lipc_cod,
							loc_lipc_km_init_mts,
							loc_lipc_km_init,
							loc_lipc_km_fim_mts,
							loc_lipc_km_fim,
							loc_lipc_cod,
						} = temp_local
						const lipc_id = loc_lipc_lipc_cod
						const localizacao_levantamento_indice_pavimento_consolidado = {
							rod_sent_desc,
							rod_km,
							rod_desc,
							loc_lipc_rod_sent_cod,
							loc_lipc_rod_conc,
							loc_lipc_rod_cod,
							loc_lipc_obs,
							loc_lipc_lipc_cod,
							loc_lipc_km_init_mts,
							loc_lipc_km_init,
							loc_lipc_km_fim_mts,
							loc_lipc_km_fim,
							loc_lipc_cod,
						}
						const indice_pavimento_consolidado = group[item].
							filter(row => row.ipc_tip_cod >= 1 && row.ipc_tip_cod <= 3).
							filter(row => row.ipc_loc_lipc_cod === loc_lipc_cod).map(row => {
								const {
									ipc_val,
									ipc_tip_cod,
									ipc_perc,
									ipc_loc_lipc_cod,
									ipc_lipc_cod,
									ipc_gp_ind_pav_cod,
									ipc_date,
									ipc_cod,
									gp_ind_pav_desc,
									tip_ind_pav_desc
								} = row
								return {
									ipc_val,
									ipc_tip_cod,
									ipc_perc,
									ipc_loc_lipc_cod,
									ipc_lipc_cod,
									ipc_gp_ind_pav_cod,
									ipc_date: parseFromMysqlDateToJSDate(ipc_date),
									ipc_cod,
									gp_ind_pav_desc,
									tip_ind_pav_desc,
									tip_ind_pav_cod: ipc_tip_cod,
								}
							})
						if (indice_pavimento_consolidado.length) {
							const { data: ipc_type_1 } = find(indice_pavimento_consolidado, 'tip_ind_pav_cod', parseInt(smallerValueIRI?.id))
							const { data: ipc_type_3 } = find(indice_pavimento_consolidado, 'tip_ind_pav_cod', parseInt(smallerValueIGG?.id))
							const f = ipc_type_1?.ipc_val || 0
							const i = ipc_type_3?.ipc_val || 0
							const f_result = (f <= 3.46 && i <= 40) ? 1 : 0
							const m_result = Number(String(loc_lipc_km_fim).replace(',', '.')) - Number(String(loc_lipc_km_init).replace(',', '.'))
							result.push({
								lipc_id,
								indice_pavimento_consolidado: indice_pavimento_consolidado.sort(
									(prev, next) => prev.tip_ind_pav_desc - next.tip_ind_pav_desc
								),
								localizacao_levantamento_indice_pavimento_consolidado,
								l: f_result,
								m: m_result,
							})
						}
					})
					const { localizacao_levantamento_indice_pavimento_consolidado } = result[result?.length - 1]
					handleForm({
						...localizacao_levantamento_indice_pavimento_consolidado,
					})
					setItems(result)
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchItemsIndicator().finally(() => fetchItemsMonitoring())
	}, [lipc_cod, lipc_conc_cod, smallerValueIRI, smallerValueIGG])
	const handleCancel = () => {
		navigate(namesScreens.PAVEMENT_INDICATOR)
	}
	return (
		<>
			<ContentHeader title={'Novo Monitoramento de Pavimento'} />
			<div className="content">
				<div className="card card-info">
					<div className="card-header">
						<CardHeader />
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<FormPavementsIndicators />
					</div>
				</div>
				<CardNavp
					nota_avaliacao_pavimento={itemsNavp}
					segmentsData={items}
					setPayloadNavp={setPayloadNavp}
				/>
				<div className="card card-info">
					<div className="card-header">
						<h3 className="card-title text-capitalize">Seguimentos</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div ref={refList} className="card-body p-0 m-0" id='refList'>
						<FormPavementShowTables
							seeItems={seeItems}
							setSeeItems={setSeeItems}
							seeMonitoring={seeMonitoring}
							setSeeMonitoring={setSeeMonitoring}
						/>
						{seeItems ? (
							<TableSegments
								widthList={widthList}
								data={items}
								crossMonitoring={crossMonitoring}
								setData={setItems} />
						) : ''}
						{seeMonitoring ? (
							<TableMonitoring items={crossMonitoring} pages={pages}
								fetchItemsMonitoring={fetchItemsMonitoring}
								widthList={widthList}
							/>
						) : ''}
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<button className="btn btn-info mr-3">
                            Salvar <i className="fa fa-save"></i>
						</button>
						<div className="btn btn-secondary" onClick={handleCancel}>
                            Cancelar <i className="fa fa-times-circle"></i>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default ViewPavementIndicators