import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonPlus } from '../../../css/globlalStyle'
import { handleWaring } from '../../../services/isValid'
import ListEmail from '../../list/email'
const DealershipContact = () => {
	const { state, onChange, onChangePhone, handleForm } = useContext(formContext)
	const { setInfoRequest, data } = useContext(requestContext)
	const {
		conc_cod,
		conc_telefone,
		conc_celular,
		em_email,
		dd,
		conc_email
	} = state
	useEffect(() => {
		if (data?.total > 0 && conc_cod) {
			handleForm({
				conc_email: data?.data
			})
		} else {
			handleForm({
				conc_email: '',
			})
		}
	}, [data])
	const handlePlusEmail = () => {
		if (!em_email) {
			return setInfoRequest(handleWaring('Você precisa preencher o e-mail'))
		}
		Array.isArray(conc_email) ? handleForm({
			conc_email: [...conc_email, {
				conc_email: em_email,
			}]
		}) : handleForm({
			conc_email: [{
				conc_email: em_email,
			}]
		})
		handleForm({
			em_email: '',
		})
	}
	return (
		<div className='row'>
			<div className="form-group col-md-6 col-sm-6">
				<label>Telefone:</label>
				<div className="input-group">
					<input type="phone"
						onChange={onChangePhone}
						name='conc_telefone' value={conc_telefone}
						className={`form-control form-control-border ${!conc_telefone && 'is-invalid'}`}
						placeholder={`(${dd ? dd : '00'}) 0000-0000`} />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Celular:</label>
				<div className="input-group">
					<input type="phone"
						onChange={onChangePhone}
						name='conc_celular' value={conc_celular}
						className={`form-control form-control-border ${!conc_celular && 'is-invalid'}`}
						placeholder={`(${dd ? dd : '00'}) 0000-0000`} />
				</div>
			</div>
			<div className="form-group col-12">
				<label>E-mail:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='em_email' value={em_email}
						className={`form-control form-control-border ${!Array.isArray(conc_email) && 'is-invalid'}`}
						placeholder='E-mail da Concessionária' />
					<div className="input-group-prepend">
						<span className="input-group-text bg-info"
							onClick={handlePlusEmail}
							style={styleButtonPlus}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</span>
					</div>
				</div>
			</div>

			{Array.isArray(conc_email) && (
				<>
					<div className='form-group col-12'>
						<label>E-mail adicionados</label>
					</div>
					{
						conc_email.map((item, i) => (
							<div className='form-group col-12' key={i}>
								<ListEmail item={item} data={conc_email} />
							</div>
						)
						)
					}
				</>
			)
			}
		</div>
	)
}
export default DealershipContact