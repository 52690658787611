import React, { useContext, useEffect } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
import { namesScreens } from '../../../constants'
import { Link, useNavigate } from 'react-router-dom'
import SearchPavement from '../../../components/form/searchPavement'
import Spinner from '../../../components/spinner'
import ListPavementIndicator from '../../../components/list/pavementIndicator'
const ManagerPavementIndicator = () => {
	const { state: { desc, conc_cod, rod_cod }, handleForm } = useContext(formContext)
	const { cardinal_points, getItemPavementIndicators, getWhereItemPavementIndicators } = useContext(requestContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		getItemPavementIndicators({ id: conc_cod, rod_cod })
	}, [conc_cod])

	const handleNew = () => {
		handleForm({
			lot_conc_desc: '',
			conc_nome: '',
			conc_cod: '',
			fisc_obs: '',
			rod_cod: '',
			rod_desc: '',
			lipc_obs: '',
		})
		navigate(namesScreens.PAVEMENT_INDICATOR_NEW)
	}
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = cardinal_points
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return desc ? getWhereItemPavementIndicators(current_page > 1 && `${desc}?page=${current_page - 1}`) : getItemPavementIndicators(current_page > 1 && {
				id: conc_cod, rod_cod: `${rod_cod || 0}?page=${current_page - 1}`
			})
		}
		if (label === 'pagination.next') {
			return desc ? getWhereItemPavementIndicators(current_page > 1 && `${desc}?page=${current_page - 1}`) : getItemPavementIndicators(current_page < last_page && {
				id: conc_cod, rod_cod: `${rod_cod || 0}?page=${current_page + 1}`
			})
		}
		if (!label) {
			return desc ? getWhereItemPavementIndicators(desc) : getItemPavementIndicators({
				id: conc_cod, rod_cod: rod_cod
			})
		}
		if (desc) {
			return getWhereItemPavementIndicators(`${desc}?page=${label}`)
		}
		getItemPavementIndicators({
			id: conc_cod, rod_cod: `${rod_cod || 0}?page=${label}`
		})
	}
	const RenderLinkFooter = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
						}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Levantamento de índices de Pavimento'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title d-md-block d-none">Levantamento de índices de pavimento cadastrados</h3>
						<div className="card-tools">
							<SearchPavement handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className='card'>
					<div className="card-body">
						<Spinner direction={'center'} label="carregando" />
						<ListPavementIndicator items={cardinal_points?.data} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(cardinal_points?.links) &&
								cardinal_points.links.map((item) => (<RenderLinkFooter item={item} key={item.label} />
								))}
						</ul>
						{user?.set_nivel === 0 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerPavementIndicator