import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
import { getBaseUrlFile } from "../../../api";
import { useNavigate } from "react-router-dom";
import { namesScreens } from "../../../constants";
const ListPavementIndicator = ({ items, simpleList = false }) => {
    const { handleForm } = useContext(formContext)
    const navigate = useNavigate()
    const handleEdit = (item) => {
        const firstItem = item[item.length - 1]
        handleForm({
            ...firstItem
        })
        navigate(namesScreens.PAVEMENT_INDICATOR_VIEW)
    }
    const handleDelete = (item) => {
        const { conc_nome, lipc_cod } = item[0]
        const confirm = window.confirm(`Você deseja mesmo excluir esse monitoramento para a concessionária ${conc_nome}`)
        if (!confirm) {
            return
        }

    }
    const handleDownload = (path) => {
        if (path) {
            window.open(`${getBaseUrlFile()}${path}`, '_blank').focus();
        }
    }
    if (!items) {
        return <div className="row">
            <div className="col-12">Não há itens para serem exibidos</div>
        </div>
    }
    return (
        Object.keys(items).map(item => (
            <div className="row" key={Number(item)}>
                <div className={simpleList ? 'col-12' : "mb-3 col-md-10 col-sm-12"}>
                    {Array.isArray(items[item]) && items[item].map((row, i) => (
                        <div className="row" key={i}>
                            {i === 0 && !simpleList && (
                                <div className="mb-3 col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <span className="text-secondary mr-1">Concessionária: </span>
                                            {row.conc_nome}
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <span className="text-secondary mr-1">Lote: </span>
                                            {row.lot_conc_desc}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {i === 0 && (
                                <div className="mb-3 col-12">
                                    <div className="text-secondary mr-2">Rodovia(s):</div>
                                    {row.rod_desc}
                                </div>
                            )}
                            {i === 0 && (
                                <div className="mb-3 col-md-6 col-sm-12">
                                    <span className="text-secondary mr-1">Total de seguimentos: </span>
                                    {row.lipc_tot_seg}
                                </div>
                            )}
                            {i === 0 && !simpleList && (
                                <div className="col-12 mb-3">
                                    <div className="row">
                                        <div className="col-md-10 col-sm-10">
                                            <span className="text-secondary mr-2">
                                                Observações:
                                            </span>
                                            {row.lipc_obs}
                                        </div>
                                        <div className="col-md-2 col-sm-2">
                                            <span className="text-secondary mr-2">
                                                Planilha:
                                            </span>
                                            <div className="btn btn-sm btn-info" onClick={() => handleDownload(row.lipc_path_file)}>
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="mb-3 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="mb-3 col-md-4 col-sm-6">
                                        <span className="text-secondary mr-1">Total atendido {row.tip_ind_pav_desc}: </span>
                                        {row.navp_val}
                                    </div>
                                    <div className="mb-3 col-md-4 col-sm-6">
                                        <span className="text-secondary mr-1">Total atendido {row.tip_ind_pav_desc}: </span>
                                        {parseFloat(row.navp_perc).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={simpleList ? 'col-12' : "mb-3 col-md-2 col-sm-12"}>
                    <div className="mb-3 btn btn-info btn-sm mr-2" onClick={() => handleEdit(items[item])}>
                        <i className="fa fa-edit"></i>
                    </div>
                    <div className="mb-3 btn btn-secondary btn-sm" onClick={() => handleDelete(items[item])}>
                        <i className="fa fa-trash"></i>
                    </div>
                </div>
                <hr className="col-12"></hr>
            </div>
        ))
    )
}
export default ListPavementIndicator