/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
const SelectCompany = ({ col, selectToUser }) => {
	const { state, onChangeSelectInput, handleForm } = useContext(formContext)
	const { company } = useContext(requestContext)
	const {
		conc_cod,
		reload,
		emp_nome
	} = state
	const cancelSelect = () => {
		handleForm({
			emp_cod: '',
			emp_nome: '',
			emp_cnpj: '',
			emp_ativo: '',
		}
		)
	}
	const isSelectToUser = (el) => {
		if (selectToUser) {
			return el.lot_conc_desc !== null
		}
		return el.lot_conc_desc !== '0'
	}
	return (
		<div className={`form-group ${col || 'col-12'}`}>
			<label>{emp_nome ? 'Empresa' : 'Empresa'}</label>
			<div className="input-group">
				{emp_nome ?
					<div className='form-control form-control-border'>
						{emp_nome}
					</div>
					:
					<select onChange={onChangeSelectInput}
						className={`form-control form-control-border ${!conc_cod && 'is-invalid'}`}>
						<option value={JSON.stringify({ reload: !reload })}>Selecionar uma Empresa</option>
						{Array.isArray(company?.data) && company.data.filter(isSelectToUser).sort((a, b) => a.emp_nome - b.emp_nome).map(item => (
							<option key={item.emp_cod} value={JSON.stringify(item)}>
								{item.emp_nome}
							</option>
						))}
					</select>
				}
				{emp_nome &&
                    <div className="input-group-prepend" onClick={cancelSelect}>
                    	<span className="input-group-text bg-info" style={styleButtonSearch}>
                    		<i className="fa fa-times-circle" aria-hidden="true"></i>
                    	</span>
                    </div>
				}
			</div>
		</div>
	)
}
export default SelectCompany