import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import { filterAndRemove } from '../../services/arrayHandless'
import { handleWaring } from '../../services/isValid'
import { formateDate } from '../../services/dateHandless'

const OverSightNotificationItem = ({ group, items }) => {
	const { setInfoRequest } = useContext(requestContext)
	const [agroup, setAgroup] = useState(true)
	const [selectedItem, setSelectedItem] = useState(null)
	const [disabled, setDisabled] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		if (Array.isArray(selectedItem)) {
			const findItem = selectedItem.find(el => el.concessionaria === group)
			setDisabled(findItem)
		}
	}, [group, selectedItem])
	const handleClick = () => {
		if (!agroup) {
			return setAgroup(!agroup)
		}
		if (!selectedItem) {
			setAgroup(false)
			return setInfoRequest(
				handleWaring('Você precisa selecionar uma uma mais atividades')
			)
		}
		setSelectedItem(null)
		navigate('/notification/new', { state: [...selectedItem] })
	}
	const handleLink = (item) => {
		window
			.open(
				`https://www.google.com/maps/place/${item.pvs_lat},${item.pvs_lng}`,
				'_target'
			)
			.focus()
	}
	const onChange = ({ target }) => {
		const { value, checked } = target
		const parseValue = JSON.parse(value)
		if (!checked) {
			const result = filterAndRemove(
				selectedItem,
				'pvs_servico_sub_cat',
				parseValue.pvs_servico_sub_cat
			)
			return setSelectedItem(result.data)
		}
		setSelectedItem(
			Array.isArray(selectedItem)
				? [...selectedItem, { ...parseValue }]
				: [
					{
						...parseValue,
					},
				]
		)
	}
	return (
		<div className="row p-3">
			<div className="col-sm-6 col-md-10 mb-3">{group}</div>
			<div className="col-sm-2 col-md-2 mb-3">
				<button className="btn btn-info w-100" onClick={handleClick} disabled={!disabled}>
          Notificar <i className="ml-1 fa fa-exclamation"></i>
				</button>
			</div>
			{items.map((item) => (
				<>
					<div className="col-md-6 col-sm-12">
						<span className="text-secondary mr-1">Atividade:</span>
						{item.serv_sub_cat_desc}
					</div>
					<div className="col-md-6 col-sm-12">
						{agroup ? (
							<div className="row">
								<div className="col-6">
									<span className="text-secondary">Prazo: </span>
									{formateDate(item.pvs_prazo_fim)}
								</div>
								<div className="col-6">
									<div className="custom-control custom-checkbox">
										<input
											className="custom-control-input custom-control-input-danger"
											type="checkbox"
											id={`customCheckbox4${item.pvs_cod}`}
											value={JSON.stringify(item)}
											onChange={onChange}
										/>
										<label
											htmlFor={`customCheckbox4${item.pvs_cod}`}
											className="custom-control-label"
										>
                      Selecionar
										</label>
									</div>
								</div>
							</div>
						) : (
							<>
								<span className="text-secondary">Prazo: </span>
								{formateDate(item.pvs_prazo_fim)}
							</>
						)}
					</div>
					{item.servico_nao_executado && (
						<div className="mb-2 col-12">{item.servico_nao_executado}</div>
					)}
					<div className="mb-2 col-sm-10 col-md-10  mb-3">
						<span className="text-secondary">Rodovia: </span> {item.rod_desc}
					</div>
					<div className="col-sm-2 col-md-2 mb-3">
						<Link to="#" className="text-dark" onClick={() => handleLink(item)}>
              Ver no mapa <i className="ml-1 fa fa-map"></i>
						</Link>
					</div>
					<hr className="mb-3 w-100"
					></hr>
				</>
			))}
			<hr className="w-100" />
		</div>
	)
}
export default OverSightNotificationItem
