import { useContext, useRef } from "react"
import { find } from "../../../services/arrayHandless"
import { generateBse64, getExt } from "../../../services/files"
import returnPosition from "../../../services/geolocation"
import { getToday } from "../../../services/dateHandless"
import { formContext } from "../../../context/formContext/index.js"
import { ImageBox } from "./ImageBox/index.js"
import Camera from "../../camera/index.js"

export const PhotoSection = () => {

    const {state, bases64, setBases64, showCamera, setShowCamera} = useContext(formContext)
	const {pvs_loc_foto_legenda} = state
    const input_file_ref = useRef(null)

    const handleAttach = () => {
		input_file_ref.current.click()
	}

	const openCamera = () => {
		setShowCamera(!showCamera)
	}

    const onChangeFile = async ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return 
		}
		const newItems = []
		for (const itemFile of files) {
			const { name } = itemFile
			const parts = name.split('.');
  			const type = parts.length > 1 ? parts.pop() : '';
			// const type = getExt(name)
			const types_valid = [{ type: 'jpeg' }, { type: 'jpg' }, { type: 'png' }]
			const { data: find_type } = find(types_valid, 'type', type)
			if (find_type) {
				const position = await returnPosition()
				const base64 = await generateBse64(itemFile)
				newItems.push({
					base64: base64,
					name: name,
					pvs_loc_foto_lat: position.lat,
					pvs_loc_foto_lng: position.lng,
					pvs_loc_foto_legenda: `Foto tirada dia ${getToday()}.`,
				})
			}
		}
		if (!newItems.length) {
			return
		}
		if (bases64?.length) {
			return setBases64([...newItems, ...bases64])
		}
		setBases64([...newItems])
	}

	const handleRemove = async (photo) => {
		const newArrFiltered = bases64.filter(item => JSON.stringify(item) !== JSON.stringify(photo));
		setBases64(newArrFiltered)
	}

    return (
        <>
            <h5 className="mb-2">Passo 1/4</h5>
            <h4 className="mb-2 mt-2">Fotos</h4>
            <ImageBox bases64={bases64} handleAttach={handleAttach} openCamera={openCamera} handleRemove={handleRemove} />
			{showCamera && <Camera pvs_loc_foto_legenda={pvs_loc_foto_legenda} />}
            <input
                name="file_attachments"
                className="d-none"
                type="file"
                onChange={onChangeFile}
                ref={input_file_ref}
                multiple
            />
        </>
    )
}