import { useContext, useEffect } from "react"
import { formContext } from "../../../../context/formContext"
import { requestContext } from "../../../../context/requestContext"

export const Direction = () => {

    const { state, onChangeSelectInput } = useContext(formContext)
    const { cardinal_points, getAllItemCardinalPoints } = useContext(requestContext)
    const { rod_concessionaria, rod_cod  } = state

    useEffect(() => {
		if (rod_cod && rod_concessionaria) {
			getAllItemCardinalPoints(rod_concessionaria, rod_cod)
		}
	}, [rod_cod, rod_concessionaria])

    return (
        <>
            <label>Sentido</label>
            <select
                className="form-control"
                placeholder='Selecione'
                name="rod_sent_cod"
                onChange={onChangeSelectInput}
            >
                <option value={''}>Selecione</option>
                {Array.isArray(cardinal_points?.data) &&
                    cardinal_points?.data?.map((item) => (
                        <option key={item.rod_sent_cod} value={JSON.stringify(item)}>
                            {item.rod_sent_desc}
                        </option>
                ))}
            </select>
        </>
    )
}