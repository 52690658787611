import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const ListTypeOversight = ({ data }) => {
	const { handleForm } = useContext(formContext)
	const { deleteItemTypeOversight } = useContext(requestContext)
	const navigate = useNavigate()
	const onClick = (item) => {
		handleForm({
			...item,
		})
		navigate('/type_sales_order/new')
	}
	const handleDelete = (item) => {
		const confirm = window.confirm(
			`Você deseja mesmo excluir o tipo de NC ${item.tip_ped_venda_desc} ? Se você confirmar a exclusão todos os sub tipo de obras de ${item.tip_ped_venda_desc} também serão excluídos`
		)
		if (!confirm) {
			return
		}
		deleteItemTypeOversight(item.tip_ped_venda_cod)
	}
	return data.data.map((item) => (
		<div className="row p-3 mb-3" key={item.tip_ped_venda_cod}>
			<div className="col-md-10 col-sm-8 mb-3">{item.tip_ped_venda_desc}</div>
			<div className="col-md-2 col-sm-4">
				<div className="row">
					<button
						className="btn btn-sm btn-info mr-2"
						onClick={() => onClick(item)}
					>
						<i className="fa fa-edit"></i>
					</button>
					<button
						className="btn btn-sm btn-secondary"
						onClick={() => handleDelete(item)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
			</div>
		</div>
	))
}
export default ListTypeOversight
