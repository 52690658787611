import React, { useContext } from 'react'
import FormForgotPassWord from '../../components/form/forgotPassword'
import { formContext } from '../../context/formContext'
import { requestContext } from '../../context/requestContext'
import { execptionWithIcons } from '../../api'
import { authContext } from '../../context/authContext'
const ForgotPassWord = () => {
	const { state } = useContext(formContext)
	const { email } = state
	const { setInfoRequest } = useContext(requestContext)
	const { forgotPassword } = useContext(authContext)
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (!email) {
			return setInfoRequest(handleWaring())
		}
		forgotPassword({ email })
	}
	const handleWaring = () => {
		const execptionWithicons = execptionWithIcons(400)
		setInfoRequest({
			status: 400,
			message: 'Você precisa preencher seu e-mail e senha',
			...execptionWithicons
		})
	}
	return (
		<div className="container d-flex flex-column">
			<div className="row align-items-center justify-content-center
            min-vh-100">
				<div className="col-12 col-md-8 col-lg-4">
					<div className="card shadow-sm">
						<div className="card-body">
							<div className="mb-4">
								<h5>Esqueceu a sua senha?</h5>
								<p className="mb-2">Coloque seu e-mail abaixo para enviarmos uma nova senha</p>
							</div>
							<form onSubmit={onSubmit}>
								<FormForgotPassWord />
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ForgotPassWord