import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import ListConfigurationParameters from '../../components/list/listConfigurationParameters'
import Spinner from '../../components/spinner'
import { formContext } from '../../context/formContext'
import { authContext } from '../../context/authContext'
import SearchConfigurationParameters from '../../components/form/searchConfigurationParameters'
const ManagerConfigurationParameters = () => {
	const { getItemConfigurationParameters, getWhereItemConfigurationParameters, data } = useContext(requestContext)
	const { handleForm, state: { sett_desc } } = useContext(formContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		handleClear()
		getItemConfigurationParameters()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = data
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return sett_desc ? getWhereItemConfigurationParameters(current_page > 1 && `${sett_desc}?page=${current_page - 1}`) : getItemConfigurationParameters(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return sett_desc ? getWhereItemConfigurationParameters(current_page > 1 && `${sett_desc}?page=${current_page - 1}`) : getItemConfigurationParameters(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return sett_desc ? getWhereItemConfigurationParameters(sett_desc) : getItemConfigurationParameters()
		}
		if (sett_desc) {
			return getWhereItemConfigurationParameters(`${sett_desc}?page=${label}`)
		}
		getItemConfigurationParameters(`0?page=${label}`)
	}
	const handleClear = () => {
		handleForm({
			config_para_cod: '',
			config_para_desc: '',
			config_para_value: '',
			config_para_settings: '',
			sett_cod: '',
			sett_desc: '',
		})
	}
	const handleNew = () => {
		handleClear()
		navigate('/configurations_paramaters/new')
	}
	const RenderLinks = ({ item }) => {
		return (

			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>

		)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Parametros de configurações'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
							Parametros de configurações cadastrados
						</h3>
						<div className="card-tools">
							<SearchConfigurationParameters handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListConfigurationParameters data={data?.data} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(data?.links) &&
								data.links.map((item) => (<RenderLinks item={item} key={item.label} />))}
						</ul>
						{user && user.set_nivel === 0 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerConfigurationParameters
