/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../../../../components/contentHeader";
import ImageItem from "../../../../components/list/img";
import ListServiceOversightReport from "../../../../components/list/oversightServicesReport";
import MyMap from "../../../../components/map";
import { requestContext } from "../../../../context/requestContext";
import { formateDate } from "../../../../services/dateHandless";
import { LOGO } from "../../../../config/flavor";
import ExportCSV from "../../../../components/excel";
import { filterAndRemoveRepeated } from "../../../../services/arrayHandless";
import Spinner from "../../../../components/spinner";
const ReportOversightView = () => {
  const { data, setLoading, loading, setReportData } = useContext(requestContext);
  const [markersService, setMarkersService] = useState(null);
  const [showMarkerService, setShowMarkerService] = useState(false);
  const [showPhotos, setShowPhotos] = useState(false);
  const [showCheckInspector, setShowCheckInspector] = useState(false);
  const [generateReport, setGenerateReport] = useState(false);
  const [position, setPosition] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [itemsReport, setItemsReport] = useState(null);
  const [newData, setNewData] = useState([])
  const naviate = useNavigate();
  useEffect(() => {
    if (generateReport) {
      window.print();
      setGenerateReport(false);
    }
  }, [generateReport]);
  useEffect(() => {
    if (Array.isArray(data)) {
      prepareDataToPDF(data)
      const csvdata = [];
      try {
        for (const element of data) {
          const { pedido_venda_servico, pedido_venda_fiscalizacao } = element;
          if (Array.isArray(pedido_venda_servico)) {
            for (const el of pedido_venda_servico) {
              let fotos = "";
              let fisc = "";
              const { pedido_venda_servico_foto } = el;
              if (pedido_venda_servico_foto?.length > 0) {
                fotos = el?.pedido_venda_servico_foto
                  .map((foto) => foto?.pvs_loc_foto_path)
                  ?.join(",");
              }
              if (pedido_venda_fiscalizacao?.legend > 0) {
                fisc = pedido_venda_fiscalizacao
                  .map((fisc_) => fisc_.fisc_obs)
                  .join(",");
              }
              csvdata.push({
                Codigo_da_ocorrencia: element.pedido_venda.ped_venda_cod,
                Concessionaria: element.pedido_venda.conc_nome,
                Descricao_do_lote: element.pedido_venda.lot_conc_desc,
                Data_constatacao: formateDate(
                  element.pedido_venda.ped_venda_date
                ),
                Status: element.pedido_venda.st_desc,
                Para_rodovia: element.pedido_venda.rod_desc,
                Responsavel: element.pedido_venda.name,
                Servico_solicitado: el.pvs_obs,
                Situacao_servico_solicitado: el.st_desc,
                Prazo_finalizacao: formateDate(
                  el?.pvs_prazo_fim?.replace("000Z", "")
                ),
                Km_inicial: el.pv_loc_km_ini,
                M_inicial: el.pv_loc_km_init_mts,
                Km_final: el.pv_loc_km_fim,
                M_final: el.pv_loc_km_fim_mts,
                Sentido_da_Rodovia: el.rod_sent_desc,
                Observacoes_sobre_local: el.pv_loc_obs,
                Fiscalizacao: fisc,
                Fotos: fotos,
              });
            }
            setMarkersService(
              pedido_venda_servico.map((item) => ({
                lat: item.pvs_lat,
                lng: item.pvs_lng,
                icon_color: item.st_cor_valor,
                content: `${item.pvs_obs} - ${item.st_desc}`,
              }))
            );
            pedido_venda_servico.forEach((item) =>
              setPosition({
                lat: item.pvs_lat,
                lng: item.pvs_lng,
                icon_color: item.st_cor_valor,
                content: `${item.pvs_obs} - ${item.st_desc}`,
              })
            );
          }
        }
        if (csvdata.length > 0) {
          const headers = Object.keys(csvdata[csvdata.length - 1]);
          const justDealeership = csvdata.map((item) => ({
            Concessionaria: item.Concessionaria,
          }));
          const removeDealershipRepeated =
            filterAndRemoveRepeated(justDealeership);
          const fileName = removeDealershipRepeated
            .map((item) => item.Concessionaria)
            .join("-");
          setCsvData({
            data: csvdata,
            headers: headers,
            fileName: fileName,
          });
        }
        setItemsReport(data);
      } catch (error) {
        /* empty */
      }
    }
  }, [data]);



  const prepareDataToPDF = (data) => {

    if (!data?.length > 0) {
      return
    }
    const novoArray = data.map(obj => {
      const fotosPrincipais = obj.pedido_venda_servico[0].pedido_venda_servico_foto.slice(0, 4);
      const outrasFotos = [];
      for (let i = 4; i < obj.pedido_venda_servico[0].pedido_venda_servico_foto.length; i += 8) {
        outrasFotos.push(obj.pedido_venda_servico[0].pedido_venda_servico_foto.slice(i, i + 8));
      }
      return {
        pedido_venda: obj.pedido_venda,
        pedido_venda_servico: [{
          ...obj.pedido_venda_servico[0],
          pedido_venda_servico_foto: fotosPrincipais
        }],
        outras_fotos: outrasFotos
      };
    });

    setNewData(novoArray)
  }

  const handleCancel = () => {
    naviate("/report/oversight");
  };
  const generatePdfDocument = async () => {
    setReportData({ data: newData, showPhotos: showPhotos })
    naviate("/reportncphotospdf");
  }
  const RenderItem = ({ item }) => {
    return (
      <>
        <div className="content mb-3">
          {!generateReport && (
            <ContentHeader
              title={`Relatório para ${item?.pedido_venda?.conc_nome}`}
            />
          )}
          <div className="card">
            {!generateReport && (
              <div className="card-header">
                <p className="card-title">Detalhes</p>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-toggle="modal"
                    data-target="#concessionaria"
                  >
                    <i className="fa fa-question-circle"></i>
                  </button>
                </div>
              </div>
            )}
            <div className="card-body">
              <div className="row">
                <div className="col-2">
                  <span className="text-secondary">Não Conformidade N°</span>{" "}
                  {item.pedido_venda.ped_venda_cod}  (Nº rastreio: {item.pedido_venda.ped_venda_cod})
                </div>
                <div className="col-2 mb-3">
                  <p className="text-secondary p-0 m-0">
                    Não Conformidade para:{" "}
                  </p>{" "}
                  {item.pedido_venda.conc_nome}
                </div>
                <div className="col-2 mb-3">
                  <p className="text-secondary p-0 m-0">Lote:</p>{" "}
                  {item.pedido_venda.lot_conc_desc}
                </div>
                {item.pedido_venda.ped_venda_date ? (
                  <div className="col-2 mb-3">
                    <p className="text-secondary p-0 m-0">No dia: </p>{" "}
                    {formateDate(item.pedido_venda.ped_venda_date)}
                  </div>
                ) : (
                  <div className="col-2 mb-3">
                    <i className="fa fa-wifi" aria-hidden="true"></i>...
                  </div>
                )}
                <div className="col-12 mb-3">
                  <p className="text-secondary p-0 m-0">Para rodivia: </p>{" "}
                  {item.pedido_venda.rod_desc}
                </div>
              </div>
              {showMarkerService &&
                Array.isArray(item.pedido_venda_servico) &&
                (position ? (
                  <>
                    {!generateReport && (
                      <p className="text-secondary">
                        Localização da abertura da Não Conformidade
                      </p>
                    )}
                    <MyMap
                      height={250}
                      position={position}
                      markersService={markersService}
                      contentinfowindow={`Aberta por ${item.pedido_venda.name}`}
                    />
                  </>
                ) : (
                  ""
                ))}
              {!generateReport && (
                <p className="text-secondary">Serviços solicitados</p>
              )}
              <ListServiceOversightReport
                generateReport={generateReport}
                data={item.pedido_venda_servico}
              />
              <div className="row">
                {showPhotos && (
                  <>
                    {!generateReport &&
                      Array.isArray(item.pedido_venda_servico) && (
                        <p className="text-secondary">
                          Fotos tiradas na abertura da Não Conformidade
                        </p>
                      )}
                    <div className="row">
                      {Array.isArray(item.pedido_venda_servico)
                        ? item.pedido_venda_servico.map((pv_servico) =>
                          pv_servico.pedido_venda_servico_foto.map(
                            (el, i) => (
                              <div className="col-md-4 col-sm-12" key={i}>
                                <ImageItem
                                  noTrashButton
                                  legend={`${el.pvs_loc_foto_legenda} referente a ${pv_servico.pvs_obs}`}
                                  src={
                                    el.pvs_loc_foto_path
                                      ? el.pvs_loc_foto_path
                                      : el.base64
                                  }
                                  data={
                                    item.pedido_venda_servico
                                      .pedido_venda_servico_foto
                                  }
                                />
                              </div>
                            )
                          )
                        )
                        : "Sem imagens"}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </>
    );
  };
  return (
    <div id="container">
      {!generateReport && (
        <div className="container-fluid sticky-top">
          <div className="card">
            <div className="card-header">
              <p className="card-title">Opções de relatórios</p>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-toggle="modal"
                  data-target="#concessionaria"
                >
                  <i className="fa fa-question-circle"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 col-sm-6 mb-3">
                  <div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                      checked={showPhotos}
                      onChange={() => setShowPhotos(!showPhotos)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch1"
                    >
                      Fotográfico
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-3">
                  <div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch2"
                      checked={showMarkerService}
                      onChange={() => setShowMarkerService(!showMarkerService)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch2"
                    >
                      Mapa georreferenciado
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-3">
                  <div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch3"
                      checked={showCheckInspector}
                      onChange={() =>
                        setShowCheckInspector(!showCheckInspector)
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch3"
                    >
                      Retornos
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  {loading ? (
                    <button className="btn btn-info mr-3">
                      <Spinner label={'Gerando...'} direction={'center'} />
                    </button>
                  ) : (
                    <button
                      className="btn btn-info mr-3"
                      onClick={generatePdfDocument}
                    >
                      Gerar PDF<i className="ml-1 fa fa-file-pdf"></i>
                    </button>
                  )}
                  {csvData?.data && csvData?.headers && (
                    <ExportCSV
                      data={csvData?.data}
                      headers={csvData?.headers}
                      fileName={csvData?.fileName}
                    />
                  )}
                  <div className="btn btn-secondary" onClick={handleCancel}>
                    Cancelar <i className="fa fa-times-circle"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {generateReport && (
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <img src={LOGO} style={{ maxWidth: 250 }} alt="image_logo" />
              </div>
              <div className="col-6 mt-3">
                Relatório de conservação
                <div className="text-secondary">
                  Gerado no dia {formateDate()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {itemsReport?.length >= 1 &&
        itemsReport.sort((prev, next) => next.pedido_venda.ped_venda_cod - prev.pedido_venda.ped_venda_cod).map((item, i) => <RenderItem item={item} key={i} />)}
    </div>
  );
};
export default ReportOversightView;
