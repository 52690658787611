import { useContext, useEffect } from "react"
import { formContext } from "../../../../context/formContext"
import { requestContext } from "../../../../context/requestContext"

export const Highway = () => {

    const { state, onChangeSelectInput } = useContext(formContext)
    const { highway,getAllItemHighway } = useContext(requestContext)
    const { conc_cod } = state

    useEffect(() => {
        if (conc_cod > 0) {
            getAllItemHighway(conc_cod)
        }
    }, [conc_cod])

    return (
        <>
            <label>Rodoviária</label>
            <select
                className="form-control"
                placeholder='Selecione'
                name="rod_cod"
                onChange={onChangeSelectInput}
            >
                <option value={''}>Selecione</option>
                {Array.isArray(highway?.data) &&
                    highway.data.map((item) => (
                        <option key={item.rod_cod} value={JSON.stringify(item)}>
                            {item.rod_desc}  - {item.rod_km} (KM ini: {item.rod_km_ini}, {item.rod_km_ini_mt} | KM Final {item.rod_km_fim}, {item.rod_km_fim_mt})
                        </option>
                ))}
            </select>
        </>
    )
}