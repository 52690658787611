import React from 'react'
const Td = ({ ipc_tip_cod, ipc_perc, ipc_val }) => {
	return (
		<td className='text-center' key={ipc_tip_cod}>{parseFloat(ipc_val).toFixed(2) || parseFloat(ipc_perc).toFixed(2)}</td>
	)
}
const RenderIPC = ({ row, smallerValueIRI, smallerValueIGG }) => {
	const {
		ipc_val,
		ipc_perc,
		ipc_tip_cod,
	} = row
	if (!smallerValueIRI && !smallerValueIGG) {
		return (
			<Td {...row} />
		)
	}
	if ((ipc_val > smallerValueIRI?.value) && (ipc_tip_cod === smallerValueIRI?.id)) {
		return (
			<td title='Irregularidade Longitudinal: IRI < 3,46 mm/m; QI < 45 cont./km' style={{
				cursor: 'pointer'
			}}  className='text-center bg-info text-light' key={ipc_tip_cod}>{parseFloat(ipc_val).toFixed(2) || parseFloat(ipc_perc).toFixed(2)}</td>
		)
	}
	if ((ipc_val > smallerValueIGG?.value) && (ipc_tip_cod === smallerValueIGG?.id)) {
		return (
			<td title='Superfície: IGG < 40' style={{
				cursor: 'pointer'
			}} className='text-center bg-info text-light' key={ipc_tip_cod}>{parseFloat(ipc_val).toFixed(2) || parseFloat(ipc_perc).toFixed(2)}</td>
		)
	}
	return (
		<Td {...row} />
	)
}
export default RenderIPC