import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import ContentHeader from '../../../components/contentHeader'
import FormHighwayLimit from '../../../components/form/highwayLimit'
import { useNavigate } from 'react-router-dom'
import { handleWaring, isValid } from '../../../services/isValid'
const NewLimitHighway = () => {
	const { setInfoRequest, postItemHighwayLimit, getAllItemGroups } = useContext(requestContext)
	const { handleForm, state } = useContext(formContext)
	const navigate = useNavigate()
	const { getAllItemDealership, getAllItemHighway, getItemAllCompany } = useContext(requestContext)
	useEffect(() => {
		getAllItemDealership()
		getItemAllCompany()
		getAllItemGroups()
	}, [])
	const {
		lim_cod,
		lim_gp,
		lim_emp,
		lim_rod,
		lim_conc,
		lim_km_ini,
		lim_km_fim,
		lim_km_ini_mts,
		lim_km_fim_mts,
	} = state
	useEffect(() => {
		const loadHighway = () => {
			if (lim_conc) {
				getAllItemHighway(lim_conc)
			}
		}
		const loadDeafaultValuesHighway = () => {
			if (lim_rod) {
				handleForm({
					rod_cod: lim_rod,
				})
			}
		}
		loadHighway()
		loadDeafaultValuesHighway()
	}, [lim_rod, lim_conc])
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const item = {
			lim_gp,
			lim_emp,
			lim_rod,
			lim_conc,
			lim_km_ini,
			lim_km_fim,
			lim_km_ini_mts,
			lim_km_fim_mts,
		}
		const valid = isValid(item)
		if (!valid) {
			return setInfoRequest(handleWaring())
		}
		item.id = lim_cod
		postItemHighwayLimit({
			...item,
			lim_cod,
		})
	}
	const handleCancel = () => {
		handleForm({
			lim_cod: '',
			lim_gp: '',
			lim_emp: '',
			lim_rod: '',
			lim_conc: '',
			lim_km_ini: '',
			lim_km_fim: '',
			lim_km_ini_mts: '',
			lim_km_fim_mts: '',
			rod_km_ini: '',
			rod_km_ini_mt: '',
			rod_km_fim: '',
			rod_km_fim_mt: '',
			conc_cod: '',
			rod_cod: '',
			emp_cod: '',
			gp_cod: '',
		})
		navigate('/highway_limit')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Limite para Rodovia'} />
			<div className='content'>
				<form onSubmit={onSubmit} method="post">
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Informações sobre o limite</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormHighwayLimit />
						</div>
					</div>
					<div className="card">
						<div className='card-body'>
							<button type='submit' className='btn btn-info mr-3'>
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewLimitHighway