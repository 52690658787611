import React from "react";
import { convertToTitleCase } from "../../../../services/stringHandless";
import FormLimitRelavitValuesPavement from "../../../../components/form/LimitRelativValuesPavement";
const CardNavp = (props) => {
    return (
        <div className="card card-info">
            <div className="card-header">
                <h3 className="card-title text-capitalize">{convertToTitleCase('Valores limites relativos')}</h3>
                <div className="card-tools">
                    <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                    >
                        <i className="fas fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-tool"
                        data-toggle="modal"
                        data-target="#concessionaria"
                    >
                        <i className="fa fa-question-circle"></i>
                    </button>
                </div>
            </div>
            <div className="card-body">
                <FormLimitRelavitValuesPavement {...props} />
            </div>
        </div>
    )
}
export default CardNavp