import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { formatPhoneNumber } from '../../../services/stringHandless'
const ListDealership = ({ data }) => {
	const { handleForm } = useContext(formContext)
	const { deleteItemDealership } = useContext(requestContext)
	const [items, setItems] = useState(null)
	const navigate = useNavigate()
	useEffect(() => {
		const loadItems = () => {
			if (Array.isArray(data?.data)) {
				const { data: items_data } = data
				setItems(items_data)
			}
		}
		loadItems()
	}, [data])
	const handleClick = (item) => {
		handleForm(item)
		navigate('/dealership/new')
	}
	const handleDelete = (item) => {
		const confirm = window.confirm(`Deseja mesmo encerrar o contrato da concessionária ${item.conc_nome}?`)
		if (confirm) {
			deleteItemDealership(item.cont_concessionaria || item.conc_cod)
		}
	}
	if (!Array.isArray(items)) {
		return <div className='mb-3 col-12 p-3 mb-3'>Não há itens para serem exibidos</div>
	}
	return (
		items.sort((a, b) => a.lot_conc_desc - b.lot_conc_desc).map(item => (
			<div className='row' key={item.conc_cod}>
				<div className='mb-3 col-md-10 col-sm-12'>
					<div className='row'>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								Concessionaria:
							</span>
							{item.conc_nome}
						</div>
						<div className='mb-3 col-md-1 col-sm-6'>
							<span className='text-secondary mr-2'>
								Lote:
							</span>
							{item.lot_conc_desc}
						</div>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								CNPJ:
							</span>
							{item.conc_cnpj}
						</div>
						<div className='mb-3 col-md-3 col-sm-6'>
							<span className='text-secondary mr-2'>
								Contrato:
							</span>
							{item.cont_ncontrato}
						</div>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								Telefone:
							</span>
							<Link to={`tel:${item.conc_telefone}`} className='text-dark'>{formatPhoneNumber(item.conc_telefone)}</Link>
						</div>
						<div className='mb-3 col-md-8 col-sm-6'>
							<span className='text-secondary mr-2'>
								Endereço:
							</span>
							{item.conc_endereco} n° {item.conc_numero}, {item.mun_nome} - {item.conc_bairro}, {item.mun_uf} CEP: {item.conc_cep}
						</div>
						<div className='mb-3 col-md-3 col-sm-3'>
							<span className='text-secondary mr-2'>
								Situação:
							</span>
							{item.conc_ativa === 0 ? (<span className="badge badge-pill text-bg-info">
								Inativa
							</span>) : (<span className="badge badge-pill text-bg-success">
								Ativa
							</span>)}
						</div>
					</div>
				</div>
				<div className='mb-3 col-md-2 col-sm-12'>
					<button className='mr-2 btn btn-sm btn-info' onClick={() => handleClick(item)}>
						<i className='fa fa-edit'></i>
					</button>
					<button className='mr-2 btn btn-sm btn-secondary' onClick={() => handleDelete(item)}>
						<i className='fa fa-trash'></i>
					</button>
				</div>
			</div>
		))
	)
}
export default ListDealership