import { useContext, useEffect, useState } from "react"
import { requestContext } from "../../../context/requestContext"
import { formContext } from "../../../context/formContext"
import { find } from "../../../services/arrayHandless"
import { handleSuccess } from "../../../services/isValid"

export const KmSection = () => {
    const { onChangeNumber, onChange, onBlurValidMinMax, state, handleForm } = useContext(formContext)
    const { rod_km_fim, rod_km_fim_mt, rod_km_ini, rod_km_ini_mt, rod_desc, rod_cod, pv_loc_obs } = state
    const { setInfoRequest, highway } = useContext(requestContext)
    const [valuesFixed, setValuesFixed] = useState(null)

    useEffect(() => {
        const loadValuesFixed = () => {
            if (rod_cod && Array.isArray(highway?.data)) {
                const { data: highway_data } = highway
                const { data: findHigway } = find(highway_data, 'rod_cod', rod_cod)
                if (!findHigway) {
                    return
                }
                setValuesFixed({
                    ...findHigway
                })
            }
        }
        loadValuesFixed()
    }, [rod_cod, highway])

    const onBlur = ({ target }) => {
        const { name } = target
        const init_rod_ref =
            valuesFixed.rod_km_ini * 1000 + valuesFixed.rod_km_ini_mt
        const fim_rod_ref =
            valuesFixed.rod_km_fim * 1000 + valuesFixed.rod_km_fim_mt
        const init_rod_preenchido = rod_km_ini * 1000 + rod_km_ini_mt
        const fim_rod_preenchido = rod_km_fim * 1000 + rod_km_fim_mt
        if (name === 'rod_km_ini_mt') {
            if (
                init_rod_preenchido >= init_rod_ref &&
                init_rod_preenchido <= fim_rod_ref
            ) {
                setInfoRequest(handleSuccess('Extensão inciail preenchida corretamente'))
            } else {
                setInfoRequest(
                    handleWaring('Extensão inforada está diferente da cadtrada na rodovia')
                )
                handleForm({
                    rod_km_ini_mt: '',
                })
            }
        } else {
            if (
                fim_rod_preenchido >= init_rod_ref &&
                fim_rod_preenchido <= fim_rod_ref
            ) {
                setInfoRequest(handleSuccess('Extensão final preenchida corretamente'))

            } else {
                setInfoRequest(
                    handleWaring('Extensão inforada está diferente da cadtrada na rodovia')
                )
                handleForm({
                    rod_km_fim_mt: '',
                })
            }
        }
        if (init_rod_preenchido > fim_rod_preenchido) {
            setInfoRequest(handleWaring('Posição inicial maior do que a final'))
        }
    }

    return (
        <>
            <h5 className="mb-2">Passo 3/4</h5>
            <h4 className="mb-2 mt-2">Localização</h4>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <label>Km inicial</label>
                    <input
                        type="number"
                        onChange={onChangeNumber}
                        onBlur={onBlurValidMinMax}
                        name="rod_km_ini"
                        id="rod_km_ini"
                        value={rod_km_ini}
                        min={valuesFixed?.rod_km_ini || 0}
                        max={valuesFixed?.rod_km_fim || 0}
                        className={'form-control'}
                        placeholder="Km"
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <label>Mts inicial</label>
                    <input
                        type="number"
                        onChange={onChangeNumber}
                        name="rod_km_ini_mt"
                        value={rod_km_ini_mt}
                        min={0}
                        max={999}
                        className={'form-control'}
                        placeholder="m"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <label>Km final</label>
                    <input
                        type="number"
                        onChange={onChangeNumber}
                        onBlur={onBlurValidMinMax}
                        name="rod_km_fim"
                        value={rod_km_fim}
                        min={valuesFixed?.rod_km_ini || 0}
                        max={valuesFixed?.rod_km_fim || 0}
                        className={'form-control'}
                        placeholder="Km"
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <label>Mts final</label>
                    <input
                        type="number"
                        onChange={onChangeNumber}
                        onBlur={onBlur}
                        name="rod_km_fim_mt"
                        value={rod_km_fim_mt}
                        min={0}
                        max={999}
                        className={'form-control'}
                        placeholder="m"
                    />
                </div>
            </div>
            <div className="col-12">
            <label>Descrição do local</label>
                <textarea
                    type="text"
                    className={'form-control'}
                    onChange={onChange}
                    name="pv_loc_obs"
                    value={pv_loc_obs}
                    maxLength={500}
                    placeholder="Descreva brevemente sobre o local onde você está"
                />
            </div>
        </>
    )
}